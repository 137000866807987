import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import {
  Container,
  Divider,
  Message,
  Icon,
  Progress,
  Dropdown,
  Button,
} from 'semantic';

import { ListHeader, Breadcrumbs, PageLoader } from 'components';

import Menu from './Menu';

import Overview from './Overview';
import Sessions from './Sessions';
import Commands from './Commands';
import Configuration from './Configuration';
import ConfigurationNew from './ConfigurationNew';
import Diagnostics from './Diagnostics';
import Activity from './Activity';
import { request } from 'utils/api';

import {
  currentUserCanAccessProviderEndpoint,
  currentUserHasMaintenanceAccess,
} from 'utils/roles';
import { getSetupProgress, isEvseOCPP201 } from 'utils/evse-controllers';
import EditEvseController from 'components/modals/EditEvseController';
import EditEvseControllerConnectors from 'components/modals/EditEvseControllerConnectors';
import AutoConfigureEvseController from 'components/modals/AutoConfigureEvseController';
import { withTranslation } from 'react-i18next';
import EvseIssues from './EvseIssues';

import Actions from '../Actions';
import ResetEvse from 'components/modals/ResetEvse';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';
import { AccountPlatformFeatureProvider } from 'components/account-platform-features/context';

const setupCtaStyle = {
  float: 'right',
  position: 'absolute',
  right: '1.3em',
  top: '1em',
};

class EvseConnectorDetails extends React.Component {
  state = {
    itemId: this.props.match.params.id,
    item: null,
    error: null,
    loading: true,
    platformFeatures: [],
  };

  componentDidMount() {
    this.fetch(this.state.itemId);
  }

  fetch = (itemId) => {
    return request({
      method: 'GET',
      path: `/1/evse-controllers/${itemId}`,
    })
      .then(({ data, platformFeatures = [] }) => {
        this.setState({ item: data, loading: false, platformFeatures });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  async refresh() {
    this.setState({ loading: true });
    await this.fetch(this.state.itemId);
  }

  render() {
    const { t } = this.props;
    const { item } = this.state;

    if (!item) return <PageLoader />;

    const setupProgress = getSetupProgress(item);

    const maintainerMode =
      !currentUserCanAccessProviderEndpoint(
        'evseCommands',
        'read',
        item.providerId
      ) && currentUserHasMaintenanceAccess(item);

    const writeAccess = currentUserCanAccessProviderEndpoint(
      'evseControllers',
      'write',
      item.providerId
    );
    const showAutoConfigure =
      setupProgress.nextAction === 'AutoConfigureEvseController';

    return (
      <AccountPlatformFeatureProvider accountId={item?.accountId}>
        <Container>
          <Breadcrumbs
            path={[
              <Link key="root" to="/charging-stations">
                {t('evseController.chargingStations', 'Charging Stations')}
              </Link>,
            ]}
            active={item ? item.ocppIdentity : 'Loading...'}
          />
          <ListHeader title={item ? item.ocppIdentity : 'Loading...'}>
            <Dropdown
              button
              basic
              disabled={!item}
              text={t('common.actions', 'Actions')}
              style={{ marginTop: '-5px' }}>
              <Dropdown.Menu direction="left">
                {item && (
                  <Actions
                    item={item}
                    onDelete={this.onDelete}
                    onReload={async () => await this.refresh()}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>

            {!maintainerMode && writeAccess && (
              <EditEvseController
                data={item}
                trigger={
                  <Button
                    content={t('evseController.configure', 'Configure')}
                    icon="gears"
                    onClick={() =>
                      setResourceProviderIdInSessionStorage(item?.providerId)
                    }
                  />
                }
                onClose={() => {
                  removeResourceProviderIdInSessionStorage();
                  this.refresh();
                }}
              />
            )}
          </ListHeader>

          {item.rebootRequired && (
            <Message warning>
              <Message.Header>
                {t('evseController.rebootRequired', 'Reboot required')}
              </Message.Header>
              <p>
                {t(
                  'evseController.rebootRequiredDetails',
                  'Changes have been made to this charge station that require a reboot. The changes have been applied, but a reboot was not yet performed.'
                )}
              </p>
              <ResetEvse
                locationId={item.locationId}
                initialValues={{
                  evseControllerId: item.id,
                }}
                trigger={
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    basic
                    icon="arrow-rotate-right"
                    content={t('evseController.reset', 'Reboot this station')}
                  />
                }
                onDone={() => {
                  this.setState({ item: { ...item, rebootRequired: false } });
                }}
              />
            </Message>
          )}

          <Divider hidden />

          {!maintainerMode && !setupProgress.completed && (
            <div>
              <Message icon info>
                <Icon
                  name={setupProgress.readyForActivation ? 'check' : 'gear'}
                />
                <Message.Content>
                  <Message.Header style={{ marginBottom: '6px ' }}>
                    {setupProgress.readyForActivation
                      ? t(
                          'evseController.readyForActivation',
                          'This device is ready for activation by the customer'
                        )
                      : t(
                          'evseController.notReadyForActivation',
                          'This device still needs further configuration'
                        )}
                  </Message.Header>
                  {setupProgress.readyForActivation
                    ? t('evseController.nextStepReady', 'Alternatively,')
                    : t(
                        'evseController.nextStepNotReady',
                        'As a next step,'
                      )}{' '}
                  {setupProgress.nextStep}
                  <Progress
                    percent={setupProgress.percent}
                    size="tiny"
                    color="grey"
                    style={{ marginBottom: '0.5em' }}
                  />
                  {setupProgress.nextAction === 'EditEvseController' && (
                    <EditEvseController
                      data={item}
                      trigger={
                        <Button
                          content={t(
                            'evseController.openSettings',
                            'Open Settings'
                          )}
                          primary
                          style={setupCtaStyle}
                        />
                      }
                      onDone={() => this.refresh()}
                      onClose={() => this.refresh()}
                    />
                  )}
                  {setupProgress.nextAction ===
                    'EditEvseControllerConnectors' && (
                    <EditEvseControllerConnectors
                      onClose={() => this.refresh()}
                      onDone={() => this.refresh()}
                      evseController={item}
                      trigger={
                        <Button
                          icon="plug"
                          content={t(
                            'evseController.openSettings',
                            'Open Settings'
                          )}
                          primary
                          style={setupCtaStyle}
                        />
                      }
                    />
                  )}
                  {showAutoConfigure && (
                    <AutoConfigureEvseController
                      onClose={() => this.refresh()}
                      onDone={() => this.refresh()}
                      evseController={item}
                      trigger={
                        <Button
                          icon="wand-magic-sparkles"
                          content={t(
                            'evseController.autoConfiguration',
                            'Auto Configuration'
                          )}
                          primary
                          style={setupCtaStyle}
                        />
                      }
                    />
                  )}
                </Message.Content>
              </Message>
              <Divider hidden />
            </div>
          )}
          <EvseIssues evseControllerId={item.id} />
        </Container>

        {item && item.deletedAt && (
          <Message
            error
            content={t(
              'evseController.hasBeenDeleted',
              'This evse controller has been deleted'
            )}
          />
        )}

        <Menu
          item={item}
          itemId={this.state.itemId}
          maintainerMode={maintainerMode}
        />

        <Divider hidden />

        {!item && <PageLoader />}
        {item && (
          <Switch>
            <Route
              exact
              path="/charging-stations/:id/sessions"
              component={(props) => (
                <Sessions
                  {...props}
                  evseController={item}
                  maintainerMode={maintainerMode}
                />
              )}
            />
            <Route
              exact
              path="/charging-stations/:id/configuration"
              component={(props) => {
                if (isEvseOCPP201(item)) {
                  return (
                    <ConfigurationNew
                      {...props}
                      evseController={item}
                      maintainerMode={maintainerMode}
                    />
                  );
                }

                return (
                  <Configuration
                    {...props}
                    evseController={item}
                    maintainerMode={maintainerMode}
                  />
                );
              }}
            />
            <Route
              exact
              path="/charging-stations/:id/commands"
              component={(props) => (
                <Commands
                  {...props}
                  evseController={item}
                  maintainerMode={maintainerMode}
                />
              )}
            />
            <Route
              exact
              path="/charging-stations/:id/diagnostics"
              component={(props) => (
                <Diagnostics
                  {...props}
                  evseController={item}
                  maintainerMode={maintainerMode}
                />
              )}
            />
            <Route
              exact
              path="/charging-stations/:id/activity"
              component={(props) => (
                <Activity
                  {...props}
                  evseController={item}
                  maintainerMode={maintainerMode}
                />
              )}
            />
            <Route
              exact
              path="/charging-stations/:id"
              item={item}
              component={(props) => (
                <Overview
                  {...props}
                  evseController={item}
                  maintainerMode={maintainerMode}
                />
              )}
            />
          </Switch>
        )}
      </AccountPlatformFeatureProvider>
    );
  }
}

export default withTranslation()(EvseConnectorDetails);
