import { isSuperAdmin, userCanAccessProviderEndpoint } from 'utils/roles';
import { useFeatures, FeatureFlags } from 'contexts/features';
import { useUser } from 'contexts/user';

export const CHARGING_STATIONS_FE_PATH = `/charging-stations`;
export const EVSE_OPERATIONAL_STATUSES_FE_PATH = `${CHARGING_STATIONS_FE_PATH}/operational-statuses`;

export const EVSE_OPERATIONAL_STATUSES_PERMISSIONS_READ =
  'evseOperationalStatuses';
export const EVSE_OPERATIONAL_STATUSES_PERMISSIONS_WRITE =
  EVSE_OPERATIONAL_STATUSES_PERMISSIONS_READ;
export const EVSE_CONTROLLERS_CUSTOM_OPERATIONAL_STATUSES_PLATFORM_MODULE =
  'custom-operational-statuses';

export const hasEvseCustomOperationalStatusesPermissions = (): boolean => {
  const { hasFeature } = useFeatures();

  const { user, provider } = useUser();
  if (!user) {
    return false;
  }

  const hasPermission = userCanAccessProviderEndpoint(
    user,
    provider,
    EVSE_OPERATIONAL_STATUSES_PERMISSIONS_WRITE,
    'write'
  );

  return (
    user &&
    hasPermission &&
    hasFeature(FeatureFlags.OperationalStatusesPage) &&
    !!provider?.enableCustomOperationalStatuses
  );
};

export const hasEvseOperationalStatusesAccess = (): boolean => {
  const { hasFeature } = useFeatures();

  const { user, provider } = useUser();
  if (!user) {
    return false;
  }

  const hasPermission = userCanAccessProviderEndpoint(
    user,
    provider,
    EVSE_OPERATIONAL_STATUSES_PERMISSIONS_READ,
    'read'
  );

  return (
    user && hasPermission && hasFeature(FeatureFlags.OperationalStatusesPage)
  );
};
