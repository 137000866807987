import React, { useMemo } from 'react';
import { Dropdown } from 'semantic';
import { useTranslation } from 'react-i18next';
import { currentUserCanAccess } from 'utils/roles';
import { Confirm } from 'components';
import { request } from 'utils/api';
import { isSuperAdmin } from 'utils/roles';
import { useUser } from 'contexts/user';
import ResetEvse from 'components/modals/ResetEvse';
import EvseControllerMigration from 'components/modals/EvseControllerMigration';
import UnlockConnector from 'components/modals/UnlockConnector';
import InspectObject from 'components/modals/InspectObject';
import TestTokenAuthorization from 'components/modals/TestTokenAuthorization';
import { Link, useHistory } from 'react-router-dom';
import EvseOperationalStatusUpdateModal from 'components/EvseOperationalStatusUpdateModal';
import { useFeatures, FeatureFlags } from 'contexts/features';

export default function EvseControllerActions({
  item,
  onReload,
  view = 'detail',
} = {}) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { hasFeature } = useFeatures();
  const history = useHistory();

  const writeAccess = currentUserCanAccess('evseControllers', 'write');
  const readAccess = currentUserCanAccess('evseControllers', 'read');
  const currentUserIsSuperAdmin = isSuperAdmin(user);
  const isOperationalStatusesPageEnabled = hasFeature(
    FeatureFlags.OperationalStatusesPage
  );

  const connectorIds = (
    Array.isArray(item?.connectors) ? item.connectors : []
  ).map((connector) => connector?.connectorId);

  const evseControllerIsClaimed = useMemo(() => {
    return (
      item &&
      Boolean(
        item.accountId ||
          item.locationId ||
          item.billingPlanId ||
          item.tariffProfileId
      )
    );
  }, [item]);

  function onDelete(item) {
    return request({
      method: 'DELETE',
      path: `/1/evse-controllers/${item.id}`,
    });
  }

  async function getEvseController(id) {
    const result = await request({
      method: 'GET',
      path: `/1/evse-controllers/${id}`,
    });
    return result.data;
  }

  async function onAssignNewEvseId() {
    await request({
      method: 'POST',
      path: `/1/evse-controllers/assign-new-evseid`,
      body: {
        evseControllerId: item.id,
      },
    });
    await onReload();
  }

  return (
    <>
      <UnlockConnector
        locationId={item.locationId}
        initialValues={{
          evseControllerId: item.id,
        }}
        trigger={
          <Dropdown.Item
            content={t('location.unlockConnector', 'Unlock Connector')}
            icon="plug"
          />
        }
      />
      <Dropdown.Item
        basic
        as={Link}
        to={`/evse/${item.normalizedEvseId}`}
        style={{ marginTop: '1px' }}
        content={t('location.viewQr', 'Visit QR link')}
        icon="qrcode"
      />
      {isOperationalStatusesPageEnabled && writeAccess && (
        <EvseOperationalStatusUpdateModal
          evseController={item}
          onUpdated={() => history.push(`/charging-stations/${item.id}`)}
          trigger={
            <Dropdown.Item
              content={t(
                'evseControllerAction.updateOperationalStatus',
                'Update Operational Status'
              )}
              icon="wrench"
            />
          }
        />
      )}
      <ResetEvse
        locationId={item.locationId}
        initialValues={{
          evseControllerId: item.id,
        }}
        trigger={
          <Dropdown.Item
            icon="arrow-rotate-right"
            content={t('location.reset', 'Reboot this station')}
          />
        }
      />

      {view !== 'table' && readAccess && (
        <TestTokenAuthorization
          evseControllerId={item.id}
          connectors={connectorIds}
          trigger={
            <Dropdown.Item
              icon="lock"
              disabled={connectorIds.length === 0}
              content={t(
                'location.testTokenAuthorization',
                'Test token authorization'
              )}
            />
          }
        />
      )}

      {currentUserIsSuperAdmin && (
        <EvseControllerMigration
          evseControllerId={item.id}
          onReload={onReload}
          trigger={
            <Dropdown.Item
              content={t('evseControllers.migrate', 'Migrate EVSE Controller')}
              icon="clone"
              disabled={evseControllerIsClaimed}
            />
          }
        />
      )}
      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: item.token?.uid || item.id }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item icon="trash" text="Delete" disabled={!writeAccess} />
        }
        button={t('deleteDialog.button', 'Delete')}
        onConfirm={async () => {
          await onDelete(item);
          await onReload();
        }}
      />
      <InspectObject
        name="EVSE"
        getData={() => getEvseController(item.id)}
        trigger={<Dropdown.Item text="Inspect" icon="file-code" />}
      />
    </>
  );
}
