import { useMemo, useState } from 'react';
import { request } from '../../../../utils/api';

export function useTariffProfileOptions() {
  const [tariffProfileOptions, setTariffProfileOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useMemo(() => {
    const requestTariffProfileData = async () => {
      const response = await request({
        path: '/1/tariff-profiles/search',
        method: 'POST',
        body: {},
      });

      setTariffProfileOptions(
        response.data.map((tariffProfile: any) => ({
          value: tariffProfile.id,
          label: tariffProfile.name,
        }))
      );
    };

    requestTariffProfileData();
  }, []);

  return tariffProfileOptions;
}
