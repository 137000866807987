export enum CommandDestination {
  ChargePoint = 'chargepoint',
  CentralSystem = 'centralsystem',
}

export enum CommandStatus {
  Pending = 'pending',
  Busy = 'busy',
  Done = 'done',
}

export enum CommandVersion {
  OCPP1_5 = 'ocpp1.5',
  OCPP1_6 = 'ocpp1.6',
  OCPP2_0_1 = 'ocpp2.0.1',
}

export type Command = {
  ocppIdentity: string;
  method: string;
  status: CommandStatus;
  destination: CommandDestination;
  providerSlug?: string;
  evseControllerId?: string;
  ocppChargingStationId?: string;
  idempotencyId?: string;
  params?: object;
  messageId?: string;
  version?: CommandVersion;
  result?: any;
  error?: any;
  id: string;
  createdAt: string;
};
