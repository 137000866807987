import React, { useState } from 'react';
import { Group } from 'components/Layout/helpers';
import InputField from 'components/form-fields/formik/InputField';
import SelectCountry from 'components/form-fields/formik/SelectCountry';
import SelectField from 'components/form-fields/formik/SelectField';
import { Formik } from 'formik';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import { Divider, Form, Button, Message, Loader } from 'semantic';
import { Trans, useTranslation } from 'react-i18next';
import { useOrganizationBillingValidation } from './billing-and-plans.validation';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import LegalLink from 'components/LegalLink';
import { request } from 'utils/api';
import { currentUserCanAccess, currentUserIsSuperAdmin } from 'utils/roles';
import { useAccount } from 'contexts/account';
import { isDirty } from 'components/form-fields/formik/utils/forms';
import {
  useAccountHolderNameValidator,
  useIBANValidator,
  useBICValidator,
  useSEPAValidator,
} from 'screens/Settings/hooks/validators';
import CurrentPlan from 'screens/Settings/components/organisms/CurrentPlan';
import { useUser } from 'contexts/user';
import { useFeatures } from 'contexts/features';
import { formatCurrency } from 'utils/formatting';
import CardPlans from 'screens/Settings/screens/organization/BillingAndPlans/CardPlans';
import { FeatureFlag } from 'components';

type AccountBillingFormValues = {
  billing: {
    accountHolderName: string;
    ibanNo: string;
    address: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    countryCode: string;
    paymentMethod: 'autopay' | 'manual';
    sepaAccepted?: boolean;
    sepaAcceptedAt?: Date | null;
    cpoReferenceText: string;
    mspReferenceText: string;
  };
};

export default function AccountBillingForm() {
  const { t } = useTranslation();
  const { account: accountData, getAccount } = useAccount();
  const { hasFeature, isReady: isFeaturesReady } = useFeatures();
  const { user, provider } = useUser();
  const [errorMessage, setErrorMessage] = useState<null | string>('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const initialFormValues = {
    billing: {
      accountHolderName: accountData?.billing?.accountHolderName || '',
      ibanNo: accountData?.billing?.ibanNo || '',
      bicNo: accountData?.billing?.bicNo || '',
      address: accountData?.billing?.address || '',
      addressLine2: accountData?.billing?.addressLine2 || '',
      postalCode: accountData?.billing?.postalCode || '',
      city: accountData?.billing?.city || '',
      countryCode: accountData?.billing?.countryCode || '',
      paymentMethod: accountData?.billing?.paymentMethod || 'autopay',
      email: accountData?.billing?.email || '',
      cpoReferenceText: accountData?.billing?.cpoReferenceText || '',
      mspReferenceText: accountData?.billing?.mspReferenceText || '',
      sepaAccepted: accountData?.billing?.sepaAccepted || false,
    },
  };

  const isBicCollectionEnabled = hasFeature('billing_bic_collection');

  const validationSchema = useOrganizationBillingValidation();
  const validateSEPA = useSEPAValidator();
  const validateIBAN = useIBANValidator();
  const validateBIC = useBICValidator();
  const validateAccountHolderName = useAccountHolderNameValidator();

  const canEditCountryCode =
    currentUserIsSuperAdmin() || !initialFormValues.billing.countryCode;

  const cannotEditBillingInformation = !currentUserCanAccess(
    'accounts-billing',
    'write'
  );

  const handleSubmit = async (values: AccountBillingFormValues) => {
    setLoading(true);
    setErrorMessage('');
    setSuccess(false);

    try {
      await request({
        method: 'PATCH',
        path: '1/accounts/self',
        body: values,
      });
      setSuccess(true);
      await getAccount();
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const manualInvoicingFee =
    provider.currencies.find((c: any) => {
      return c.currency === accountData?.billingCurrency;
    })?.manualInvoicingFee || 0;

  const vatPercentage = provider.individualFees / 100;

  return (
    <>
      {(loading && !accountData) ||
        (!isFeaturesReady && (
          <Loader active={true}>{t('query.states.loading', 'Loading')}</Loader>
        ))}
      {accountData && (
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting, values }) => {
            const isPaymentManual = values.billing.paymentMethod === 'manual';
            const isPaymentAutopay = values.billing.paymentMethod === 'autopay';
            const dirty = isDirty(values, initialFormValues);

            return (
              <Form onSubmit={handleSubmit}>
                <FormHeader
                  size="large"
                  disabled={cannotEditBillingInformation}
                  pt={5}
                  pb={5}
                  text={t(
                    'settings.organization.billing.title',
                    'Organization Billing'
                  )}
                />
                <Divider />
                {errorMessage && <Message error content={errorMessage} />}
                {success && (
                  <Message
                    success
                    content={t('messages.updated', 'Successfully updated!')}
                  />
                )}
                <p>
                  {t(
                    'settings.organization.billing.description',
                    'Provide your organizations payment & billing details information.'
                  )}
                </p>

                {accountData?.accountTierBillingPlanId && (
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <CurrentPlan
                      currentPlanId={
                        accountData.accountTierBillingPlanId.id
                          ? accountData.accountTierBillingPlanId.id
                          : accountData.accountTierBillingPlanId
                      }
                      accountBillingCurrency={accountData.billingCurrency}
                      currentLanguage={user.defaultLangCode}
                    />
                  </div>
                )}

                {cannotEditBillingInformation && (
                  <Message info>
                    {t(
                      'access.cannotEditBillingInformation',
                      'You do not have sufficient permissions to edit billing information.'
                    )}
                  </Message>
                )}

                <FeatureFlag feature="display_account_card_plans">
                  <CardPlans />
                </FeatureFlag>

                <FormHeader
                  size="medium"
                  disabled={cannotEditBillingInformation}
                  mt={20}
                  mb={15}
                  text={t(
                    'settings.organization.billing.headers.paymentInfo',
                    'Payment Information'
                  )}
                />

                <SelectField
                  disabled={cannotEditBillingInformation}
                  name={'billing.paymentMethod'}
                  label={t(
                    'settings.organization.billing.paymentMethod',
                    'Payment Method'
                  )}
                  required
                  options={[
                    {
                      key: 'autopay',
                      value: 'autopay',
                      text: t('billingInfo.autopay', 'Autopay'),
                    },
                    {
                      key: 'manual',
                      value: 'manual',
                      text: t(
                        'billingInfo.manualPaymentWithFee',
                        'Manual Payment ({{ fee }} per invoice)',
                        {
                          fee: formatCurrency(
                            manualInvoicingFee * (vatPercentage + 1),
                            {
                              currency: accountData.billingCurrency,
                            }
                          ),
                        }
                      ),
                    },
                  ]}
                  wrapperStyle={{ maxWidth: 285 }}
                />

                {isPaymentManual && (
                  <p style={{ fontStyle: 'italic', marginBottom: 0 }}>
                    {t(
                      'settings.organization.billing.manualPaymentInfo',
                      'Cost for processing manual payments is {{fee}} per invoice.',
                      {
                        fee: formatCurrency(
                          manualInvoicingFee * (vatPercentage + 1),
                          {
                            currency: accountData.billingCurrency,
                          }
                        ),
                      }
                    )}
                  </p>
                )}

                <FormVerticalSpace />

                <InputField
                  disabled={cannotEditBillingInformation}
                  name="billing.accountHolderName"
                  label={t(
                    'settings.organization.billing.accountHolderName',
                    'Account Holder Name'
                  )}
                  required={isPaymentAutopay}
                  validate={
                    isPaymentAutopay
                      ? (value) => validateAccountHolderName(value)
                      : undefined
                  }
                  wrapperStyle={{ maxWidth: 285 }}
                />

                <FormVerticalSpace />

                <InputField
                  disabled={cannotEditBillingInformation}
                  name="billing.ibanNo"
                  label={t(
                    'settings.organization.billing.ibanNo',
                    'IBAN Number'
                  )}
                  required={isPaymentAutopay}
                  validate={(value: string) => {
                    if (!isPaymentAutopay) return;
                    return validateIBAN(value);
                  }}
                  wrapperStyle={{ maxWidth: 285 }}
                />

                <FormVerticalSpace />

                {isBicCollectionEnabled && (
                  <>
                    <InputField
                      required={isPaymentAutopay}
                      disabled={cannotEditBillingInformation}
                      name="billing.bicNo"
                      label={t(
                        'settings.organization.billing.bicNo',
                        'BIC Number'
                      )}
                      validate={(value: string) => {
                        if (!isPaymentAutopay) return;
                        return validateBIC(value);
                      }}
                      wrapperStyle={{ maxWidth: 285 }}
                    />
                    <FormVerticalSpace />
                  </>
                )}

                {isPaymentAutopay && (
                  <div style={{ marginTop: 15, maxWidth: 335 }}>
                    <CheckboxField
                      name={'billing.sepaAccepted'}
                      required
                      disabled={cannotEditBillingInformation}
                      validate={(value) => validateSEPA(value)}
                      label={
                        <label>
                          <Trans i18nKey="settings.organization.billing.sepaTerms">
                            I accept the&nbsp;
                            <LegalLink type="sepa">
                              SEPA authorization and terms
                            </LegalLink>
                            .
                          </Trans>
                        </label>
                      }
                    />
                  </div>
                )}

                <FormVerticalSpace />

                <InputField
                  disabled={cannotEditBillingInformation}
                  name="billing.cpoReferenceText"
                  label={t(
                    'settings.organization.billing.cpoReferenceText',
                    'Invoice Reference (Charging station)'
                  )}
                  placeholder={t(
                    'settings.organization.billing.placeholders.cpoReference',
                    'Charging station subscriptions from Road'
                  )}
                  wrapperStyle={{ maxWidth: 335 }}
                />

                <FormVerticalSpace />

                <InputField
                  disabled={cannotEditBillingInformation}
                  name="billing.mspReferenceText"
                  label={t(
                    'settings.organization.billing.mspReferenceText',
                    'Invoice Reference (Cards)'
                  )}
                  placeholder={t(
                    'settings.organization.billing.placeholders.mspReference',
                    'Charge Cards usage & subscription from Road'
                  )}
                  wrapperStyle={{ maxWidth: 335 }}
                />

                <FormVerticalSpace />

                <FormHeader
                  size="medium"
                  disabled={cannotEditBillingInformation}
                  mt={20}
                  mb={15}
                  text={t(
                    'settings.organization.billing.headers.addressInfo',
                    'Billing Address'
                  )}
                />

                <InputField
                  disabled={cannotEditBillingInformation}
                  name="billing.email"
                  label={t(
                    'settings.organization.billing.email',
                    'Billing E-mail'
                  )}
                  required
                  wrapperStyle={{ maxWidth: 335 }}
                />

                <FormVerticalSpace />

                <SelectCountry
                  disabled={!canEditCountryCode}
                  name="billing.countryCode"
                  required={true}
                  label="Country"
                  wrapperStyle={{ maxWidth: 335 }}
                />

                <FormVerticalSpace />

                <Group align="flex-start" spacing={'xs'}>
                  <InputField
                    disabled={cannotEditBillingInformation}
                    name="billing.address"
                    label={t('settings.organization.billing.address', 'Street')}
                    required
                    wrapperStyle={{ maxWidth: 285 }}
                  />

                  <InputField
                    disabled={cannotEditBillingInformation}
                    name="billing.addressLine2"
                    label={t(
                      'settings.organization.billing.addressLine2',
                      'No.'
                    )}
                    required
                    wrapperStyle={{ maxWidth: 133 }}
                  />
                </Group>

                <FormVerticalSpace />

                <Group align="flex-start" spacing={'xs'}>
                  <InputField
                    disabled={cannotEditBillingInformation}
                    name="billing.postalCode"
                    label={t(
                      'settings.organization.billing.postalCode',
                      'Post Code'
                    )}
                    required
                    wrapperStyle={{ maxWidth: 188 }}
                  />

                  <InputField
                    disabled={cannotEditBillingInformation}
                    name="billing.city"
                    label={t('settings.organization.billing.city', 'City/Town')}
                    required
                    wrapperStyle={{ maxWidth: 133 }}
                  />
                </Group>

                <FormVerticalSpace size={40} />

                <Button
                  type="submit"
                  loading={loading || isSubmitting}
                  disabled={cannotEditBillingInformation || !dirty}
                  style={{ marginLeft: 0 }}>
                  {t(
                    'settings.personal.billing.updateBilling',
                    'Update Payment & Billing Information'
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}
