import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Table } from 'semantic';
import { size } from 'lodash-es';

import {
  EvseOperationalStatus,
  EvseOperationalStatusBilling,
  EvseOperationalStatusChargingStation,
  EvseOperationalStatusRoaming,
  EvseOperationalStatusType,
} from 'types/evse-operational-status';
import { OperationalStatus } from 'components/OperationalStatus';
import { capitalize } from 'utils/formatting';
import EditEvseOperationalStatus from 'components/modals/EditEvseOperationalStatus';
import {
  evseOperationalStatusBillingTranslation,
  evseOperationalStatusChargingStationTranslation,
  evseOperationalStatusDescriptionTranslation,
  evseOperationalStatusNameTranslation,
  evseOperationalStatusRoamingTranslation,
  evseOperationalStatusTypeTranslation,
} from 'utils/evse-operational-status';

interface EvseControllerLabelTableProps {
  writeAccess: boolean;
  evseOperationalStatuses: EvseOperationalStatus[];
  deleteEvseOperationalStatus: (id: string) => void;
  onUpdatedEvseOperationalStatus?: () => void;
  loadingDeleting?: boolean;
}

export default function EvseOperationalStatusesTable({
  writeAccess,
  evseOperationalStatuses,
  deleteEvseOperationalStatus,
  loadingDeleting,
  onUpdatedEvseOperationalStatus,
}: EvseControllerLabelTableProps) {
  const { t } = useTranslation();
  const evseOperationalSystemStatuses =
    evseOperationalStatuses?.filter(
      (status) => status.type === EvseOperationalStatusType.System
    ) || [];
  const initialStatuses = evseOperationalStatuses.filter(
    (status) => status.isInitial
  );
  const initialStatus =
    size(initialStatuses) === 1
      ? initialStatuses[0]
      : initialStatuses.find(
          (s) => s.type === EvseOperationalStatusType.Custom
        );

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>
            {t('evseOperationalStatuses.tableColumnName', 'Name')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('evseOperationalStatuses.tableColumnType', 'Type')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('evseOperationalStatuses.tableColumnParent', 'Parent')}
          </Table.HeaderCell>
          <Table.HeaderCell width={4}>
            {t('evseOperationalStatuses.tableColumnDescription', 'Description')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t(
              'evseOperationalStatuses.tableColumnRoamingStatus',
              'Roaming Status'
            )}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t(
              'evseOperationalStatuses.tableColumnChargerStatus',
              'Charger Status'
            )}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t(
              'evseOperationalStatuses.tableColumnBillingStatus',
              'Billing Status'
            )}
          </Table.HeaderCell>
          {writeAccess && (
            <Table.HeaderCell width={1}>
              {t('evseOperationalStatuses.tableColumnActions', 'Actions')}
            </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {evseOperationalStatuses.map((status: EvseOperationalStatus) => {
          const parentEvseOperationalStatus =
            status.parentEvseOperationalStatusId
              ? evseOperationalStatuses.find(
                  (s) => s.id === status.parentEvseOperationalStatusId
                )
              : null;

          return (
            <Table.Row key={status.id}>
              <Table.Cell width={2}>
                <OperationalStatus evseOperationalStatus={status} />
                {initialStatus?.id === status.id && (
                  <p style={{ fontSize: 12, paddingTop: 5 }}>
                    <i>
                      {t(
                        'evseOperationalStatuses.initialStatus',
                        'Initial Status'
                      )}
                    </i>
                  </p>
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {capitalize(
                  evseOperationalStatusTypeTranslation(t, status.type)
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {parentEvseOperationalStatus
                  ? capitalize(
                      evseOperationalStatusNameTranslation(
                        t,
                        parentEvseOperationalStatus
                      )
                    )
                  : '-'}
              </Table.Cell>
              <Table.Cell width={4}>
                {evseOperationalStatusDescriptionTranslation(t, status)}
              </Table.Cell>
              <Table.Cell width={1}>
                {status.roamingStatus === EvseOperationalStatusRoaming.None
                  ? '-'
                  : capitalize(
                      evseOperationalStatusRoamingTranslation(
                        t,
                        status.roamingStatus
                      )
                    )}
              </Table.Cell>
              <Table.Cell width={1}>
                {status.chargingStationStatus ===
                EvseOperationalStatusChargingStation.None
                  ? '-'
                  : capitalize(
                      evseOperationalStatusChargingStationTranslation(
                        t,
                        status.chargingStationStatus
                      )
                    )}
              </Table.Cell>
              <Table.Cell width={1}>
                {status.billingStatus === EvseOperationalStatusBilling.None
                  ? '-'
                  : capitalize(
                      evseOperationalStatusBillingTranslation(
                        t,
                        status.billingStatus
                      )
                    )}
              </Table.Cell>

              {writeAccess && (
                <Table.Cell textAlign="center" width={2}>
                  {status.type === EvseOperationalStatusType.Custom && (
                    <>
                      <EditEvseOperationalStatus
                        size="tiny"
                        evseOperationalStatus={status}
                        evseOperationalSystemStatuses={
                          evseOperationalSystemStatuses
                        }
                        onSuccessfulSubmit={onUpdatedEvseOperationalStatus}
                        trigger={
                          <Button
                            basic
                            icon="pen-to-square"
                            text={t('common.edit', 'Edit')}
                            onClick={() => {}}
                          />
                        }
                      />
                      <Button
                        basic
                        icon="trash"
                        disabled={loadingDeleting}
                        text={t('common.delete', 'Delete')}
                        onClick={() => deleteEvseOperationalStatus(status.id)}
                      />
                    </>
                  )}
                </Table.Cell>
              )}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
