import React from 'react';
import { useTariffProfileOptions } from './hooks/useTariffProfileOptions';
import DropdownField from '../../form-fields/formik/DropdownField';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';

export default function BulkTariffProfileAssignment({
  objectPath,
}: {
  objectPath: string;
}) {
  const { t } = useTranslation();
  const tariffProfileOptions = useTariffProfileOptions();

  return (
    <>
      <DropdownField
        name={objectPath}
        label={t('editEvseControllersModal.tariffProfile', 'Tariff Profile')}
        hideInternalComponentError={true}
        className="container-dropdown"
        required
        fluid
        selection
        search={true}
        placeholder={t(
          'editEvseControllersModal.selectOption',
          'Select Option'
        )}
        options={tariffProfileOptions.map((option) => ({
          key: option.value,
          text: option.label,
          value: option.value,
        }))}
      />
      <Message
        warning
        header={t(
          'editEvseControllersModal.tariffProfileWarning.header',
          'Note the tariff profile could be not activated for some charging stations.'
        )}
        content={
          <>
            <p>
              {t(
                'editEvseControllersModal.tariffProfileWarning.content',
                'Tariff profile will be saved but not actively applied to the charging station if it has one of following settings:'
              )}
            </p>
            <ul>
              <li>
                {t(
                  'editEvseControllersModal.tariffProfileWarning.content1',
                  'Any card allowed to charge for free (ignores all cost settings)'
                )}
              </li>
              <li>
                {t(
                  'editEvseControllersModal.tariffProfileWarning.content2',
                  'Restricted charging from other networks (disabled roaming)'
                )}
              </li>
              <li>
                {t(
                  'editEvseControllersModal.tariffProfileWarning.content3',
                  'Disabled charging point (no charging, no billing)'
                )}
              </li>
            </ul>
          </>
        }
      />
    </>
  );
}
