import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useBulkEditEvseControllersErrorFormatter() {
  const { t } = useTranslation();

  return useCallback(
    (failedCondition: { code: string; description: string }) => {
      switch (failedCondition.code) {
        case 'EVSE_CONTROLLER_ACCOUNTS_HAVE_SAME_CURRENCY':
          return {
            header: t(
              'evseControllers.errors.sameCurrency.header',
              'Some of the selected charging stations use different currencies.'
            ),
            message: t(
              'evseControllers.errors.sameCurrency.message',
              'This option is available only for charging stations that are invoiced in the same currency.'
            ),
          };
        case 'EVSE_CONTROLLERS_HAVE_SAME_POWER_TYPE':
          return {
            header: t(
              'evseControllers.errors.samePowerType.header',
              'The selected charging stations have a different power delivery type.'
            ),
            message: t(
              'evseControllers.errors.samePowerType.message',
              'This option is available only for charging stations that have the same power delivery type (AC, DC or AC/DC)'
            ),
          };
        case 'EVSE_CONTROLLERS_HAVE_NO_ACCOUNTS':
          return {
            header: t(
              'evseControllers.errors.noAccounts.header',
              'Some of the selected charging stations already have an assigned Account.'
            ),
            message: t(
              'evseControllers.errors.noAccounts.message',
              'This option is available only for new charging stations without an assigned Account to prevent any impact on the station’s operation and billing.'
            ),
          };
        case 'EVSE_CONTROLLERS_HAVE_SAME_ACCOUNTS':
          return {
            header: t(
              'evseControllers.errors.sameAccount.header',
              'Some of the selected charging stations belong to different account.'
            ),
            message: t(
              'evseControllers.errors.sameAccount.message',
              'This option is available only for charging stations within the same account to prevent any potential billing issues.'
            ),
          };
        default:
          console.error('Unhandled failed condition error');
      }

      return {
        header: t('evseControllers.errors.unknown.header', 'Unknown error'),
        message: t(
          'evseControllers.errors.unknown.message',
          'An unknown error occurred'
        ),
      };
    },
    [t]
  );
}
