import { TFunction } from 'react-i18next';
import {
  EvseOperationalStatus,
  EvseOperationalCanonicalStatus,
  EvseOperationalStatusColor,
  EvseOperationalStatusRoaming,
  EvseOperationalStatusChargingStation,
  EvseOperationalStatusBilling,
  EvseOperationalStatusType,
} from 'types/evse-operational-status';

export const evseOperationalStatusTypeTranslation = (
  t: TFunction,
  type: EvseOperationalStatusType
): string => {
  switch (type) {
    case EvseOperationalStatusType.System:
      return t('evseOperationalStatus.type.system', 'System');
    case EvseOperationalStatusType.Custom:
      return t('evseOperationalStatus.type.custom', 'Custom');
    default:
      return type;
  }
};

export const evseOperationalStatusNameTranslation = (
  t: TFunction,
  status: EvseOperationalStatus
): string => {
  switch (status.canonicalStatus) {
    case EvseOperationalCanonicalStatus.Onboarding:
      return t('evseOperationalStatus.name.onboarding', 'Onboarding');
    case EvseOperationalCanonicalStatus.Activation:
      return t('evseOperationalStatus.name.activation', 'Activation');
    case EvseOperationalCanonicalStatus.Live:
      return t('evseOperationalStatus.name.live', 'Live');
    case EvseOperationalCanonicalStatus.Archived:
      return t('evseOperationalStatus.name.archived', 'Archived');
    case EvseOperationalCanonicalStatus.Disabled:
      return t('evseOperationalStatus.name.disabled', 'Disabled');
    case EvseOperationalCanonicalStatus.OutOfService:
      return t('evseOperationalStatus.name.outOfService', 'Out of Service');
    default:
      return status.name;
  }
};

export const evseOperationalStatusDescriptionTranslation = (
  t: TFunction,
  status: EvseOperationalStatus
): string => {
  switch (status.canonicalStatus) {
    case EvseOperationalCanonicalStatus.Onboarding:
      return t(
        'evseOperationalStatus.description.onboarding',
        'Device needs to be configured & number of connector set. Automatically transitions to Activation in Progress once completed.'
      );
    case EvseOperationalCanonicalStatus.Activation:
      return t(
        'evseOperationalStatus.description.activation',
        'Activation of station needed by setting the Account, Location, Billing plan & station costs. Automatically transitions to Live once completed.'
      );
    case EvseOperationalCanonicalStatus.Live:
      return t(
        'evseOperationalStatus.description.live',
        'Station is Live and operational. Subscription billing is running.'
      );
    case EvseOperationalCanonicalStatus.Archived:
      return t(
        'evseOperationalStatus.description.archived',
        'Station is archived. Subscription billing stops and it is unpublished in case roaming is enabled.'
      );
    case EvseOperationalCanonicalStatus.Disabled:
      return t(
        'evseOperationalStatus.description.disabled',
        'Station is Disabled and not in operation. Subscription billing stops and it is unpublished in case roaming is enabled.'
      );
    case EvseOperationalCanonicalStatus.OutOfService:
      return t(
        'evseOperationalStatus.description.outOfService',
        'Station is out of service. Subscription billing is running and it represented as out of order in case roaming is enabled.'
      );
    default:
      return status.description;
  }
};

export const evseOperationalStatusColorTranslation = (
  t: TFunction,
  color: EvseOperationalStatusColor
): string => {
  switch (color) {
    case EvseOperationalStatusColor.Red:
      return t('evseOperationalStatus.color.red', 'Red');
    case EvseOperationalStatusColor.Orange:
      return t('evseOperationalStatus.color.orange', 'Orange');
    case EvseOperationalStatusColor.Yellow:
      return t('evseOperationalStatus.color.yellow', 'Yellow');
    case EvseOperationalStatusColor.Olive:
      return t('evseOperationalStatus.color.olive', 'Olive');
    case EvseOperationalStatusColor.Green:
      return t('evseOperationalStatus.color.green', 'Green');
    case EvseOperationalStatusColor.Teal:
      return t('evseOperationalStatus.color.teal', 'Teal');
    case EvseOperationalStatusColor.Blue:
      return t('evseOperationalStatus.color.blue', 'Blue');
    case EvseOperationalStatusColor.Violet:
      return t('evseOperationalStatus.color.violet', 'Violet');
    case EvseOperationalStatusColor.Purple:
      return t('evseOperationalStatus.color.purple', 'Purple');
    case EvseOperationalStatusColor.Pink:
      return t('evseOperationalStatus.color.pink', 'Pink');
    case EvseOperationalStatusColor.Brown:
      return t('evseOperationalStatus.color.brown', 'Brown');
    case EvseOperationalStatusColor.Grey:
      return t('evseOperationalStatus.color.grey', 'Grey');
    case EvseOperationalStatusColor.Black:
      return t('evseOperationalStatus.color.black', 'Black');
    default:
      return color;
  }
};

export const evseOperationalStatusRoamingTranslation = (
  t: TFunction,
  roamingStatus: EvseOperationalStatusRoaming
): string => {
  switch (roamingStatus) {
    case EvseOperationalStatusRoaming.None:
      return t('evseOperationalStatus.roaming.none', 'None');
    case EvseOperationalStatusRoaming.Blocked:
      return t('evseOperationalStatus.roaming.blocked', 'Blocked');
    case EvseOperationalStatusRoaming.Inoperative:
      return t('evseOperationalStatus.roaming.inoperative', 'Inoperative');
    case EvseOperationalStatusRoaming.OutOfOrder:
      return t('evseOperationalStatus.roaming.outOfOrder', 'Out of Order');
    case EvseOperationalStatusRoaming.Planned:
      return t('evseOperationalStatus.roaming.planned', 'Planned');
    case EvseOperationalStatusRoaming.Removed:
      return t('evseOperationalStatus.roaming.removed', 'Removed');
    case EvseOperationalStatusRoaming.Reserved:
      return t('evseOperationalStatus.roaming.reserved', 'Reserved');
    default:
      return roamingStatus;
  }
};

export const evseOperationalStatusChargingStationTranslation = (
  t: TFunction,
  chargingStationStatus: EvseOperationalStatusChargingStation
): string => {
  switch (chargingStationStatus) {
    case EvseOperationalStatusChargingStation.None:
      return t('evseOperationalStatus.chargingStation.none', 'None');
    case EvseOperationalStatusChargingStation.Operative:
      return t('evseOperationalStatus.chargingStation.operative', 'Operative');
    case EvseOperationalStatusChargingStation.Inoperative:
      return t(
        'evseOperationalStatus.chargingStation.inoperative',
        'Inoperative'
      );
    default:
      return chargingStationStatus;
  }
};

export const evseOperationalStatusBillingTranslation = (
  t: TFunction,
  billingStatus: EvseOperationalStatusBilling
): string => {
  switch (billingStatus) {
    case EvseOperationalStatusBilling.None:
      return t('evseOperationalStatus.billing.none', 'None');
    case EvseOperationalStatusBilling.Enabled:
      return t('evseOperationalStatus.billing.enabled', 'Enabled');
    case EvseOperationalStatusBilling.Disabled:
      return t('evseOperationalStatus.billing.disabled', 'Disabled');
    default:
      return billingStatus;
  }
};
