export interface EditEvseControllersValues {
  evseControllerIds: string[];
  settings: {
    key: string;
    value: string;
  }[];
}

interface PresetSubOption {
  componentType: 'dropdown' | 'toggle';
  data: { value: string; label: string }[];
  componentProps?: { [key: string]: any };
}

interface CustomSubOption {
  componentType: 'custom';
  component: (objectPath: string) => JSX.Element;
}

export type SubOption = PresetSubOption | CustomSubOption;

// This should be aligned with the validator option key defined in EvseControllerBackgroundJobOption
export const DropDownOptionsWithSubOptionType = {
  accountId: 'accountId',
  billingPlanId: 'billingPlanId',
  scanToPay: 'scanToPay',
  fieldServiceAccount: 'fieldServiceAccount',
  chargingStationAvailability: 'chargingStationAvailability',
  location: 'location',
  labels: 'labels',
  roaming: 'roaming',
  accessGroups: 'accessGroups',
  tariffProfile: 'tariffProfile',
  evseOperationalStatusId: 'evseOperationalStatusId',
} as const;

export type DropDownOption = keyof typeof DropDownOptionsWithSubOptionType;

export interface DisallowedReason {
  header: string;
  message: string;
}
