import React, { createContext, useEffect, useContext, useMemo } from 'react';
import flagsmith from 'flagsmith';
import { FlagsmithProvider, useFlagsmith } from 'flagsmith/react';
import { useUser } from 'contexts/user';
import { captureError } from 'utils/sentry';

import { ENV_NAME, FLAGSMITH_API_URL, FLAGSMITH_CLIENT_KEY } from 'utils/env';
import { isSandboxMode } from 'utils/sandbox';

export enum FeatureFlags {
  // enables charging station operational statuses feature
  OperationalStatusesPage = 'operational_statuses_page',
  // enables configuration presets feature
  EvseConfigPresets = 'evse_config_presets',
}

interface FeatureContextType {
  hasFeature: (featureName: string | FeatureFlags) => boolean;
  isReady: boolean;
}

// defaultFeatureFlags is a map of feature flags that are available with a specific state by default.
// once we connect to our remote feature provider they are automatically updated with their remote state
// however this is helpful for graceful degredation, and local (networkless) development.
// See: https://docs.flagsmith.com/clients/javascript#providing-default-flags for more information
const defaultFeatureFlags = {
  sandbox: {
    enabled: true,
  },
  feature_name: {
    enabled: false,
  },
};

export const FeatureContext = createContext<FeatureContextType>({
  isReady: false,
  hasFeature: () => false,
});

const WrappedFeatureProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const flagsmithClient = useFlagsmith();
  const { user, provider, tokenPayload } = useUser();

  const [isReady, setIsReady] = React.useState(false);

  const updateFeatureFlags = async (user: any, provider: any) => {
    if (user && provider) {
      const traits = {
        sandboxMode: isSandboxMode(),
        userId: tokenPayload?.useSourceTraits
          ? tokenPayload?.sourceUser
          : user.id,
        accountId: tokenPayload?.useSourceTraits
          ? tokenPayload?.sourceAccount
          : user.accountId,
        providerId: provider.id,
        releaseVersion: process.env.RELEASE_VERSION,
        envName: ENV_NAME,
        accountRoles: user.accountRoles
          ?.map((role: { id: string }) => role.id)
          .join(','),
        providerRoles: user.providerRoles
          ?.map((role: { id: string }) => role.id)
          .join(','),
        globalRoles: user.globalRoles
          ?.map((role: { id: string }) => role.id)
          .join(','),
        roles: user.roles?.join(','),
        billingCountry: user.accountBillingCountry?.toUpperCase(),
      };
      try {
        await flagsmithClient.identify(user?.id || 'anonymous', traits);
      } catch (e) {
        captureError(e);
      }
      setIsReady(true);
    }
  };

  // ensure that uses of useFlags or the FeatureFlag component contain the context of the currently
  // authenticated user
  useEffect(() => {
    updateFeatureFlags(user, provider);
  }, [user?.id, provider?.id, tokenPayload]);

  const value = useMemo(() => {
    return {
      hasFeature: flagsmithClient.hasFeature,
      isReady: isReady,
    };
  }, [isReady]);

  return (
    <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
  );
};

export const FeatureProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <FlagsmithProvider
      options={{
        api: FLAGSMITH_API_URL,
        environmentID: FLAGSMITH_CLIENT_KEY,
        defaultFlags: defaultFeatureFlags,
      }}
      flagsmith={flagsmith}>
      <WrappedFeatureProvider>{children}</WrappedFeatureProvider>
    </FlagsmithProvider>
  );
};

// useFeatures is a hook that provides access to the FeatureContext
export const useFeatures = (): FeatureContextType => {
  const context = useContext(FeatureContext);
  if (context === undefined) {
    throw new Error('useFeatures must be used within a FeatureProvider');
  }

  return context;
};
