import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  ActionButton,
  Button,
  Checkbox,
  Container,
  Divider,
  Form,
  Header,
  HeaderSubheader,
} from 'semantic';
import { Breadcrumbs, Confirm, ErrorMessage, HelpTip } from 'components';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_CONFIG_PRESET_BE_PATH,
  EVSE_CONFIG_PRESETS_FE_PATH,
} from '../utils';
import { request } from 'utils/api';
import ConfigurationInputList, {
  buildChangeConfigurationItem,
  ConfigurationItemForm,
  isConfigValid,
} from 'screens/EvseControllersBackgroundJobs/ChangeConfiguration/ConfigurationInputList';
import { set } from 'lodash-es';
import { OCPPProtocolVersion } from 'types/evse-controller';
import { Loader, Message } from 'semantic-ui-react';
import {
  EvseConfigPreset,
  EvseConfigPresetItem,
} from 'types/evse-config-preset';
import useFetch from 'hooks/useFetch';

type FormState = {
  loading: boolean;
  apiError: Error | null;
  formError: Error | null;
};

type ConfigPresetForm = {
  configurationItems: ConfigurationItemForm[];
  name: string;
  isDefaultAutoConfiguration: boolean;
};

export default function EvseConfigPresetForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [formState, setFormState] = useState<FormState>({
    loading: false,
    apiError: null,
    formError: null,
  });
  const [formData, setFormData] = useState<ConfigPresetForm>({
    isDefaultAutoConfiguration: false,
    name: '',
    configurationItems: [buildChangeConfigurationItem()],
  });
  const [configPreset, setConfigPreset] = useState<EvseConfigPreset>({});
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  const getStandardKeyId = (item: EvseConfigPresetItem): string => {
    const protocol = item.ocppProtocolVersion.replaceAll('.', ''); // we need it in format ocpp16 or ocpp201
    const component = item.component?.name;

    return `${protocol}.${component ? `${component}.` : ''}${item.name}`;
  };

  const { data: preset, loading: presetLoading } = useFetch<EvseConfigPreset>({
    path: `${EVSE_CONFIG_PRESET_BE_PATH}/${id}`,
    disabled: !id,
  });

  useEffect(() => {
    if (!preset) return;

    setConfigPreset(preset);
    setFormData({
      isDefaultAutoConfiguration: preset.isDefaultAutoConfiguration,
      name: preset.name,
      configurationItems: preset.configurationItems.map((item, index) => ({
        ...item,
        id: (index + 1).toString(), // we need to start with 1
        standardKeyId: getStandardKeyId(item), // required to identify the standard key in the list
        edit: false,
      })),
    });
  }, [preset]);

  const setField = (name: string, value: any, nullable = false) => {
    const parsedValue = value === '' && nullable ? null : value;
    setFormData(set({ ...formData }, name, parsedValue));
    setIsFormChanged(true);
  };

  const confirmTranslations: () => [React.ReactNode, React.ReactNode] =
    useCallback(() => {
      // create form, preset was chosen as auto-configuration
      if (!id && formData.isDefaultAutoConfiguration) {
        return [
          <Trans i18nKey="evseConfigPresets.confirmUseAsAutoConfig">
            Use this preset as auto-configuration override?
          </Trans>,
          <Trans i18nKey="evseConfigPresets.confirmUsePresetAsAutoConfigDescription">
            <>
              <p>
                Please confirm that you want to use the preset "Custom Params
                BYES" as the auto-configuration override.
              </p>
              <p>
                This update will apply to all charge stations onboarded on this
                provider.
              </p>
            </>
          </Trans>,
        ];
      }

      // update form, auto-configuration is unchecked
      if (
        id &&
        !formData.isDefaultAutoConfiguration &&
        configPreset.isDefaultAutoConfiguration
      ) {
        return [
          <Trans i18nKey="evseConfigPresets.confirmDisableAutoConfig">
            Are you sure you want to disable the auto-configuration override?
          </Trans>,
          <Trans
            i18nKey="evseConfigPresets.confirmDisableAutoConfigDescription"
            values={{ name: configPreset.name }}>
            <>
              <p>
                The preset <b>"{{ name }}"</b> is currently being used as an
                auto-configuration override.
              </p>
              <p>
                If you no longer want to use this preset as the override, the
                system default parameters and values will be applied.
              </p>
            </>
          </Trans>,
        ];
      }

      // update form, form is changed for auto-configuration
      if (id && isFormChanged && configPreset.isDefaultAutoConfiguration) {
        return [
          <Trans i18nKey="evseConfigPresets.confirmUpdateAutoConfig">
            Are you sure you want to update this preset?
          </Trans>,
          <Trans i18nKey="evseConfigPresets.confirmUpdateAutoConfigDescription">
            <>
              <p>
                Updating this preset will impact the auto-configuration for all
                charging stations onboarded on this provider going forward.
              </p>
              <p>You can update this preset or delete all overrides anytime.</p>
            </>
          </Trans>,
        ];
      }
      return [null, null];
    }, [id, formData, isFormChanged]);

  const submitForm = useCallback(async () => {
    setFormState({
      loading: true,
      apiError: null,
      formError: null,
    });

    if (!formData.configurationItems.length) {
      setFormState({
        loading: false,
        apiError: null,
        formError: new Error('At least one configuration item is required'),
      });
      return;
    }

    const allConfigurationFieldsValid = formData.configurationItems.every(
      (cfgItem) => isConfigValid(cfgItem, [cfgItem.ocppProtocolVersion])
    );
    if (!allConfigurationFieldsValid) {
      setFormState({
        loading: false,
        apiError: null,
        formError: new Error('All configuration fields must be filled'),
      });
      return;
    }

    try {
      await request({
        method: id ? 'PATCH' : 'POST',
        path: `${EVSE_CONFIG_PRESET_BE_PATH}${id ? `/${id}` : ''}`,
        body: {
          ...formData,
          // Remove non-API fields
          configurationItems: formData.configurationItems.map((item) => ({
            ...item,
            id: undefined,
            label: undefined,
            input: undefined,
            standardKeyId: undefined,
            edit: undefined,
          })),
        },
      });

      setFormState({ loading: false, apiError: null, formError: null });
      history.push(EVSE_CONFIG_PRESETS_FE_PATH);
    } catch (e: any) {
      setFormState({ loading: false, apiError: e, formError: null });
    }
  }, [formData]);

  if (presetLoading) {
    return <Loader active style={{ marginTop: '10%' }} />;
  }

  const [confirmTitle, confirmDesc] = confirmTranslations();

  return (
    <Container>
      <Breadcrumbs
        path={[
          <Link to={CHARGING_STATIONS_FE_PATH}>
            {t(
              'evseConfigPresets.breadcrumbsChargingStations',
              'Charging Stations'
            )}
          </Link>,
          <Link to={EVSE_CONFIG_PRESETS_FE_PATH}>
            {t('evseConfigPresets.breadcrumbsList', 'Configuration Presets')}
          </Link>,
        ]}
        active={
          id
            ? t('evseConfigPresets.breadcrumbsEdit', 'Edit Preset')
            : t('evseConfigPresets.breadcrumbsCreate', 'Create Preset')
        }
      />
      <Header as="h2">
        {id
          ? t('evseConfigPresets.editTitle', 'Edit Configuration Preset')
          : t(
              'evseConfigPresets.createTitle',
              'Create new Configuration Preset'
            )}
      </Header>
      <Divider hidden />

      <Form error={Boolean(formState.formError)} onSubmit={submitForm}>
        <div>
          <Header as="h4">
            {t('evseConfigPresets.inputName', '1. Name the preset')}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseConfigPresets.inputNameDescription',
                'Give your preset a clear, descriptive name for easy identification later.'
              )}
            </HeaderSubheader>
          </Header>
          <Form.Input
            required
            name="name"
            placeholder={t(
              'evseConfigPresets.inputNamePlaceholder',
              'Type here...'
            )}
            value={formData.name}
            type="input"
            label={t('evseConfigPresets.inputNameLabel', 'Name')}
            onChange={(e, { name, value }) => setField(name, value)}
          />
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseConfigPresets.defineConfigurationTitle',
              '2. Define OCPP Configuration'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseConfigPresets.defineConfigurationSubTitle',
                'Select from the list of standard OCPP configuration parameters, or use the custom configuration to update charge station specific parameters.'
              )}
            </HeaderSubheader>
          </Header>

          <ConfigurationInputList
            ocppProtocols={[
              OCPPProtocolVersion.OCPP15,
              OCPPProtocolVersion.OCPP16,
              OCPPProtocolVersion.OCPP201,
            ]}
            items={formData.configurationItems}
            onChange={(items: ConfigurationItemForm[]) =>
              setField('configurationItems', items)
            }
          />
        </div>

        <Divider />

        <Message>
          <Form.Field>
            <div style={{ display: 'flex' }}>
              <Checkbox
                as=""
                style={{ fontWeight: 'normal' }}
                onChange={(e: any, { checked }: any) => {
                  setField('isDefaultAutoConfiguration', checked);
                }}
                toggle
                checked={formData.isDefaultAutoConfiguration}
                label={t(
                  'evseConfigPresets.inputUseAsAutoConfiguration',
                  'Use this preset to override or add parameters to the system default auto-configuration.'
                )}
              />
              <HelpTip
                iconCircular
                closeIcon
                disabledIconTitle
                style={{ marginLeft: 10 }}
                color="grey"
                iconTooltip="info"
                size="tiny"
                title={t(
                  'evseConfigPresets.inputUseAsAutoConfigurationHelpTitle',
                  'Override for auto-configuration'
                )}
                text={
                  <Trans i18nKey="evseConfigPresets.inputUseAsAutoConfigurationHelpDescription">
                    <>
                      <p>
                        Running the auto-configuration of a charging station
                        applies default system values to a predefined set of
                        OCPP parameters. To adjust these values or include
                        additional parameters, use a Configuration Preset.
                      </p>
                      <p>
                        Parameters defined in the Configuration Preset will
                        override the defaults, while unspecified parameters will
                        retain their system default values.
                      </p>
                      <p>
                        For more details on system defaults and how to override
                        them, visit our support page via link below.
                      </p>
                    </>
                  </Trans>
                }
                actionsJustify="space-between"
                actions={[
                  <Link to="#" target="_blank">
                    <ActionButton primary compact>
                      {t(
                        'evseConfigPresets.inputUseAsAutoConfigurationHelp',
                        'More about auto-configuration'
                      )}
                    </ActionButton>
                  </Link>,
                ]}
              />
            </div>
          </Form.Field>
        </Message>

        <ErrorMessage error={formState.apiError} />
        <ErrorMessage error={formState.formError} />

        {confirmTitle && confirmDesc ? (
          <Confirm
            size="tiny"
            header={confirmTitle}
            content={confirmDesc}
            trigger={
              <Button
                loading={formState.loading}
                disabled={formState.loading}
                content={
                  id
                    ? t('evseConfigPresets.updateButton', 'Update Preset')
                    : t('evseConfigPresets.saveButton', 'Save Preset')
                }
                type="button"
              />
            }
            onConfirm={submitForm}
          />
        ) : (
          <Button
            loading={formState.loading}
            disabled={formState.loading}
            content={
              id
                ? t('evseConfigPresets.updateButton', 'Update Preset')
                : t('evseConfigPresets.saveButton', 'Save Preset')
            }
            type="submit"
          />
        )}

        <Link to={formState.loading ? '#' : EVSE_CONFIG_PRESETS_FE_PATH}>
          <Button
            disabled={formState.loading}
            content={t('evseConfigPresets.cancelButton', 'Cancel')}
            basic
            type="button"
          />
        </Link>
      </Form>
    </Container>
  );
}
