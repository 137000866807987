import React, { useState } from 'react';

import { Button, Form, Modal } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { DropdownItemProps, Message } from 'semantic-ui-react';
import * as Yup from 'yup';
import { capitalize } from 'lodash-es';

import InputField from 'components/form-fields/formik/InputField';
import {
  EvseOperationalStatus,
  EvseOperationalStatusColor,
  EvseOperationalStatusBilling,
  EvseOperationalStatusChargingStation,
  EvseOperationalStatusRoaming,
} from 'types/evse-operational-status';
import DropdownField from 'components/form-fields/formik/DropdownField';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import { request } from 'utils/api';
import AsyncModal from 'helpers/async-modal';
import {
  evseOperationalStatusBillingTranslation,
  evseOperationalStatusChargingStationTranslation,
  evseOperationalStatusColorTranslation,
  evseOperationalStatusNameTranslation,
  evseOperationalStatusRoamingTranslation,
} from 'utils/evse-operational-status';

interface Props {
  evseOperationalStatus?: EvseOperationalStatus;
  evseOperationalSystemStatuses: EvseOperationalStatus[];
  onSuccessfulSubmit?: () => void;
  close: () => void;
}

interface ColorOption extends DropdownItemProps {
  key: EvseOperationalStatusColor;
  text: string;
  value: EvseOperationalStatusColor;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
  badgeColor: Yup.string()
    .oneOf(Object.values(EvseOperationalStatusColor))
    .required(),
  isInitial: Yup.boolean().optional(),
  chargingStationStatus: Yup.string()
    .oneOf(Object.values(EvseOperationalStatusChargingStation))
    .required(),
  roamingStatus: Yup.string()
    .oneOf(Object.values(EvseOperationalStatusRoaming))
    .required(),
  billingStatus: Yup.string()
    .oneOf(Object.values(EvseOperationalStatusBilling))
    .required(),
  parentEvseOperationalStatusId: Yup.string().nullable().optional(),
});

const useInitialValues = (
  evseOperationalStatus?: EvseOperationalStatus
): Partial<EvseOperationalStatus> => {
  return {
    name: evseOperationalStatus?.name || '',
    description: evseOperationalStatus?.description || '',
    badgeColor:
      evseOperationalStatus?.badgeColor || EvseOperationalStatusColor.Grey,
    chargingStationStatus:
      evseOperationalStatus?.chargingStationStatus ||
      EvseOperationalStatusChargingStation.None,
    billingStatus:
      evseOperationalStatus?.billingStatus || EvseOperationalStatusBilling.None,
    roamingStatus:
      evseOperationalStatus?.roamingStatus || EvseOperationalStatusRoaming.None,
    isInitial: evseOperationalStatus?.isInitial || false,
    parentEvseOperationalStatusId:
      evseOperationalStatus?.parentEvseOperationalStatusId,
  };
};

const EditEvseOperationalStatus: React.FC<Props> = ({
  evseOperationalStatus,
  evseOperationalSystemStatuses = [],
  onSuccessfulSubmit,
  close,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);
  const initialValues = useInitialValues(evseOperationalStatus);

  const handleSubmit = async (values: Partial<EvseOperationalStatus>) => {
    setError(null);

    try {
      if (evseOperationalStatus?.id) {
        await request({
          method: 'PATCH',
          path: `/1/evse-operational-statuses/${evseOperationalStatus?.id}`,
          body: values,
        });
      } else {
        await request({
          method: 'POST',
          path: `/1/evse-operational-statuses`,
          body: values,
        });
      }
      if (onSuccessfulSubmit) {
        onSuccessfulSubmit();
      }
      close();
    } catch (e) {
      setError(e as Error);
    }
  };

  const badgeColorOptions: ColorOption[] = Object.values(
    EvseOperationalStatusColor
  ).map((color) => ({
    key: color,
    value: color,
    text: capitalize(evseOperationalStatusColorTranslation(t, color)),
  }));

  const roamingStatusOptions: DropdownItemProps[] = Object.values(
    EvseOperationalStatusRoaming
  ).map((status) => ({
    key: status,
    value: status,
    text: capitalize(evseOperationalStatusRoamingTranslation(t, status)),
  }));

  const chargerStatusOptions: DropdownItemProps[] = Object.values(
    EvseOperationalStatusChargingStation
  ).map((status) => ({
    key: status,
    value: status,
    text: capitalize(
      evseOperationalStatusChargingStationTranslation(t, status)
    ),
  }));

  const billingStatusOptions: DropdownItemProps[] = Object.values(
    EvseOperationalStatusBilling
  ).map((status) => ({
    key: status,
    value: status,
    text: capitalize(evseOperationalStatusBillingTranslation(t, status)),
  }));

  const parentOperationalStatusOptions: DropdownItemProps[] =
    evseOperationalSystemStatuses.map((status) => ({
      key: status.id,
      value: status.id,
      text: evseOperationalStatusNameTranslation(t, status),
    }));

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting, values, setValues }) => (
        <>
          <Modal.Header>
            {evseOperationalStatus?.id
              ? t('evseOperationalStatusEditModal.titleEdit', 'Edit Status')
              : t(
                  'evseOperationalStatusEditModal.titleCreate',
                  'Create Status'
                )}
          </Modal.Header>
          <Modal.Content>
            {error && <Message error content={error.message} />}
            <Form name="evse-operational-status-form" onSubmit={handleSubmit}>
              <InputField
                name="name"
                label={t('evseOperationalStatusEditModal.nameLabel', 'Name')}
                type="text"
                required
              />
              <InputField
                name="description"
                label={t(
                  'evseOperationalStatusEditModal.descriptionLabel',
                  'Description'
                )}
                type="text"
                required
              />
              <DropdownField
                name="badgeColor"
                label={t(
                  'evseOperationalStatusEditModal.badgeColorLabel',
                  'Color'
                )}
                options={badgeColorOptions}
                fluid
                selection
                clearable={false}
                required
              />
              <CheckboxField
                name="isInitial"
                label={t(
                  'evseOperationalStatusEditModal.isInitialLabel',
                  'Is Initial'
                )}
              />
              <DropdownField
                name="parentEvseOperationalStatusId"
                label={t(
                  'evseOperationalStatusEditModal.parentStatusLabel',
                  'Parent Status'
                )}
                options={parentOperationalStatusOptions}
                fluid
                selection
                postChange={async (id) => {
                  const systemStatus = evseOperationalSystemStatuses.find(
                    (s) => id === s.id
                  );

                  await setValues({
                    ...values,
                    ...(systemStatus
                      ? {
                          parentEvseOperationalStatusId: systemStatus.id,
                          canonicalStatus: systemStatus.canonicalStatus,
                          chargingStationStatus:
                            systemStatus.chargingStationStatus,
                          billingStatus: systemStatus.billingStatus,
                          roamingStatus: systemStatus.roamingStatus,
                        }
                      : { parentEvseOperationalStatusId: null }),
                  });
                }}
              />
              <DropdownField
                name="roamingStatus"
                label={t(
                  'evseOperationalStatusEditModal.roamingStatusLabel',
                  'Roaming Status'
                )}
                options={roamingStatusOptions}
                fluid
                selection
                clearable={false}
                required
                disabled={!!values.parentEvseOperationalStatusId}
              />
              <DropdownField
                name="chargingStationStatus"
                label={t(
                  'evseOperationalStatusEditModal.chargingStationStatusLabel',
                  'Charger Status'
                )}
                options={chargerStatusOptions}
                fluid
                selection
                clearable={false}
                required
                disabled={!!values.parentEvseOperationalStatusId}
              />
              <DropdownField
                name="billingStatus"
                label={t(
                  'evseOperationalStatusEditModal.billingStatusLabel',
                  'Billing Status'
                )}
                options={billingStatusOptions}
                fluid
                selection
                clearable={false}
                required
                disabled={!!values.parentEvseOperationalStatusId}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              content={t('common.cancel', 'Cancel')}
              onClick={close}
            />
            <Button
              primary
              as="button"
              form="evse-operational-status-form"
              loading={isSubmitting}
              onClick={handleSubmit}
              type="submit">
              {evseOperationalStatus?.id
                ? t('button.update', 'Update')
                : t('button.create', 'Create')}
            </Button>
          </Modal.Actions>
        </>
      )}
    </Formik>
  );
};

export default AsyncModal(EditEvseOperationalStatus);
