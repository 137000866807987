import { capitalize } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from '../semantic';
import { EvseOperationalStatus } from 'types/evse-operational-status';
import { evseOperationalStatusNameTranslation } from 'utils/evse-operational-status';

type OperationalStatusProps = {
  evseOperationalStatus: EvseOperationalStatus;
};

export function OperationalStatus({
  evseOperationalStatus,
}: OperationalStatusProps) {
  const { t } = useTranslation();
  const name = evseOperationalStatusNameTranslation(t, evseOperationalStatus);

  return (
    <>
      <Label
        title={capitalize(name)}
        content={capitalize(name)}
        color={evseOperationalStatus.badgeColor}
      />
    </>
  );
}
