import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import { request } from 'utils/api';
import { Container, Divider, Message, Segment } from 'semantic';
import Table from 'components/tables/Sessions';
import { omit } from 'lodash-es';
import {
  Breadcrumbs,
  Layout,
  ListHeader,
  Search,
  SearchFilters,
} from 'components';
import { useTranslation } from 'react-i18next';
import { getMaxRange } from 'utils/filters';
import FeatureFlag from 'components/FeatureFlag';
import { SUPPORTED_CURRENCIES } from 'components/Layout/utils';
import {
  sessionStatusOptions,
  sessionExcludedReasonsOptionsLabel,
} from 'utils/session';

import './SessionsCpo.css';

const maxRange = 90;
const itemLimit = 100;

export default function Sessions() {
  const { t } = useTranslation();

  const filterMapping = () => {
    return {
      searchPhrase: {
        type: 'search',
      },
      onlyActive: {
        label: 'Only Active',
        type: 'boolean',
      },
      onlyUnInvoiced: {
        label: 'Only Un-Invoiced',
        type: 'boolean',
      },
      currency: {
        label: 'Currency',
        type: 'dropdown',
      },
      syncHasErrors: {
        label: 'Sync Has Errors',
        type: 'boolean',
      },
      excluded: {
        label: 'Excluded',
        type: 'boolean',
      },
      excludedReason: {
        label: 'Excluded Reason',
        type: 'dropdown',
      },
      unqualifiedSync: {
        label: 'Unqualified',
        type: 'boolean',
      },
      endedAt: {
        label: 'Ended At',
        type: 'date',
        range: true,
      },
      paymentStatus: {
        label: 'Payment Status',
        type: 'object',
      },
      paymentFlow: {
        label: 'Payment Flow',
        type: 'object',
      },
    };
  };

  useEffect(() => {
    fetchAvailableFilters();
  }, []);

  const [activeOnly, setActiveOnly] = useState(false);
  const [availableFilters, setAvailableFilters] = useState({});

  const searchRef = React.useRef();

  const onDataNeeded = (filters = {}) => {
    const { limit, sort, ...rest } = filters;

    const shouldRestrictEndedAt =
      Object.keys(omit(rest, ['limit', 'skip', 'searchPhrase'])).length > 0 &&
      !rest.onlyActive;

    // This converts the payment status from {..., payment.status.someStatus: true, payment.status.someOtherStatus: true}
    // Into {..., paymentStatuses: ['someStatus', 'someOtherStatus']}.
    // And cleans the request from the payment.status.* fields.
    const paymentStatuses = Object.keys(filters)
      .filter(
        (key) => key.startsWith('payment.status') && filters[key] === true
      )
      .map((key) => {
        const status = key.split('.').pop();
        delete rest[key];
        return status;
      });

    // This converts the payment flow from {..., payment.productPaymentFlow.flow1: true, payment.productPaymentFlow.flow2: true}
    // Into {..., paymentFlows: ['flow1', 'flow2']}.
    // And cleans the request from the payment.productPaymentFlow.* fields.
    const paymentFlows = Object.keys(filters)
      .filter(
        (key) =>
          key.startsWith('payment.productPaymentFlow') && filters[key] === true
      )
      .map((key) => {
        const status = key.split('.').pop();
        delete rest[key];
        return status;
      });

    return request({
      method: 'POST',
      path: `/2/sessions/cpo/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: {
        endedAt: shouldRestrictEndedAt ? getMaxRange(maxRange) : undefined,
        ...rest,
        currencyConvertMode: 'to-eur',
        limit: limit || itemLimit,
        paymentStatuses,
        paymentFlows,
      },
    });
  };

  const labels = {
    onlyActive: t('sessionsCpo.onlyActive', 'Only Active'),
    onlyInvoiced: t('sessionsCpo.onlyInvoiced', 'Only Invoiced'),
    onlyUnInvoiced: t('sessionsCpo.onlyUnInvoiced', 'Only Un-Invoiced'),
    syncHasErrors: t('sessionsCpo.syncHasErrors', 'Sync Has Errors'),
    currency: t('sessionsCpo.currency', 'Currency'),
    excludedReason: t('sessionsCpo.excludedReason', 'Excluded Reason'),
    excluded: t('sessionsCpo.excluded', 'Excluded from Billing'),
    unqualifiedSync: t('sessionsCpo.unqualifiedSync', 'Unqualified'),
    endedAt: t('common.endedAt', 'Ended At'),
    startedAt: t('common.startedAt', 'Started At'),
    paymentStatus: t('common.paymentStatus', 'Payment Status'),
    paymentFlow: t('common.paymentFlow', 'Payment Flow'),
    changeCardProviders: t(
      'sessionsCpo.changeCardProviders',
      'Charge Card Providers'
    ),
  };

  const excludedReasonOptionsTranslated = sessionStatusOptions.map((option) => {
    return {
      key: option,
      value: option,
      text: sessionExcludedReasonsOptionsLabel(t, option),
    };
  });

  async function fetchAvailableFilters() {
    if (availableFilters.data || availableFilters.loading) {
      return;
    }

    setAvailableFilters({
      loading: true,
    });

    try {
      const { data } = await request({
        method: 'GET',
        path: '/2/sessions/cpo/available-filters',
      });
      setAvailableFilters({
        data: data,
      });
    } catch (e) {
      setAvailableFilters({
        error: e,
      });
    }
  }

  return (
    <AppWrapper>
      <Container>
        <Search.Provider
          ref={searchRef}
          limit={itemLimit}
          onDataNeeded={onDataNeeded}
          filterMapping={filterMapping}>
          <Breadcrumbs
            path={[
              <Link key="charging-stations" to="/charging-stations">
                {t('menu.cpoChargeStations', 'Charging stations')}
              </Link>,
            ]}
            active={t('sessionsCpo.title', 'Sessions')}
          />

          <ListHeader title={t('sessionsCpo.title', 'Sessions')}>
            <Search.Export filename="sessions-cpo" />
          </ListHeader>

          <Divider hidden />

          <Segment>
            <Layout horizontal spread stackable>
              <SearchFilters.ModalFilterV2 size="tiny">
                <Message info>
                  {t(
                    'sessionsCpo.filterMessage',
                    'Below filters will all be limited to last 90 days of data, unless "Ended At" is specified.'
                  )}
                </Message>
                <SearchFilters.Checkbox
                  label={labels.onlyActive}
                  name="onlyActive"
                  setStateFunction={() => setActiveOnly(true)}
                />
                <SearchFilters.Checkbox
                  label={labels.onlyInvoiced}
                  name="onlyInvoiced"
                />
                <SearchFilters.Checkbox
                  label={labels.onlyUnInvoiced}
                  name="onlyUnInvoiced"
                />
                <SearchFilters.Checkbox
                  label={labels.unqualifiedSync}
                  name="unqualifiedSync"
                />
                <SearchFilters.Checkbox
                  label={labels.syncHasErrors}
                  name="syncHasErrors"
                />
                <span className={'filter-label'}>{labels.paymentStatus}</span>
                <SearchFilters.Checkbox
                  label={t('payment.status.paid', 'Paid')}
                  name="payment.status.paid"
                />
                <SearchFilters.Checkbox
                  label={t('payment.status.authorized', 'Authorized')}
                  name="payment.status.authorized"
                />
                <SearchFilters.Checkbox
                  label={t('payment.status.refunded', 'Refunded')}
                  name="payment.status.refunded"
                />
                <SearchFilters.Checkbox
                  label={t('payment.status.voided', 'Voided')}
                  name="payment.status.voided"
                />
                <SearchFilters.Checkbox
                  label={t('payment.status.cancelled', 'Cancelled')}
                  name="payment.status.cancelled"
                />

                <span className={'filter-label'}>{labels.paymentFlow}</span>
                <SearchFilters.Checkbox
                  label={t('payment.productPaymentFlow.taptopay', 'Tap to Pay')}
                  name="payment.productPaymentFlow.tap-to-pay"
                />
                <SearchFilters.Checkbox
                  label={t(
                    'payment.productPaymentFlow.scantopay',
                    'Scan to Pay'
                  )}
                  name="payment.productPaymentFlow.scan-to-pay"
                />
                <SearchFilters.Checkbox
                  label={t(
                    'payment.productPaymentFlow.chargeCard',
                    'Charge Card'
                  )}
                  name="payment.productPaymentFlow.charge-card"
                />

                <SearchFilters.DropdownFilterV2
                  name="tokenInfraProviderIds"
                  multiple
                  error={availableFilters.error?.message}
                  loading={availableFilters.loading}
                  options={(availableFilters.data?.tokenInfraProviderIds || [])
                    .filter((item) => {
                      return item.key?.length;
                    })
                    .map((item) => {
                      return {
                        key: item.key,
                        value: item.key,
                        text: `${item.key}`,
                      };
                    })}
                  label={labels.changeCardProviders}
                />

                <FeatureFlag feature="multi_currency_support">
                  <SearchFilters.Dropdown
                    name="currency"
                    options={SUPPORTED_CURRENCIES}
                    label={labels.currency}
                  />
                </FeatureFlag>
                <SearchFilters.Dropdown
                  multiple
                  name="excludedReason"
                  options={excludedReasonOptionsTranslated}
                  label={labels.excludedReason}
                />
                <SearchFilters.Checkbox
                  label={labels.excluded}
                  name="excluded"
                />
                {!activeOnly && (
                  <>
                    <Divider horizontal />
                    <SearchFilters.DateRange
                      maxRange={maxRange}
                      label={labels.endedAt}
                      name="endedAt"
                    />
                  </>
                )}
              </SearchFilters.ModalFilterV2>

              <Layout horizontal stackable center right>
                <Search.Total />
                <SearchFilters.Search
                  name="searchPhrase"
                  placeholder={t(
                    'sessions.searchPlaceholder',
                    'Search by ID, Account ID, External ID or User ID'
                  )}
                />
              </Layout>
            </Layout>
          </Segment>
          <Search.Status noResults={t('common.noResults', 'No Results')} />

          <Table
            includeSyncStatus
            includeResyncAction
            includeStopAction
            includeLocation
          />
          <Divider hidden />

          <div
            style={{
              textAlign: 'center',
            }}>
            <Search.Pagination />
          </div>
        </Search.Provider>
      </Container>
    </AppWrapper>
  );
}
