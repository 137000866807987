import { BillingPlan } from 'types/billingplan';

export default function useAccountTierBillingPlanDefaults(): BillingPlan {
  return {
    providerContext: 'cpo',
    type: 'account-tier',
    paymentCyclePeriod: 'MONTHLY',
    subscriptionCyclePeriod: 'YEARLY',
    minimumTermDuration: 'P1Y',
    maximumTermDuration: undefined,
    subscriptionBillingPolicy: 'CALENDAR_MONTH_ANNIVERSARY',
    refundPolicy: 'NO_REFUND',
    coolOffPeriod: undefined,
    billingPeriod: 'month',
    details: {
      en: {
        name: 'Account Tier Package',
        features: [
          'Custom tariffs per user',
          'Scheduled tariffs',
          'Custom location name on maps',
          'Account analytics',
          'Idle Fee',
          'Dynamic pricing based on actual energy market prices',
        ],
      },
      nl: {
        features: [
          'Custom tarieven per gebruiker',
          'Geplande tarieven',
          'Aangepaste locatienaam op kaarten',
          'Account Analytics',
          'Blokkeertarief',
          'Dynamische tarieven gebaseerd op huidige energiemarktprijzen',
        ],
      },
      de: {
        features: [
          'Benutzerdefinierte Tarife pro Benutzer',
          'Geplante Tarife',
          'Benutzerdefinierter Standortname auf Karten',
          'Konto-Analytik',
          'Blockiergebühr',
          'Dynamische Preisgestaltung basierend auf tatsächlichen Energiemarktpreisen',
        ],
      },
      fr: {
        features: [
          'Tarifs personnalisés par utilisateur',
          'Tarifs programmés',
          'Nom de lieu personnalisé sur les cartes',
          'Analyse de compte',
          "Frais d'inactivité",
          'Tarification dynamique basée sur les prix réels du marché de l’énergie',
        ],
      },
      it: {
        features: [
          'Tariffe personalizzate per utente',
          'Tariffe programmate',
          'Nome della posizione personalizzato sulle mappe',
          'Analisi del conto',
          'Commissione di inattività',
          'Tariffazione dinamica basata sui prezzi effettivi del mercato energetico',
        ],
      },
      es: {
        features: [
          'Tarifa personalizada por usuario',
          'Tarifa programada',
          'Nombre personalizado de la ubicación en el mapa',
          'Analítica de la cuenta',
          'Tarifa de inactividad',
          'Tarifa dinámica basada en el precio real del mercado energético',
        ],
      },
    },
    isPublic: false,
    deleted: false,
  } as unknown as BillingPlan;
}
