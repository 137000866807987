import React from 'react';

import { Dropdown } from 'semantic';

import InspectObject from 'components/modals/InspectObject';
import SyncLogStatus from 'components/modals/SyncLogStatus';
import { useTranslation } from 'react-i18next';

import { Confirm } from 'components';
import { request } from 'utils/api';
import { useSandbox } from 'contexts/sandbox';
import { IS_STAGING } from 'utils/env';
import { currentUserIsSuperAdmin } from 'utils/roles';

export default function AccountActions({ item, onReload } = {}) {
  const { t } = useTranslation();
  const { sandboxMode } = useSandbox();

  function onDelete() {
    return request({
      method: 'DELETE',
      path: `/1/accounts/${item.id}`,
    });
  }

  function onRestore() {
    return request({
      method: 'POST',
      path: `/1/accounts/${item.id}/restore`,
    });
  }

  function onGenerateSandboxObjects() {
    return request({
      method: 'POST',
      path: `/1/accounts/${item.id}/generate-sandbox-objects`,
    });
  }

  const fetchAccount = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/accounts/${item.id}`,
    });

    return data;
  };

  let generateSandboxObjectsButton = null;
  // temporarily allow staging environment until all customers have migrated to sandbox
  if (sandboxMode || IS_STAGING) {
    generateSandboxObjectsButton = (
      <Confirm
        header="Generate sandbox objects"
        content={
          'This operation will populate example objects for this account. This includes two provider level billing plans and a maintenance token. Continue?'
        }
        trigger={
          <Dropdown.Item icon="folder-plus" text="Generate Sandbox Objects" />
        }
        onConfirm={async () => {
          await onGenerateSandboxObjects(item);
          await onReload();
        }}
      />
    );
  }

  return (
    <>
      {currentUserIsSuperAdmin() && (
        <SyncLogStatus
          getData={fetchAccount}
          modelName="account"
          size="fullscreen"
          trigger={
            <Dropdown.Item text="Sync status" icon="wand-magic-sparkles" />
          }
        />
      )}
      {!item.deleted && (
        <Confirm
          header={t(
            'deleteDialog.header',
            'Are you sure you want to delete {{name}}?',
            { name: item.name }
          )}
          content={t(
            'deleteDialog.content',
            'All data will be permanently deleted'
          )}
          trigger={<Dropdown.Item icon="trash" text="Delete" />}
          onConfirm={async () => {
            await onDelete(item);
            await onReload();
          }}
        />
      )}
      {item.deleted && (
        <Confirm
          header={t(
            'restoreDialog.header',
            'Are you sure you want to restore {{name}}?',
            { name: item.name }
          )}
          content={t('restoreDialog.content', 'The object will be restored')}
          trigger={
            <Dropdown.Item
              icon="arrow-rotate-left"
              text={t('restoreDialog.action', 'Restore')}
            />
          }
          onConfirm={async () => {
            await onRestore(item);
            await onReload();
          }}
        />
      )}
      {generateSandboxObjectsButton}
      <InspectObject
        name="Account"
        getData={fetchAccount}
        trigger={<Dropdown.Item text="Inspect" icon="code" />}
      />
    </>
  );
}
