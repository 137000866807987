import { SignedMeterData } from 'types/sessions';

export function decodeBase64IfNeeded(signedData: SignedMeterData): string {
  if (signedData.ocmfEncoding === 'base64') {
    try {
      // Decode base64 string
      return atob(signedData.signedMeterData);
    } catch (error) {
      console.error('Error decoding base64 data:', error);
      return 'Invalid base64 data';
    }
  }
  // Return original value if no decoding is needed
  return signedData.signedMeterData;
}
