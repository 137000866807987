import React, { useState } from 'react';

import {
  Button,
  Modal,
  Message,
  Icon,
  Loader,
  Accordion,
  ActionButton,
} from 'semantic';
import { useTranslation } from 'react-i18next';
import { upperCase } from 'lodash-es';
import { Link } from 'react-router-dom';

import { request } from 'utils/api';
import { EvseController } from 'types/evse-controller';
import { SelectableButton } from 'components/SelectableButton';
import { useUser } from 'contexts/user';
import { canAccess } from 'utils/roles';
import useFetch from 'hooks/useFetch';
import { EvseOperationalStatus } from 'types/evse-operational-status';
import { evseOperationalStatusDescriptionTranslation } from 'utils/evse-operational-status';
import { OperationalStatus } from 'components/OperationalStatus';
import { EVSE_OPERATIONAL_STATUSES_FE_PATH } from 'screens/EvseOperationalStatuses/utils';

type Props = {
  evseController: EvseController;
  onUpdated?: () => void;
  trigger: React.ReactNode;
};

export default function EvseOperationalStatusUpdateModal({
  evseController,
  onUpdated,
  trigger,
}: Props) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { user, provider } = useUser();
  const [
    errorUpdateEvseOperationalStatus,
    setErrorUpdateEvseOperationalStatus,
  ] = useState<Error | null>(null);
  const [
    loadingUpdateEvseOperationalStatus,
    setLoadingUpdateEvseOperationalStatus,
  ] = useState(false);
  const [selectedEvseOperationalStatusId, setSelectedEvseOperationalStatusId] =
    useState<string>(evseController.evseOperationalStatusId);
  const [
    unavailableEvseOperationalStatusesOpen,
    setUnavailableEvseOperationalStatusesOpen,
  ] = useState(false);

  if (!user) return <Loader active />;

  const canUserReadWriteEvseControllers = canAccess(
    user,
    provider?.id || '',
    user.accountId || '',
    'evseControllers',
    'read-write'
  );

  const canUserReadWriteEvseControllersMaintenance = canAccess(
    user,
    provider?.id || '',
    user.accountId || '',
    'evseControllersMaintenance',
    'read-write'
  );

  let apiPath = '';
  if (canUserReadWriteEvseControllers) {
    apiPath = `/1/evse-controllers/${evseController.id}/operational-status`;
  } else if (canUserReadWriteEvseControllersMaintenance) {
    apiPath = `/1/evse-controllers/maintenance/${evseController.id}/operational-status`;
  } else {
    return null;
  }

  const updateEvseControllerOperationalStatus = async () => {
    if (
      evseController.evseOperationalStatusId === selectedEvseOperationalStatusId
    ) {
      return;
    }

    setLoadingUpdateEvseOperationalStatus(true);
    setErrorUpdateEvseOperationalStatus(null);

    try {
      await request({
        method: 'PATCH',
        path: apiPath,
        body: { evseOperationalStatusId: selectedEvseOperationalStatusId },
      });
      onUpdated && onUpdated();
      setModalOpen(false);
    } catch (error) {
      setErrorUpdateEvseOperationalStatus(error as Error);
    } finally {
      setLoadingUpdateEvseOperationalStatus(false);
    }
  };

  const onClose = () => {
    setModalOpen(false);
    setErrorUpdateEvseOperationalStatus(null);
    setLoadingUpdateEvseOperationalStatus(false);
    setUnavailableEvseOperationalStatusesOpen(false);
    setSelectedEvseOperationalStatusId(evseController.evseOperationalStatusId);
  };

  const {
    data: evseOperationalStatuses,
    error: errorFetchingEvseOperationalStatuses,
    loading: loadingFetchingEvseOperationalStatuses,
  } = useFetch<(EvseOperationalStatus & { available: boolean })[]>({
    method: 'GET',
    path: apiPath,
  });

  const availableEvseOperationalStatuses =
    evseOperationalStatuses?.filter(
      (evseOperationalStatus) => evseOperationalStatus.available
    ) || [];
  const unavailableEvseOperationalStatuses =
    evseOperationalStatuses?.filter(
      (evseOperationalStatus) => !evseOperationalStatus.available
    ) || [];

  return (
    <Modal
      closeIcon
      open={modalOpen}
      onOpen={() => setModalOpen(true)}
      onClose={onClose}
      size="tiny"
      trigger={trigger}>
      <Modal.Header>
        {t('evseOperationalStatusUpdateModal.title', 'Operational Status')}
      </Modal.Header>
      <Modal.Content style={{ minHeight: 150 }}>
        {!loadingFetchingEvseOperationalStatuses &&
          errorFetchingEvseOperationalStatuses && (
            <Message
              error
              content={errorFetchingEvseOperationalStatuses.message}
            />
          )}
        {!loadingUpdateEvseOperationalStatus &&
          errorUpdateEvseOperationalStatus && (
            <Message error content={errorUpdateEvseOperationalStatus.message} />
          )}
        <p>
          {t(
            'evseOperationalStatusUpdateModal.description',
            'Update the operational status of this charging station. Some statuses may be disabled in case there is missing configuration on station or additional rules set by roaming & billing.'
          )}
        </p>
        {loadingFetchingEvseOperationalStatuses ? (
          <div>
            <Loader active />
          </div>
        ) : (
          <div>
            <h3 style={{ paddingBottom: 10 }}>
              {t(
                'evseOperationalStatusUpdateModal.selectTitle',
                'Select Operational Status'
              )}
            </h3>
            <p style={{ color: 'grey', fontWeight: 500, fontSize: 12 }}>
              {upperCase(
                t(
                  'evseOperationalStatusUpdateModal.titleAvailable',
                  'Available'
                )
              )}
            </p>
            {availableEvseOperationalStatuses.map((evseOperationalStatus) => (
              <Option
                key={evseOperationalStatus.id}
                evseOperationalStatus={evseOperationalStatus}
                selected={
                  evseOperationalStatus.id === selectedEvseOperationalStatusId
                }
                onClick={() =>
                  setSelectedEvseOperationalStatusId(evseOperationalStatus.id)
                }
              />
            ))}
            <Accordion>
              <Accordion.Title
                onClick={() =>
                  setUnavailableEvseOperationalStatusesOpen(
                    !unavailableEvseOperationalStatusesOpen
                  )
                }>
                <p
                  style={{
                    color: 'grey',
                    fontWeight: 500,
                    fontSize: 12,
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 10,
                  }}>
                  {unavailableEvseOperationalStatusesOpen ? (
                    <Icon name="angle-down" />
                  ) : (
                    <Icon name="angle-right" />
                  )}
                  {upperCase(
                    t(
                      'evseOperationalStatusUpdateModal.titleUnavailable',
                      'Unavailable'
                    )
                  )}
                </p>
              </Accordion.Title>
              <Accordion.Content
                active={unavailableEvseOperationalStatusesOpen}>
                {unavailableEvseOperationalStatuses.map(
                  (evseOperationalStatus) => (
                    <Option
                      key={evseOperationalStatus.id}
                      evseOperationalStatus={evseOperationalStatus}
                      selected={false}
                      disabled
                    />
                  )
                )}
              </Accordion.Content>
            </Accordion>
            <div style={{ paddingTop: 20 }}>
              <strong>
                {t(
                  'evseOperationalStatusUpdateModal.note',
                  'Note that updating operational status may result in additional system updates depending on roaming, hardware & billing configurations.'
                )}
              </strong>
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link target="_blank" to={EVSE_OPERATIONAL_STATUSES_FE_PATH}>
            <ActionButton primary compact>
              {t(
                'evseOperationalStatusUpdateModal.moreAbout',
                'More about operational statuses'
              )}
            </ActionButton>
          </Link>
          <div>
            <Button
              basic
              onClick={onClose}
              content={t('evseOperationalStatusUpdateModal.cancel', 'Cancel')}
            />
            <Button
              primary
              disabled={
                selectedEvseOperationalStatusId ===
                evseController.evseOperationalStatusId
              }
              loading={loadingUpdateEvseOperationalStatus}
              onClick={() => updateEvseControllerOperationalStatus()}
              content={t(
                'evseOperationalStatusUpdateModal.updateStatus',
                'Update Status'
              )}
            />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export const Option = ({
  evseOperationalStatus,
  onClick,
  selected,
  disabled,
}: {
  evseOperationalStatus: EvseOperationalStatus;
  onClick?: () => void;
  selected: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <SelectableButton
      buttonStyle={{ marginLeft: 0, marginBottom: 15 }}
      selected={selected}
      onClick={onClick}
      disabled={disabled}>
      <div>
        <OperationalStatus evseOperationalStatus={evseOperationalStatus} />
        <p style={{ paddingTop: 10 }}>
          {evseOperationalStatusDescriptionTranslation(
            t,
            evseOperationalStatus
          )}
        </p>
      </div>
    </SelectableButton>
  );
};
