import React from 'react';

import { withTranslation } from 'react-i18next';

import { Table, Button, Dropdown } from 'semantic';

import HelpTip from 'components/HelpTip';
import EditBillingPlanModal from 'components/modals/EditBillingPlanModal';
import { currentUserCanAccess } from 'utils/roles';
import { Search } from 'components';

import Actions from '../Actions';
import { formatDateTime } from 'utils/date';
import Currency from 'components/Currency';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';
import { resolveBillingPlanTypeName } from '../utils';

class BillingPlanTable extends React.Component {
  static contextType = Search.Context;

  render() {
    const { t } = this.props;
    const { items, reload, loading } = this.context;
    const writeAccess = currentUserCanAccess('billingPlans', 'write');

    if (!items.length || loading) return null;

    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>
                {t('billingPlans.columnName', 'Name')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('billingPlans.columnType', 'Type')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('billingPlans.columnPricing', 'Pricing')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('billingPlans.columnPublic', 'Public?')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('billingPlans.columnCards', 'Cards')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('billingPlans.columnStations', 'Stations')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('billingPlans.columnAccounts', 'Accounts')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('billingPlans.columnCreated', 'Created')}

                <HelpTip
                  title={t('billingPlans.columnCreated', 'Created')}
                  text={t(
                    'billingPlans.columnCreatedTooltip',
                    'This is the date and time the token was created.'
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('billingPlans.columnActions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              const preferredPrice =
                item?.prices?.find(
                  (priceInCurrency) => priceInCurrency.currency === 'EUR'
                ) ||
                item?.prices?.[0] ||
                {};

              const details = item.details || {
                en: { name: 'Untitled' },
              };
              const billingPlanName =
                details.en.name || details.nl.name || details.de.name;

              return (
                <Table.Row key={item.id}>
                  <Table.Cell>{billingPlanName}</Table.Cell>
                  <Table.Cell>{resolveBillingPlanTypeName(item)}</Table.Cell>
                  <Table.Cell>
                    <Table celled size="small" basic="very" textAlign="right">
                      <Table.Body>
                        {preferredPrice.perPeriod !== 0 && (
                          <Table.Row>
                            <Table.Cell width={8}>
                              {t('billingPlanTable.perPeriod', 'Per Period')}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              <Currency
                                currency={preferredPrice.currency}
                                value={preferredPrice.perPeriod}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {preferredPrice.perSession !== 0 && (
                          <Table.Row>
                            <Table.Cell width={8}>
                              {t('billingPlanTable.perSession', 'Per Session')}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              <Currency
                                currency={preferredPrice.currency}
                                value={preferredPrice.perSession}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {preferredPrice.perCard !== 0 && (
                          <Table.Row>
                            <Table.Cell width={8}>
                              {t('billingPlanTable.perCard', 'Per Card')}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              <Currency
                                currency={preferredPrice.currency}
                                value={preferredPrice.perCard}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {preferredPrice.perDevice !== 0 && (
                          <Table.Row>
                            <Table.Cell width={8}>
                              {t('billingPlanTable.perDevice', 'Per Device')}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              <Currency
                                currency={preferredPrice.currency}
                                value={preferredPrice.perDevice}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {preferredPrice.perKwhFixed !== 0 && (
                          <Table.Row>
                            <Table.Cell width={8}>
                              {t(
                                'billingPlanTable.perKwhFixedFee',
                                '{{CURRENCY}} Per Kwh Fee',
                                { CURRENCY: preferredPrice.currency }
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              <Currency
                                currency={preferredPrice.currency}
                                value={preferredPrice.perKwhFixed}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {preferredPrice.perKwhPercentage !== 0 && (
                          <Table.Row>
                            <Table.Cell width={8}>
                              {t(
                                'billingPlanTable.perKwhPercentageFee',
                                '% Per Kwh Fee'
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {`${preferredPrice.perKwhPercentage}%`}
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                  <Table.Cell>{item.isPublic ? 'Yes' : 'No'}</Table.Cell>
                  <Table.Cell>
                    {item.type === 'consumer' ? (
                      <Link
                        to={`/cards?billingPlan=${item.id}`}
                        target="_blank"
                        title="Show cards">
                        {item.cardCount || 0}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {item.type === 'station' ? (
                      <Link
                        to={`/charging-stations?billingPlans=${item.id}`}
                        target="_blank"
                        title="Show charging station">
                        {item.evseCount || 0}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {item.type === 'account-tier' ? (
                      <Link
                        to={`/accounts?accountTierBillingPlan=${item.id}`}
                        target="_blank"
                        title="Show accounts">
                        {item.accountCount || 0}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <EditBillingPlanModal
                      billingPlan={item}
                      onSubmit={(body) =>
                        request({
                          method: 'PATCH',
                          path: `/1/billing-plans/${item?.id}`,
                          body,
                        })
                      }
                      onSave={reload}
                      trigger={
                        <Button
                          basic
                          icon="pen-to-square"
                          title="Edit"
                          disabled={!writeAccess}
                        />
                      }
                    />
                    <Dropdown button basic text={t('common.more', 'More')}>
                      <Dropdown.Menu direction="left">
                        <Actions
                          billingPlanName={billingPlanName}
                          writeAccess={writeAccess}
                          item={item}
                          onReload={reload}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default withTranslation()(BillingPlanTable);
