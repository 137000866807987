import { useMemo, useState } from 'react';
import { request } from '../../../../utils/api';
import { DisallowedReason, DropDownOption } from '../types';
import { useTranslation } from 'react-i18next';
import { useBulkEditEvseControllersErrorFormatter } from '../errors';
import EditEvseControllerRoamingSettings from '../../EditEvseControllerRoamingSettings';
import EditEvseControllerLabels from '../../EditEvseControllerLabels';
import React from 'react';
import EditEvseControllerAccessGroups from '../EditAccessGroups';
import { useAccountOptions } from './useAccountOptions';
import { useLocationOptions } from './useLocationOptions';
import { useBillingPlanOptions } from './useBillingPlanOptions';
import BulkTariffProfileAssignment from '../bulkTariffProfileAssignment';
import { useEvseOperationalStatusOptions } from './useEvseOperationalStatusOptions';
import { useFeatures, FeatureFlags } from 'contexts/features';

type Option = {
  name: DropDownOption;
  label: string;
  isAllowed: boolean;
  disallowedReasons?: DisallowedReason[];
};

export function useDropDownOptions(evseControllerIds: string[]) {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();

  const isOperationalStatusesPageEnabled = hasFeature(
    FeatureFlags.OperationalStatusesPage
  );

  const [randomAccountId, setRandomAccountId] = useState<string>('');

  const errorFormatter = useBulkEditEvseControllersErrorFormatter();
  const accountOptions = useAccountOptions();
  const locationOptions = useLocationOptions(randomAccountId);
  const billingOptions = useBillingPlanOptions(randomAccountId);
  const evseOperationalStatusOptions =
    useEvseOperationalStatusOptions(evseControllerIds);

  useMemo(() => {
    const getAccountId = async () => {
      const evseController = await request({
        method: 'GET',
        path: `/1/evse-controllers/${evseControllerIds[0]}`,
      });

      setRandomAccountId(evseController.data.accountId);
    };

    if (evseControllerIds?.length) {
      getAccountId();
    }
  }, [evseControllerIds]);

  const initialDropDownOptions: Option[] = [
    {
      name: 'accessGroups',
      label: t('editEvseControllersModal.accessGroup', 'Access Groups'),
      isAllowed: false,
    },
    {
      name: 'accountId',
      label: t('editEvseControllersModal.account', 'Account'),
      isAllowed: false,
    },
    {
      name: 'billingPlanId',
      label: t('editEvseControllersModal.billingPlan', 'Billing Plan'),
      isAllowed: false,
    },
    {
      name: 'chargingStationAvailability',
      label: t(
        'editEvseControllersModal.chargingStationAvailability',
        'Charging Station Availablity'
      ),
      isAllowed: false,
    },
    {
      name: 'fieldServiceAccount',
      label: t(
        'editEvseControllersModal.fieldServiceAccount',
        'Field Service Account'
      ),
      isAllowed: false,
    },
    {
      name: 'labels',
      label: t('editEvseControllersModal.label', 'Labels'),
      isAllowed: false,
    },
    {
      name: 'location',
      label: t('editEvseControllersModal.location', 'Location'),
      isAllowed: false,
    },
    {
      name: 'roaming',
      label: t(
        'editEvseControllersModal.roaming',
        'Roaming and Roaming settings'
      ),
      isAllowed: false,
    },
    {
      name: 'scanToPay',
      label: t('editEvseControllersModal.scanToPay', 'Scan-to-Pay'),
      isAllowed: false,
    },
    {
      name: 'tariffProfile',
      label: t('editEvseControllersModal.tariffProfile', 'Tariff Profile'),
      isAllowed: false,
    },
    // To add more settings, first include it in backend validator
    // then add it here, ultimately update secondDropdownOptions
    // to know what to do for its related sub-option
  ];

  if (isOperationalStatusesPageEnabled) {
    initialDropDownOptions.push({
      name: 'evseOperationalStatusId',
      label: t(
        'editEvseControllersModal.evseOperationalStatus',
        'Operational Status'
      ),
      isAllowed: false,
    });
  }

  // Defaulting the dropdown options to disabled and then updating them based on the response from the API validator
  const [dropDownOptions, setDropdownOptions] = useState<Option[]>(
    initialDropDownOptions
  );

  const secondDropdownOptions: {
    [key: string]:
      | {
          componentType: 'dropdown' | 'toggle';
          onDataNeeded: () => { value: string; label: string }[];
          componentProps?: { [key: string]: any };
        }
      | {
          componentType: 'custom';
          component: (objectPath: string) => JSX.Element;
        };
  } = {
    accountId: {
      componentType: 'dropdown',
      onDataNeeded: () => accountOptions,
    },
    scanToPay: {
      componentType: 'toggle',
      onDataNeeded: () => [
        {
          value: 'optIn',
          label: t('editEvseControllersModal.optIn', 'Opt-in to “Scan-to-Pay”'),
        },
        {
          value: 'optOut',
          label: t(
            'editEvseControllersModal.optOut',
            'Opt-out of “Scan-to-Pay”'
          ),
        },
      ],
    },
    roaming: {
      componentType: 'custom',
      component: (objectPath: string) => (
        <EditEvseControllerRoamingSettings objectPath={objectPath} />
      ),
    },
    labels: {
      componentType: 'custom',
      component: (objectPath: string) => (
        <EditEvseControllerLabels objectPath={objectPath} />
      ),
    },
    fieldServiceAccount: {
      componentType: 'dropdown',
      onDataNeeded: () => [
        {
          value: 'none',
          label: t('editEvseControllersModal.none', 'None'),
        },
        ...accountOptions,
      ],
    },
    chargingStationAvailability: {
      componentType: 'toggle',
      onDataNeeded: () => [
        {
          value: 'true',
          label: t(
            'editEvseControllersModal.enableChargingStations',
            'Enable charging points (charging allowed, billing active)'
          ),
        },
        {
          value: 'false',
          label: t(
            'editEvseControllersModal.disableChargingStations',
            'Disable charging points (no charging, no billing)'
          ),
        },
      ],
    },
    location: {
      componentType: 'dropdown',
      onDataNeeded: () => locationOptions,
    },
    accessGroups: {
      componentType: 'custom',
      component: (objectPath: string) => (
        <EditEvseControllerAccessGroups
          objectPath={objectPath}
          accountId={randomAccountId}
        />
      ),
    },
    billingPlanId: {
      componentType: 'dropdown',
      onDataNeeded: () => billingOptions,
    },
    tariffProfile: {
      componentType: 'custom',
      component: (objectPath: string) => (
        <BulkTariffProfileAssignment objectPath={objectPath} />
      ),
    },
    evseOperationalStatusId: {
      componentType: 'dropdown',
      onDataNeeded: () => evseOperationalStatusOptions,
    },
  };

  useMemo(() => {
    const getApiValidatedOptions = async () => {
      const response = await request({
        method: 'POST',
        path: '/1/evse-controllers/bulk/edit/options',
        body: {
          ids: evseControllerIds,
        },
      });

      setDropdownOptions(
        mergeApiValidatorResponseAndDropDownOptions(
          dropDownOptions,
          response.data,
          errorFormatter
        )
      );
    };

    if (evseControllerIds?.length) {
      getApiValidatedOptions();
    }
  }, [evseControllerIds]);

  return {
    dropDownOptions,
    secondDropdownOptions,
  };
}

const mergeApiValidatorResponseAndDropDownOptions = (
  defaultDropDownOptions: {
    name: DropDownOption;
    label: string;
    isAllowed: boolean;
  }[],
  validatedOptions: {
    name: string;
    isAllowed: boolean;
    failedConditions: { code: string; description: string }[];
  }[],
  errorFormatter: ReturnType<typeof useBulkEditEvseControllersErrorFormatter>
): {
  name: DropDownOption;
  label: string;
  isAllowed: boolean;
  failedReasons?: { title: string; message: string }[];
}[] => {
  return defaultDropDownOptions.map((defaultDropDownOption) => {
    const validatedOption = validatedOptions.find(
      (validatedOption) => validatedOption.name === defaultDropDownOption.name
    );

    return {
      ...defaultDropDownOption,
      isAllowed: validatedOption?.isAllowed || false,
      ...(validatedOption?.failedConditions?.length && {
        disallowedReasons: validatedOption.failedConditions.map(errorFormatter),
      }),
    };
  });
};
