import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'semantic';
import { Link } from 'react-router-dom';
import { ListHeader, Breadcrumbs, Search } from 'components';
import { request } from 'utils/api';
import Table from './Table';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_CONFIG_PRESET_BE_PATH,
  EVSE_CONFIG_PRESETS_FE_PATH_CREATE,
  hasEvseConfigPresetPermissions,
} from 'screens/EvseConfigPreset/utils';
import { pick } from 'lodash-es';

export default function EvseConfigPresetsList() {
  const { t } = useTranslation();
  const searchRef = useRef();

  const onDataNeeded = useCallback(async (filters: any) => {
    return await request({
      method: 'GET',
      path: EVSE_CONFIG_PRESET_BE_PATH,
      params: pick(filters, ['limit', 'skip']),
    });
  }, []);

  const writeAccess = hasEvseConfigPresetPermissions('write');

  return (
    <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded} limit={100}>
      {() => {
        return (
          <>
            <Breadcrumbs
              path={[
                <Link to={CHARGING_STATIONS_FE_PATH}>
                  {t(
                    'evseConfigPresets.breadcrumbsChargingStations',
                    'Charging Stations'
                  )}
                </Link>,
              ]}
              active={t(
                'evseConfigPresets.breadcrumbsList',
                'Configuration Presets'
              )}
            />
            <div style={{ marginTop: 0, marginBottom: 10 }}>
              <ListHeader
                title={t('evseConfigPresets.title', 'Configuration Presets')}>
                {writeAccess && (
                  <Link to={EVSE_CONFIG_PRESETS_FE_PATH_CREATE}>
                    <Button
                      primary
                      style={{ marginTop: '1px' }}
                      content={t(
                        'evseConfigPresets.newPresetBtn',
                        'New Preset'
                      )}
                      icon="plus"
                    />
                  </Link>
                )}
              </ListHeader>
            </div>
            <p>
              {t(
                'evseConfigPresets.description',
                'Save set of configuration parameters as a preset that can be used across your charging stations.'
              )}
            </p>
            <div>
              <Search.Status noResults={<NoResults />} useSegment={false} />
              <Table />
            </div>
            <Divider hidden />
            <div
              style={{
                textAlign: 'center',
              }}>
              <Search.Pagination />
            </div>
          </>
        );
      }}
    </Search.Provider>
  );
}

const NoResults = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t(
        'evseConfigPresets.noResults',
        'There are no Configuration Presets to display.'
      )}
      <Link
        to={EVSE_CONFIG_PRESETS_FE_PATH_CREATE}
        className="borderless-blue-button">
        {t('evseConfigPresets.noResultsCreateNew', 'Create New Preset')}
      </Link>
    </div>
  );
};
