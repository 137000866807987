import {
  EvseConfigPreset,
  EvseConfigPresetItem,
} from 'types/evse-config-preset';
import { EvseController, OCPPProtocolVersion } from 'types/evse-controller';

type KeyValue = {
  required: boolean;
  value: string | number;
};

type KeysMap = Partial<{
  [key in AutoConfigKeys16 | AutoConfigKeys201]: KeyValue;
}>;

enum AutoConfigKeys16 {
  WebSocketPingInterval = 'WebSocketPingInterval',
  ConnectionTimeOut = 'ConnectionTimeOut',
  LocalAuthorizeOffline = 'LocalAuthorizeOffline',
  LocalAuthListEnabled = 'LocalAuthListEnabled',
  LocalPreAuthorize = 'LocalPreAuthorize',
  AuthorizationCacheEnabled = 'AuthorizationCacheEnabled',
  AllowOfflineTxForUnknownId = 'AllowOfflineTxForUnknownId',
  AuthorizeRemoteTxRequests = 'AuthorizeRemoteTxRequests',
  CpoName = 'CpoName',
  ChameleonMinCurrent = 'Chameleon-MinCurrent',
  FreevendEnabled = 'FreevendEnabled',
  MeterValueSampleInterval = 'MeterValueSampleInterval',
  CentralMeterValueSampleInterval = 'CentralMeterValueSampleInterval',
  CentralMeterValueTransmissionMode = 'CentralMeterValueTransmissionMode',
  TransactionMessageAttempts = 'TransactionMessageAttempts',
  TransactionMessageRetryInterval = 'TransactionMessageRetryInterval',
  StopTransactionOnEVSideDisconnect = 'StopTransactionOnEVSideDisconnect',
  StopTransactionOnInvalidId = 'StopTransactionOnInvalidId',
  MaxEnergyOnInvalidId = 'MaxEnergyOnInvalidId',
  HeartbeatInterval = 'HeartbeatInterval',
}

enum AutoConfigKeys201 {
  WebSocketPingInterval = 'OCPPCommCtrlr.WebSocketPingInterval',
  EVConnectionTimeOut = 'TxCtrlr.EVConnectionTimeOut',
  LocalAuthorizeOffline = 'AuthCtrlr.LocalAuthorizeOffline',
  LocalAuthListCtrlrEnabled = 'LocalAuthListCtrlr.Enabled',
  LocalPreAuthorize = 'AuthCtrlr.LocalPreAuthorize',
  AuthCacheCtrlrEnabled = 'AuthCacheCtrlr.Enabled',
  OfflineTxForUnknownIdEnabled = 'AuthCtrlr.OfflineTxForUnknownIdEnabled',
  AuthorizeRemoteStart = 'AuthCtrlr.AuthorizeRemoteStart',
  TxUpdatedInterval = 'SampledDataCtrlr.TxUpdatedInterval',
  MessageAttemptsTransactionEvent = 'OCPPCommCtrlr.MessageAttemptsTransactionEvent',
  MessageAttemptIntervalTransactionEvent = 'OCPPCommCtrlr.MessageAttemptIntervalTransactionEvent',
  StopTxOnEVSideDisconnect = 'TxCtrlr.StopTxOnEVSideDisconnect',
  StopTxOnInvalidId = 'TxCtrlr.StopTxOnInvalidId',
  MaxEnergyOnInvalidId = 'TxCtrlr.MaxEnergyOnInvalidId',
  TxStartPoint = 'TxCtrlr.TxStartPoint',
  HeartbeatInterval = 'OCPPCommCtrlr.HeartbeatInterval',
}

export type Task = {
  name: string;
  type?: 'keys';
  check?: {
    description: string;
    execute: (args: {
      evseController: EvseController;
      configuration: any;
      configPreset?: EvseConfigPreset;
    }) => Promise<boolean>;
  };
  getKeys?: (
    ocppProtocolVersion: OCPPProtocolVersion,
    configPreset?: EvseConfigPreset
  ) => KeysMap;
  fix?: {
    description: string;
    execute: (args: {
      evseController: EvseController;
      configuration: any;
      changeConfiguration: (config: any) => Promise<void>;
      updateSettings: (settings: any) => Promise<void>;
      configPreset?: EvseConfigPreset;
    }) => Promise<void>;
  };
};

const tasks: Task[] = [
  {
    name: 'Number of Connectors Configuration',
    check: {
      description:
        'Checking if NumberOfConnectors configuration matches our EVSE Controller settings',
      execute: async ({ evseController, configuration }) => {
        let { NumberOfConnectors = '1' } = configuration;
        NumberOfConnectors = NumberOfConnectors.toString().replace(
          /[\[\]\(\)]+/g,
          ''
        );
        if (parseInt(NumberOfConnectors, 10) !== evseController.numConnectors) {
          throw new Error(
            `Expected EVSE Controller settings to have numConnectors = ${NumberOfConnectors} (was ${evseController.numConnectors})`
          );
        }
        return true;
      },
    },
    fix: {
      description:
        'Updating EVSE controller settings to reflect NumberOfConnectors configuration',
      execute: async ({ configuration, updateSettings }) => {
        let { NumberOfConnectors = '1' } = configuration;
        NumberOfConnectors = NumberOfConnectors.toString().replace(
          /[\[\]\(\)]+/g,
          ''
        );
        await updateSettings({
          numConnectors: NumberOfConnectors,
        });
      },
    },
  },
  {
    name: 'Websocket & Connection Configuration',
    type: 'keys',
    getKeys: (
      ocppProtocolVersion: OCPPProtocolVersion,
      configPreset?: EvseConfigPreset
    ) => {
      let keys: KeysMap;

      switch (ocppProtocolVersion) {
        case OCPPProtocolVersion.OCPP201:
          keys = {
            [AutoConfigKeys201.WebSocketPingInterval]: {
              required: false,
              value: 60,
            },
            [AutoConfigKeys201.EVConnectionTimeOut]: {
              required: false,
              value: 120,
            },
          };
          break;
        default:
          keys = {
            [AutoConfigKeys16.WebSocketPingInterval]: {
              required: false,
              value: 60,
            },
            [AutoConfigKeys16.ConnectionTimeOut]: {
              required: false,
              value: 120,
            },
          };
      }

      return applyOverrides(ocppProtocolVersion, keys, configPreset);
    },
  },
  {
    name: 'Heartbeat Configuration',
    check: {
      description: 'Checking if HeartbeatInterval needs to be adjusted',
      execute: async ({
        evseController,
        configuration,
        configPreset,
      }: {
        evseController: EvseController;
        configuration: any;
        configPreset?: EvseConfigPreset;
      }) => {
        const {
          noPingHeartbeatInterval,
          withPingHeartbeatInterval,
          webSocketPingInterval,
          heartbeatInterval,
        } = getHeartbeatIntervalValues({
          evseController,
          configuration,
          configPreset,
        });

        if (
          webSocketPingInterval &&
          parseInt(heartbeatInterval, 10) !== withPingHeartbeatInterval
        ) {
          throw new Error(
            `Expected HeartbeatInterval to be ${withPingHeartbeatInterval} (WebSocketPingInterval supported)`
          );
        }
        if (
          !webSocketPingInterval &&
          parseInt(heartbeatInterval, 10) !== noPingHeartbeatInterval
        ) {
          throw new Error(
            `Expected HeartbeatInterval to be ${noPingHeartbeatInterval} (WebSocketPingInterval not supported)`
          );
        }
        return true;
      },
    },
    fix: {
      description: 'Updating HeartbeatInterval',
      execute: async ({
        evseController,
        configuration,
        changeConfiguration,
        configPreset,
      }: {
        evseController: EvseController;
        configuration: any;
        configPreset?: EvseConfigPreset;
        changeConfiguration: (config: any) => Promise<void>;
      }) => {
        const {
          noPingHeartbeatInterval,
          withPingHeartbeatInterval,
          webSocketPingInterval,
          heartbeatInterval,
          heartbeatKey,
        } = getHeartbeatIntervalValues({
          evseController,
          configuration,
          configPreset,
        });

        if (
          webSocketPingInterval &&
          parseInt(heartbeatInterval, 10) !== Number(withPingHeartbeatInterval)
        ) {
          await changeConfiguration({
            [heartbeatKey]: withPingHeartbeatInterval.toString(),
          });
        }
        if (
          !webSocketPingInterval &&
          parseInt(heartbeatInterval, 10) !== noPingHeartbeatInterval
        ) {
          await changeConfiguration({
            [heartbeatKey]: noPingHeartbeatInterval.toString(),
          });
        }
      },
    },
  },
  {
    name: 'Transaction Configuration',
    type: 'keys',
    getKeys: (
      ocppProtocolVersion: OCPPProtocolVersion,
      configPreset?: EvseConfigPreset
    ) => {
      let keys: KeysMap;

      switch (ocppProtocolVersion) {
        case OCPPProtocolVersion.OCPP201:
          keys = {
            [AutoConfigKeys201.MessageAttemptsTransactionEvent]: {
              required: false,
              value: 3,
            },
            [AutoConfigKeys201.MessageAttemptIntervalTransactionEvent]: {
              required: false,
              value: 60,
            },
            [AutoConfigKeys201.StopTxOnEVSideDisconnect]: {
              required: false,
              value: 'True',
            },
            [AutoConfigKeys201.StopTxOnInvalidId]: {
              required: false,
              value: 'True',
            },
            [AutoConfigKeys201.MaxEnergyOnInvalidId]: {
              required: false,
              value: 0,
            },
            [AutoConfigKeys201.TxStartPoint]: {
              required: true,
              value: 'PowerPathClosed',
            },
          };
          break;
        default:
          keys = {
            [AutoConfigKeys16.TransactionMessageAttempts]: {
              required: false,
              value: 3,
            },
            [AutoConfigKeys16.TransactionMessageRetryInterval]: {
              required: false,
              value: 60,
            },
            [AutoConfigKeys16.StopTransactionOnEVSideDisconnect]: {
              required: false,
              value: 'True',
            },
            [AutoConfigKeys16.StopTransactionOnInvalidId]: {
              required: false,
              value: 'True',
            },
            [AutoConfigKeys16.MaxEnergyOnInvalidId]: {
              required: false,
              value: 0,
            },
          };
      }

      return applyOverrides(ocppProtocolVersion, keys, configPreset);
    },
  },
  {
    name: 'Meter Configuration',
    type: 'keys',
    getKeys: (
      ocppProtocolVersion: OCPPProtocolVersion,
      configPreset?: EvseConfigPreset
    ) => {
      let keys: KeysMap;

      switch (ocppProtocolVersion) {
        case OCPPProtocolVersion.OCPP201:
          keys = {
            [AutoConfigKeys201.TxUpdatedInterval]: {
              required: true,
              value: 500,
            },
          };
          break;
        default:
          keys = {
            [AutoConfigKeys16.MeterValueSampleInterval]: {
              required: true,
              value: 500,
            },
            [AutoConfigKeys16.CentralMeterValueSampleInterval]: {
              required: false,
              value: 500,
            },
            [AutoConfigKeys16.CentralMeterValueTransmissionMode]: {
              required: false,
              value: 'during',
            },
          };
      }

      return applyOverrides(ocppProtocolVersion, keys, configPreset);
    },
  },
  {
    name: 'Vendor Specific Configuration',
    type: 'keys',
    getKeys: (
      ocppProtocolVersion: OCPPProtocolVersion,
      configPreset?: EvseConfigPreset
    ) => {
      let keys: KeysMap;

      switch (ocppProtocolVersion) {
        case OCPPProtocolVersion.OCPP201:
          keys = {};
          break;
        default:
          keys = {
            [AutoConfigKeys16.CpoName]: {
              required: false,
              value: 'E-Flux',
            },
            [AutoConfigKeys16.ChameleonMinCurrent]: {
              required: false,
              value: 14,
            },
            [AutoConfigKeys16.FreevendEnabled]: {
              required: false,
              value: 'False',
            },
          };
      }

      return applyOverrides(ocppProtocolVersion, keys, configPreset);
    },
  },
  {
    name: 'Local Auth Configuration',
    type: 'keys',
    getKeys: (
      ocppProtocolVersion: OCPPProtocolVersion,
      configPreset?: EvseConfigPreset
    ) => {
      let keys: KeysMap;

      switch (ocppProtocolVersion) {
        case OCPPProtocolVersion.OCPP201:
          keys = {
            [AutoConfigKeys201.LocalAuthorizeOffline]: {
              required: true,
              value: 'True',
            },
            [AutoConfigKeys201.LocalAuthListCtrlrEnabled]: {
              required: false,
              value: 'True',
            },
            [AutoConfigKeys201.LocalPreAuthorize]: {
              required: true,
              value: 'False',
            },
            [AutoConfigKeys201.AuthCacheCtrlrEnabled]: {
              required: false,
              value: 'False',
            },
            [AutoConfigKeys201.OfflineTxForUnknownIdEnabled]: {
              required: false,
              value: 'True',
            },
            [AutoConfigKeys201.AuthorizeRemoteStart]: {
              required: false,
              value: 'True',
            },
          };
          break;
        default:
          keys = {
            [AutoConfigKeys16.LocalAuthorizeOffline]: {
              required: true,
              value: 'True',
            },
            [AutoConfigKeys16.LocalAuthListEnabled]: {
              required: false,
              value: 'True',
            },
            [AutoConfigKeys16.LocalPreAuthorize]: {
              required: true,
              value: 'False',
            },
            [AutoConfigKeys16.AuthorizationCacheEnabled]: {
              required: false,
              value: 'False',
            },
            [AutoConfigKeys16.AllowOfflineTxForUnknownId]: {
              required: false,
              value: 'True',
            },
            [AutoConfigKeys16.AuthorizeRemoteTxRequests]: {
              required: false,
              value: 'True',
            },
          };
      }

      return applyOverrides(ocppProtocolVersion, keys, configPreset);
    },
  },
];

export const getTasks = (
  ocppProtocol: OCPPProtocolVersion,
  configPreset: EvseConfigPreset | null
): Task[] => {
  if (!configPreset) {
    return tasks;
  }

  const extraKeys =
    configPreset.configurationItems?.filter((item: EvseConfigPresetItem) => {
      const key = getEvseConfigPresetItemKey(item);

      return (
        item.ocppProtocolVersion === ocppProtocol &&
        !Object.values(AutoConfigKeys16).includes(key as AutoConfigKeys16) &&
        !Object.values(AutoConfigKeys201).includes(key as AutoConfigKeys201)
      );
    }) || [];

  if (!extraKeys.length) {
    return tasks;
  }

  return [
    ...tasks,
    {
      name: 'Provider Specific Configuration',
      type: 'keys',
      getKeys: () => {
        const keys: { [key: string]: any } = {};

        extraKeys.forEach((item: EvseConfigPresetItem) => {
          keys[getEvseConfigPresetItemKey(item)] = {
            required: false,
            value: item.value,
          };
        });

        return keys;
      },
    },
  ];
};

const applyOverrides = (
  ocppProtocol: OCPPProtocolVersion,
  keys: KeysMap,
  configPreset?: EvseConfigPreset
): KeysMap => {
  return Object.fromEntries(
    Object.entries(keys).map(([key, value]) => {
      // Find an override for the key
      const override = configPreset?.configurationItems?.find(
        (item: EvseConfigPresetItem) =>
          item.ocppProtocolVersion === ocppProtocol &&
          getEvseConfigPresetItemKey(item) === key
      );

      // Update the value if an override exists
      if (override) {
        return [
          key,
          {
            ...value, // Spread the original value
            value: override.value, // only value is overwritten
          },
        ];
      }

      // Return the original entry if no override is found
      return [key, value];
    })
  );
};

const getOverrideValueForKey = (
  key: string,
  configPreset: EvseConfigPreset,
  ocppProtocol?: OCPPProtocolVersion
): string | undefined =>
  configPreset.configurationItems?.find(
    (item: EvseConfigPresetItem) =>
      item.ocppProtocolVersion === ocppProtocol &&
      getEvseConfigPresetItemKey(item) === key
  )?.value;

const getHeartbeatIntervalValues = ({
  evseController,
  configuration,
  configPreset,
}: {
  evseController: EvseController;
  configuration: any;
  configPreset?: EvseConfigPreset;
}): {
  noPingHeartbeatInterval: number;
  withPingHeartbeatInterval: string | number;
  webSocketPingInterval: number;
  heartbeatInterval: string;
  heartbeatKey: string;
} => {
  const noPingHeartbeatInterval = 60; // this is constant as dictated by CloudFlare
  let webSocketPingInterval, heartbeatInterval;

  let heartbeatKey;
  switch (evseController.ocppProtocolVersion) {
    case OCPPProtocolVersion.OCPP201:
      webSocketPingInterval =
        configuration[AutoConfigKeys201.WebSocketPingInterval];
      heartbeatInterval = configuration[AutoConfigKeys201.HeartbeatInterval];
      heartbeatKey = AutoConfigKeys201.HeartbeatInterval;
      break;
    default:
      webSocketPingInterval =
        configuration[AutoConfigKeys16.WebSocketPingInterval];
      heartbeatInterval = configuration[AutoConfigKeys16.HeartbeatInterval];
      heartbeatKey = AutoConfigKeys16.HeartbeatInterval;
  }

  const withPingHeartbeatInterval =
    (configPreset &&
      getOverrideValueForKey(
        heartbeatKey,
        configPreset,
        evseController.ocppProtocolVersion
      )) ||
    900;

  return {
    noPingHeartbeatInterval,
    withPingHeartbeatInterval,
    webSocketPingInterval,
    heartbeatInterval,
    heartbeatKey,
  };
};

const getEvseConfigPresetItemKey = (item: EvseConfigPresetItem): string =>
  `${item.component ? `${item.component.name}.` : ''}${item.name}`;
