import { Card, CardContent, Segment } from 'semantic-ui-react';
import { Group, Space } from 'components/Layout/helpers';
import { Header, Icon } from 'semantic';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/formatting';

export interface CardBillingPlanBase {
  name: string;
  numCardsUsingBillingPlan: number;
}

export interface CardBillingPlanPaid extends CardBillingPlanBase {
  freeOfCharge: false;
  currency: string;
  billingPeriod: string;
  prices: {
    perPeriod: number;
    perCard: number;
    perSession: number;
    perKwhPercentage: number;
    perKwhFixed: number;
  };
}

export interface CardBillingPlanFree extends CardBillingPlanBase {
  freeOfCharge: true;
}

export type CardBillingPlan = CardBillingPlanPaid | CardBillingPlanFree;

type Props = {
  cardPlan: CardBillingPlan;
};

export default function CardPlanCard({ cardPlan }: Props) {
  const { t } = useTranslation();

  return (
    <Card style={{ minWidth: '480px' }}>
      <CardContent>
        <Group grow position="apart">
          <Group>
            <Icon name="credit-card" size="big" />
            <Header
              as="h3"
              style={{
                marginTop: 0,
              }}>
              {cardPlan.name}
            </Header>
          </Group>

          <Header
            as="h3"
            style={{
              float: 'right',
              marginTop: 0,
              fontWeight: '400',
            }}>
            {t(
              'settings.organization.billing.cardPlans.numberOfCards',
              '{{numCards}} card(s)',
              { numCards: cardPlan.numCardsUsingBillingPlan }
            )}
          </Header>
        </Group>

        <Space size={10} />

        {cardPlan.freeOfCharge && (
          <p>
            {t(
              'settings.organization.billing.cardPlans.freeOfCharge',
              'This plan is free of charge'
            )}
          </p>
        )}

        {!cardPlan.freeOfCharge && (
          <Segment style={{ padding: 0, margin: 0 }} basic compact>
            {getPriceLines(cardPlan, t)}
          </Segment>
        )}
      </CardContent>
    </Card>
  );
}

function getPriceLines(
  cardPlan: CardBillingPlanPaid,
  t: TFunction<'translation'>
) {
  const lines: JSX.Element[] = [];

  if (cardPlan.prices.perPeriod) {
    const price = formatCurrency(cardPlan.prices.perPeriod, {
      currency: cardPlan.currency,
    });

    switch (cardPlan.billingPeriod) {
      case 'week':
        lines.push(
          <p key="pricePerWeek">
            {t(
              'settings.organization.billing.cardPlans.pricePerWeek.pre',
              'Subscription Fee:'
            )}{' '}
            <strong>
              {t(
                'settings.organization.billing.cardPlans.pricePerWeek.text',
                '{{price}} per week excl. VAT',
                {
                  price,
                }
              )}
            </strong>
          </p>
        );
      case 'year':
        lines.push(
          <p key="pricePerYear">
            {t(
              'settings.organization.billing.cardPlans.pricePerYear.pre',
              'Subscription Fee:'
            )}{' '}
            <strong>
              {t(
                'settings.organization.billing.cardPlans.pricePerYear.text',
                '{{price}} per year excl. VAT',
                {
                  price,
                }
              )}
            </strong>
          </p>
        );
      default:
        lines.push(
          <p key="pricePerMonth">
            {t(
              'settings.organization.billing.cardPlans.pricePerMonth.pre',
              'Subscription Fee:'
            )}{' '}
            <strong>
              {t(
                'settings.organization.billing.cardPlans.pricePerMonth.text',
                '{{price}} per month excl. VAT',
                {
                  price,
                }
              )}
            </strong>
          </p>
        );
    }
  }

  if (cardPlan.prices.perCard) {
    lines.push(
      <p key="pricePerCard">
        {t(
          'settings.organization.billing.cardPlans.pricePerCard.pre',
          'One-Time Fee:'
        )}{' '}
        <strong>
          {t(
            'settings.organization.billing.cardPlans.pricePerCard.text',
            '{{price}} per charge card excl. VAT',
            {
              price: formatCurrency(cardPlan.prices.perCard, {
                currency: cardPlan.currency,
              }),
            }
          )}
        </strong>
      </p>
    );
  }

  if (cardPlan.prices.perSession) {
    lines.push(
      <p key="pricePerSession">
        {t(
          'settings.organization.billing.cardPlans.pricePerSession.pre',
          'Session Fee:'
        )}{' '}
        <strong>
          {t(
            'settings.organization.billing.cardPlans.pricePerSession.text',
            '{{price}} / session excl. VAT',
            {
              price: formatCurrency(cardPlan.prices.perSession, {
                currency: cardPlan.currency,
              }),
            }
          )}
        </strong>
      </p>
    );
  }

  if (cardPlan.prices.perKwhFixed) {
    lines.push(
      <p key="pricePerKwhFixed">
        {t(
          'settings.organization.billing.cardPlans.pricePerKwhFixed.pre',
          'KWh Fee:'
        )}{' '}
        <strong>
          {t(
            'settings.organization.billing.cardPlans.pricePerKwhFixed.text',
            '{{price}} per kWh fixed excl. VAT',
            {
              price: formatCurrency(cardPlan.prices.perKwhFixed, {
                currency: cardPlan.currency,
              }),
            }
          )}
        </strong>
      </p>
    );
  }

  if (cardPlan.prices.perKwhPercentage) {
    lines.push(
      <p key="perKwhPercentage">
        {t(
          'settings.organization.billing.cardPlans.perKwhPercentage.pre',
          'KWh Fee:'
        )}{' '}
        <strong>
          {t(
            'settings.organization.billing.cardPlans.perKwhPercentage.text',
            '{{percentage}}% per kWh',
            {
              percentage: cardPlan.prices.perKwhPercentage,
            }
          )}
        </strong>
      </p>
    );
  }

  return lines;
}
