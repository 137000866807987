import { TFunction } from 'react-i18next';
import {
  CPO_SESSION_EXCLUDED_REASON,
  MSP_SESSION_EXCLUDED_REASON,
} from 'types/sessions';

export const sessionStatusOptions = [
  CPO_SESSION_EXCLUDED_REASON.HISTORICAL_END,
  MSP_SESSION_EXCLUDED_REASON.TOO_OLD,
  CPO_SESSION_EXCLUDED_REASON.MAINTENANCE_TOKEN,
  MSP_SESSION_EXCLUDED_REASON.NON_EXISTENT_TOKEN,
  MSP_SESSION_EXCLUDED_REASON.NON_ACTIVE_TOKEN,
  CPO_SESSION_EXCLUDED_REASON.HIGH_ENERGY_USAGE,
  CPO_SESSION_EXCLUDED_REASON.LOW_ENERGY_USAGE,
  CPO_SESSION_EXCLUDED_REASON.LOW_DURATION,
  CPO_SESSION_EXCLUDED_REASON.IMPROBABLE_ENERGY_USAGE,
  CPO_SESSION_EXCLUDED_REASON.NO_COST,
  MSP_SESSION_EXCLUDED_REASON.HIGH_COST,
  CPO_SESSION_EXCLUDED_REASON.EXTERNAL_PAYMENT_SOLUTION,
  CPO_SESSION_EXCLUDED_REASON.NON_ACCEPTED_AUTH,
  CPO_SESSION_EXCLUDED_REASON.MANUAL_EXCLUSION,
  CPO_SESSION_EXCLUDED_REASON.MANUAL_CANCELLATION,
  CPO_SESSION_EXCLUDED_REASON.INVALID_START_TIME,
  CPO_SESSION_EXCLUDED_REASON.INVALID_END_TIME,
  CPO_SESSION_EXCLUDED_REASON.UNKNOWN_METER_VALUES,
  CPO_SESSION_EXCLUDED_REASON.TEMPORARILY_EXCLUDED_WAITING_FOR_MISSING_DATA,
  MSP_SESSION_EXCLUDED_REASON.PSP_PAYMENT,
  MSP_SESSION_EXCLUDED_REASON.NO_SESSION,
  CPO_SESSION_EXCLUDED_REASON.NO_AUTO_REIMBURSEMENT,
  MSP_SESSION_EXCLUDED_REASON.DUPLICATE,
];

export const sessionExcludedReasonsOptionsLabel = (
  t: TFunction,
  key: string
): string => {
  switch (key) {
    case CPO_SESSION_EXCLUDED_REASON.HISTORICAL_END:
      return t(
        'session.excludedReason.historicalEnd',
        'Session was back-dated'
      );
    case MSP_SESSION_EXCLUDED_REASON.TOO_OLD:
      return t('session.excludedReason.tooOld', 'Session was too old');
    case CPO_SESSION_EXCLUDED_REASON.MAINTENANCE_TOKEN:
      return t(
        'session.excludedReason.maintenanceToken',
        'Session started with maintenance token'
      );
    case MSP_SESSION_EXCLUDED_REASON.NON_EXISTENT_TOKEN:
      return t(
        'session.excludedReason.nonExistentToken',
        'Session started with a non-existent token'
      );
    case MSP_SESSION_EXCLUDED_REASON.NON_ACTIVE_TOKEN:
      return t(
        'session.excludedReason.nonActiveToken',
        'Session started with a non-active token'
      );
    case CPO_SESSION_EXCLUDED_REASON.HIGH_ENERGY_USAGE:
      return t(
        'session.excludedReason.highEnergyUsage',
        'Session had a higher energy usage than normal'
      );
    case CPO_SESSION_EXCLUDED_REASON.LOW_ENERGY_USAGE:
      return t(
        'session.excludedReason.lowEnergyUsage',
        'Session had a lower energy usage than normal'
      );
    case CPO_SESSION_EXCLUDED_REASON.LOW_DURATION:
      return t(
        'session.excludedReason.lowDuration',
        'Session had a lower duration session than normal'
      );
    case CPO_SESSION_EXCLUDED_REASON.IMPROBABLE_ENERGY_USAGE:
      return t(
        'session.excludedReason.improbableEnergyUsage',
        'Session had a improbable energy usage'
      );
    case CPO_SESSION_EXCLUDED_REASON.NO_COST:
      return t('session.excludedReason.noCost', 'Session had no cost');
    case MSP_SESSION_EXCLUDED_REASON.HIGH_COST:
      return t(
        'session.excludedReason.highCost',
        'Session had higher cost than normal'
      );
    case CPO_SESSION_EXCLUDED_REASON.EXTERNAL_PAYMENT_SOLUTION:
      return t(
        'session.excludedReason.externalPaymentSolution',
        'Session used an external payment solution'
      );
    case CPO_SESSION_EXCLUDED_REASON.NON_ACCEPTED_AUTH:
      return t(
        'session.excludedReason.nonAcceptedAuth',
        'Session was started with non authorized token'
      );
    case CPO_SESSION_EXCLUDED_REASON.MANUAL_EXCLUSION:
      return t(
        'session.excludedReason.manualExclusion',
        'Session was manually excluded'
      );
    case CPO_SESSION_EXCLUDED_REASON.MANUAL_CANCELLATION:
      return t(
        'session.excludedReason.manualCancellation',
        'Session was manually cancelled'
      );
    case CPO_SESSION_EXCLUDED_REASON.INVALID_START_TIME:
      return t(
        'session.excludedReason.invalidStartTime',
        'Session had an invalid start time'
      );
    case CPO_SESSION_EXCLUDED_REASON.INVALID_END_TIME:
      return t(
        'session.excludedReason.invalidEndTime',
        'Session had an invalid end time'
      );
    case CPO_SESSION_EXCLUDED_REASON.UNKNOWN_METER_VALUES:
      return t(
        'session.excludedReason.unknownMeterValues',
        'Session meter values are unknown'
      );
    case CPO_SESSION_EXCLUDED_REASON.TEMPORARILY_EXCLUDED_WAITING_FOR_MISSING_DATA:
      return t(
        'session.excludedReason.temporarilyExcludedWaitingForMissingData',
        'Session is temporarily excluded waiting for missing data'
      );
    case MSP_SESSION_EXCLUDED_REASON.PSP_PAYMENT:
      return t(
        'session.excludedReason.pspPayment',
        'Session was paid with a Payment Service Provider'
      );
    case MSP_SESSION_EXCLUDED_REASON.NO_SESSION:
      return t(
        'session.excludedReason.noSession',
        'Session had no cost and no energy usage'
      );
    case CPO_SESSION_EXCLUDED_REASON.NO_AUTO_REIMBURSEMENT:
      return t(
        'session.excludedReason.noAutoReimbursement',
        'Session had no auto reimbursed'
      );
    case MSP_SESSION_EXCLUDED_REASON.DUPLICATE:
      return t('session.excludedReason.duplicate', 'Session is a duplicate');
    default:
      return '';
  }
};
