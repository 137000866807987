import { size } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { request } from 'utils/api';
import { EvseOperationalStatus } from 'types/evse-operational-status';
import { evseOperationalStatusNameTranslation } from 'utils/evse-operational-status';

type APIResponseData = (EvseOperationalStatus & {
  available: boolean;
})[];

export function useEvseOperationalStatusOptions(evseControllerIds: string[]) {
  const { t } = useTranslation();
  const [evseOperationalStatusOptions, setEvseOperationalStatusOptions] =
    useState<{ value: string; label: string }[]>([]);

  useMemo(() => {
    const requestEvseOperationalStatusData = async () => {
      if (size(evseControllerIds) < 1) return;

      const response = await request<{ data: APIResponseData }>({
        path: `/1/evse-controllers/available-operational-statuses`,
        method: 'GET',
        params: {
          evseControllerIds,
        },
      });

      setEvseOperationalStatusOptions(
        response.data
          .filter((evseOperationalStatus) => evseOperationalStatus.available)
          .map((evseOperationalStatus: any) => ({
            value: evseOperationalStatus.id,
            label: evseOperationalStatusNameTranslation(
              t,
              evseOperationalStatus
            ),
          }))
      );
    };

    requestEvseOperationalStatusData();
  }, [evseControllerIds]);

  return evseOperationalStatusOptions;
}
