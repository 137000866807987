import React from 'react';
import { Segment, Message, Grid } from 'semantic';
import { request } from 'utils/api';
import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';
import ApiMessage from 'components/ApiMessage';
import Form from './Form';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

function determineSignupRouteForProvider(provider) {
  if (provider.slug === 'road') {
    return '/api/signup';
  }

  return '/join';
}

export default function ForgotPassword() {
  const { t } = useTranslation();

  const { provider } = useUser();

  const [state, setState] = useState({
    error: null,
    loading: false,
    email: '',
  });

  if (!provider) {
    return null;
  }

  async function handleOnSubmit({ email }) {
    setState({
      ...state,
      email,
      error: null,
      loading: true,
    });

    try {
      await request({
        method: 'POST',
        path: '/1/auth/request-password',
        body: {
          email,
        },
      });

      setState({
        ...state,
        email: email,
        loading: false,
        success: true,
      });
    } catch (error) {
      setState({
        ...state,
        error,
        loading: false,
      });
    }
  }

  const { error, success, email, loading } = state;

  return (
    <PageCenter>
      <LogoTitle title={t('title.forgotPassword', 'Forgot Password')} />
      <Segment.Group>
        <Segment padded>
          {error && <ApiMessage error message={error.message} />}
          {success ? (
            <Message info>
              <Message.Header>
                {t('title.emailSent', 'Mail Sent!')}
              </Message.Header>
              <Message.Content>
                <Trans i18nKey="message.forgotPasswordSuccess" email={email}>
                  Please follow the instructions in the email we sent to
                  <b>{{ email }}</b>
                </Trans>
              </Message.Content>
            </Message>
          ) : (
            <Form onSubmit={handleOnSubmit} loading={loading} />
          )}
        </Segment>
        <Segment secondary>
          <Grid>
            <Grid.Column floated="left" width={8}>
              <Link to="/login">{t('link.login', 'Login')}</Link>
            </Grid.Column>

            {provider.enableSelfSignup && (
              <Grid.Column floated="right" width={8} textAlign="right">
                <Link to={determineSignupRouteForProvider(provider)}>
                  {t('link.noAccount', 'Dont have an account')}
                </Link>
              </Grid.Column>
            )}
          </Grid>
        </Segment>
      </Segment.Group>
    </PageCenter>
  );
}
