import React from 'react';
import { request } from 'utils/api';
import { Segment, Form, Header, Message } from 'semantic';

import PageCenter from 'components/PageCenter';
import Logo from 'components/LogoTitle';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts/user';
import { Trans, withTranslation } from 'react-i18next';

class MfaBackupVerification extends React.Component {
  state = {
    error: null,
    loading: false,
    code: '',
  };

  static contextType = UserContext;

  componentDidMount() {
    const data = this.getMfaSessionData();
    if (!data) {
      this.props.history.push('/login');
      return;
    }
    if (data.mfaMethod === 'sms') {
      this.triggerToken();
    }
  }

  getMfaSessionData() {
    const data = window.localStorage.getItem('mfa-auth');
    if (!data) return null;
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }

  triggerToken = async () => {
    const { token } = this.getMfaSessionData();

    this.setState({
      error: null,
      loading: true,
    });

    try {
      await request({
        method: 'POST',
        path: '/1/mfa/send-code',
        token,
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  onSubmit = async () => {
    const { mfaToken } = this.getMfaSessionData();
    this.setState({
      error: null,
      loading: true,
    });

    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/mfa/verify',
        token: mfaToken,
        body: {
          code: this.state.code,
        },
      });

      this.context.setToken(data.token);
      this.props.history.push('/');
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { error, loading } = this.state;
    const { t } = this.props;
    const { provider } = this.context;
    const supportEmail = provider?.supportEmail;

    return (
      <PageCenter>
        <Logo
          title={t(
            'loginBackupVerfication.logoTitle',
            'Two-factor Verification'
          )}
        />
        <Segment.Group>
          <Segment padded>
            <Form error={!!error} size="large" onSubmit={this.onSubmit}>
              <Header>
                {t('loginBackupVerfication.header', 'Use Your Backup Codes')}
              </Header>
              {error && <Message error content={error.message} />}
              <p>
                {t(
                  'loginBackupVerfication.description',
                  'Please enter one of your unused backup verification codes:'
                )}
              </p>
              <Form.Input
                value={this.state.code}
                onChange={(e, { value }) => this.setState({ code: value })}
                name="code"
                placeholder={t(
                  'loginBackupVerfication.codePlaceHolder',
                  'Backup verification code'
                )}
              />
              <Form.Button
                fluid
                primary
                size="large"
                content={t('button.login', 'Login')}
                loading={loading}
                disabled={loading}
              />
              {supportEmail && (
                <p>
                  <Trans
                    i18nKey="loginBackupVerfication.contactSupportV2"
                    supportEmail={supportEmail}>
                    Don't have your backup codes anymore? Contact support at{' '}
                    <a href={`mailto:${supportEmail}`}>{{ supportEmail }}</a> to
                    start a manual verification process.
                  </Trans>
                </p>
              )}
            </Form>
          </Segment>
          <Segment secondary>
            <Link to="/login/verification">
              {t(
                'loginBackupVerfication.backToRerification',
                'Back to verification'
              )}
            </Link>
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }
}

export default withTranslation()(MfaBackupVerification);
