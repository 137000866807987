import React from 'react';
import { request } from 'utils/api';
import { Segment, Form, Message, Button } from 'semantic';

import PageCenter from 'components/PageCenter';
import Logo from 'components/LogoTitle';

import { Link } from 'react-router-dom';
import { useUser } from 'contexts/user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function LoginSSO(props) {
  const redirectUrl = new URL(window.location.href);

  let redirectPath = '/';

  if (redirectUrl.searchParams.get('redirect')) {
    redirectPath = redirectUrl.searchParams.get('redirect');
  }

  const { provider } = useUser();

  const [state, setState] = useState({
    error: null,
    loading: false,
    email: '',
    ssoEnabled: provider.enableSingleSignOn,
  });

  const { t } = useTranslation();

  async function handleOnSubmit() {
    setState({
      ...state,
      error: null,
      loading: true,
    });

    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/auth/login/sso',
        body: {
          email: state.email,
          redirectUrl: redirectPath,
        },
      });

      if (!data.ssoEnabled) {
        setState({ ssoEnabled: false, error: null, loading: false });
        return;
      }

      if (data.ssoEnabled && data.redirectRequired) {
        window.location = data.redirectUrl;
        return;
      }
    } catch (error) {
      setState({
        ...state,
        error,
        loading: false,
      });
    }
  }

  if (!state.ssoEnabled) {
    return (
      <PageCenter>
        <Logo title="Login" />
        <Segment.Group>
          <Segment padded>
            <Message
              error
              content={t(
                'error.ssoNotEnabled',
                'Single Sign-On is not enabled for your service provider.'
              )}
            />
            <Link to={'/login'}>
              {t('link.loginWithPassword', 'Log in with email and password')}
            </Link>
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }

  return (
    <PageCenter>
      <Logo title="Login" />
      <Segment.Group>
        <Segment padded>
          <Form error={!!state.error} size="large" onSubmit={handleOnSubmit}>
            {state.error && <Message error content={state.error.message} />}
            <Form.Field error={state.error?.hasField?.('email')}>
              <Form.Input
                value={state.email}
                onChange={(e, { value }) => {
                  setState({ ...state, email: value });
                }}
                name="email"
                icon="envelope"
                iconPosition="left"
                placeholder={t('formPlaceHolder.email', 'E-mail Address')}
                type="email"
                autoComplete="email"
              />
            </Form.Field>
            <div>
              <Button
                fluid
                primary
                size="large"
                content="Login"
                loading={state.loading}
                disabled={state.loading}
              />
            </div>
          </Form>
        </Segment>
        <Segment padded>
          <Link
            to={{
              pathname: '/login',
              search: props.location.search,
            }}>
            {t('link.loginWithPassword', 'Log in with email and password')}
          </Link>
        </Segment>
      </Segment.Group>
    </PageCenter>
  );
}
