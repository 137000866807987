import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { Container, Message } from 'semantic';
import { useTranslation } from 'react-i18next';

import { AppWrapper } from 'components';
import List from './List';
import Form from './Form';
import {
  EVSE_CONFIG_PRESETS_FE_PATH,
  EVSE_CONFIG_PRESETS_FE_PATH_CREATE,
  hasEvseConfigPresetPermissions,
} from 'screens/EvseConfigPreset/utils';

export default function EvseConfigPresetIndex() {
  const { t } = useTranslation();
  const hasReadAccess = hasEvseConfigPresetPermissions('read');
  const hasWriteAccess = hasEvseConfigPresetPermissions('write');

  return (
    <AppWrapper>
      {!hasReadAccess && (
        <Message
          error
          content={t(
            'evseConfigPresets.noFeature',
            'You do not have access to this feature.'
          )}
        />
      )}

      {hasReadAccess && (
        <Container>
          <Switch>
            <Route path={EVSE_CONFIG_PRESETS_FE_PATH} component={List} exact />
            {hasWriteAccess && (
              <>
                <Route
                  path={EVSE_CONFIG_PRESETS_FE_PATH_CREATE}
                  component={Form}
                  exact
                />
                <Route
                  path={`${EVSE_CONFIG_PRESETS_FE_PATH}/:id/edit`}
                  component={Form}
                  exact
                />
              </>
            )}
          </Switch>
        </Container>
      )}
    </AppWrapper>
  );
}
