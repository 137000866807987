import FeatureFlag from 'components/FeatureFlag';
import Space from 'components/Layout/helpers/Space';
import PlatformModule from 'components/PlatformModule';
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Header } from 'semantic';
import { BillingPlan, BillingPlanPrice } from 'types/billingplan';
import request from 'utils/api/request';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import TagsField from 'components/form-fields/formik/TagsField';
import InputField from 'components/form-fields/formik/InputField';
import SearchDropdown from 'components/form-fields/formik/SearchDropdown';
import { AccountTier } from 'types/account-tier';
import { Checkbox, Popup, Table } from 'semantic-ui-react';
import { useFeatures } from 'contexts/features';
import { SUPPORTED_CURRENCIES } from 'components/Layout/utils';
import SelectField from 'components/form-fields/formik/SelectField';
import HelpTip from 'components/HelpTip';
import { PlanDetails } from './BillingPlanFormShared';
import { ActionButtonWithIcon } from 'components/semantic/ActionButtonWithIcon';

type ProviderCurrency = {
  currency: string;
  active: boolean;
};

type Props = {
  billingPlan: BillingPlan & {
    usingBillingPlanFeesPercentage?: boolean;
    usingATBPDefaults?: boolean;
  };
  currencies: ProviderCurrency[];
  currenciesInUse: string[];

  // I had to lift those state vars to parent comp to allow return the form to original state after the
  // use unchecks useATBPDefaults, it will rerender with the defaults as initalParams.
  useDefaults: boolean;
  setUseDefaults: (checked: boolean) => void;
};

export function BillingPlanFormAccountTier({
  billingPlan,
  currencies,
  currenciesInUse,
  useDefaults,
  setUseDefaults,
}: Props) {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<BillingPlan>();

  // For Account Tier billing plans
  // we want to reuse the English name across all languages
  useEffect(() => {
    if (useDefaults && billingPlan.details.en.name) {
      const billingPlanName = billingPlan.details.en.name;

      setFieldValue('details.nl.name', billingPlanName);
      setFieldValue('details.de.name', billingPlanName);
      setFieldValue('details.fr.name', billingPlanName);
      setFieldValue('details.it.name', billingPlanName);
      setFieldValue('details.es.name', billingPlanName);
    }
  }, [useDefaults, billingPlan.details.en.name]);

  return (
    <>
      <Space size={10} direction="vertical" />
      {!billingPlan.id && (
        <Form.Field>
          <Checkbox
            toggle
            label={t(
              'editBillingPlan.useAccountTierBillingPlanDefaults',
              'Use Account Tier billing plan defaults'
            )}
            checked={useDefaults}
            onClick={() => setUseDefaults(!useDefaults)}
          />
        </Form.Field>
      )}
      <Header>{t('editBillingPlan.prices', 'Prices')}</Header>
      <FieldArray
        name="prices"
        render={(arrayHelpers) => (
          <PricesField
            prices={billingPlan.prices}
            currencies={currencies}
            currenciesInUse={currenciesInUse}
            onDelete={(index) => {
              arrayHelpers.remove(index);
            }}
          />
        )}
      />

      <PlatformModule moduleName="multi-currency">
        <FeatureFlag feature="multi_currency_support">
          {currencies.length > (billingPlan?.prices?.length ?? 0) && (
            <ActionButtonWithIcon
              text={t('editBillingPlan.addPrice', 'Add currency')}
              icon="plus"
              onClick={() => {
                const newPrices = billingPlan?.prices ?? [];
                newPrices.push({
                  currency: '',
                  perPeriod: 0,
                  perCard: 0,
                  perDevice: 0,
                  perSession: 0,
                  perKwhFixed: 0,
                  perKwhPercentage: 0,
                });
                setFieldValue('prices', newPrices);
              }}
            />
          )}
        </FeatureFlag>
      </PlatformModule>

      <Header>{t('editBillingPlan.options', 'Options')}</Header>
      <Form.Group>
        <CheckboxField
          label={t('editBillingPlan.visibleToAll', 'Is visible to all users?')}
          toggle
          name="isPublic"
        />
      </Form.Group>

      <Form.Field>
        <InputField name="priority" type="number" label="Display priority" />
      </Form.Field>

      <Form.Field>
        <TagsField label={t('editBillingPlan.tags', 'Tags')} name="tags" />
      </Form.Field>

      <SearchDropdown
        name="accountTierId"
        key={billingPlan.accountTierId}
        objectMode={false}
        label={t('editBillingPlan.accountTier', 'Account Tier')}
        onDataNeeded={() =>
          request<{ data: AccountTier[] }>({
            method: 'GET',
            path: '/1/account-tiers',
          }).then((res) => {
            return {
              data: res.data?.map((accountTier) => ({
                id: accountTier?.id,
                name: accountTier?.name,
              })),
            };
          })
        }
        getOptionLabel={(item) => item.name}
        required
      />

      <PlanDetails billingPlan={billingPlan} />
    </>
  );
}

type PricesFieldProps = {
  prices: BillingPlanPrice[];
  currencies: ProviderCurrency[];
  currenciesInUse: string[];
  onDelete: (index: number) => void;
};

function PricesField({
  prices,
  currencies,
  currenciesInUse,
  onDelete,
}: PricesFieldProps) {
  const { hasFeature } = useFeatures();
  const { t } = useTranslation();
  return (
    <>
      <Table style={{ tableLayout: 'fixed' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{t('common.currency', 'Currency')}</span>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <span>{t('editBillingPlan.perPeriod', 'per Period')}</span>{' '}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{t('editBillingPlan.perDevice', 'Per Device')}</span>{' '}
                <HelpTip
                  title={t('editBillingPlan.perDeviceTitle', 'Device')}
                  text={t(
                    'editBillingPlan.perDeviceText',
                    'The price "per device" refers to a one-time fee that is charged when a new charge station is added by an account. This is only applicable when configuring a Charge Station billing plan.'
                  )}
                />
              </div>
            </Table.HeaderCell>

            <FeatureFlag feature="multi_currency_support">
              <PlatformModule moduleName="multi-currency">
                <Table.HeaderCell width={1}></Table.HeaderCell>
              </PlatformModule>
            </FeatureFlag>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {prices?.map((price, i) => {
            const currencyLabel = SUPPORTED_CURRENCIES.find(
              (x) => x.value === price.currency
            );
            return (
              <Table.Row key={i}>
                <Table.Cell>
                  {hasFeature('multi_currency_support') ? (
                    <PlatformModule moduleName="multi-currency">
                      <SelectField
                        name={`prices.${i}.currency`}
                        options={currencies?.map((x) => ({
                          key: x.currency,
                          text: `${x.currency} (${
                            x.active
                              ? t('common.active', 'active')
                              : t('common.inactive', 'inactive')
                          })`,
                          value: x.currency,
                        }))}
                        hideErrorLabel
                      />
                    </PlatformModule>
                  ) : (
                    <p>{currencyLabel?.text}</p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <InputField
                    name={`prices.${i}.perPeriod`}
                    type="number"
                    min={0}
                    step={0.01}
                    hideErrorLabel
                  />
                </Table.Cell>
                <Table.Cell>
                  <InputField
                    name={`prices.${i}.perDevice`}
                    type="number"
                    min={0}
                    step={0.01}
                    hideErrorLabel
                  />
                </Table.Cell>

                <PlatformModule moduleName="multi-currency">
                  <FeatureFlag feature="multi_currency_support">
                    <Table.Cell>
                      <Popup
                        disabled={
                          prices.length !== 1 &&
                          !currenciesInUse.includes(price.currency)
                        }
                        content={
                          prices.length === 1
                            ? t(
                                'editBillingPlan.lastCurrencyCannotBeDeleted',
                                'Billing plan should contain at least one currency.'
                              )
                            : t(
                                'editBillingPlan.currencyCannotBeDeletedAccountRef',
                                'Currency cannot be deleted as it is in use by at least one account.'
                              )
                        }
                        trigger={
                          <span>
                            <Button
                              as="button"
                              icon="trash"
                              disabled={
                                prices.length === 1 ||
                                currenciesInUse.includes(price.currency)
                              }
                              onClick={async () => {
                                onDelete(i);
                              }}
                            />
                          </span>
                        }
                      />
                    </Table.Cell>
                  </FeatureFlag>
                </PlatformModule>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
