import React from 'react';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';
import { useTranslation } from 'react-i18next';
import CardPlanCard, {
  CardBillingPlan,
} from 'screens/Settings/screens/organization/BillingAndPlans/CardPlanCard';
import { useQuery } from '@tanstack/react-query';
import { request } from 'utils/api';
import { Loader } from 'semantic';
import { Message } from 'semantic-ui-react';

export default function CardPlans() {
  const { t } = useTranslation();

  const {
    isLoading,
    data: response,
    isError,
  } = useQuery<unknown, unknown, { data: CardBillingPlan[] }>({
    queryKey: [],
    queryFn: () =>
      request({
        path: `/1/cards/mine/billing-plans/breakdown`,
        method: 'GET',
      }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  if (!isLoading && (isError || !response?.data?.length)) {
    return (
      <>
        <Message error>
          <p>
            {t(
              'settings.organization.billing.cardPlans.failedToLoadError',
              'An error occurred while loading card plans.'
            )}
          </p>
        </Message>
      </>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Loader active inline>
          {t(
            'settings.organization.billing.cardPlans.loading',
            'Loading card plans...'
          )}
        </Loader>
      </div>
    );
  }

  return (
    <>
      <FormHeader
        size="medium"
        pt={5}
        pb={5}
        text={t('settings.organization.billing.cardPlans.title', 'Card Plans')}
      />
      {response?.data?.map((plan) => {
        return (
          <CardPlanCard
            key={`${plan.name}${plan.numCardsUsingBillingPlan}`}
            cardPlan={plan}
          />
        );
      })}
    </>
  );
}
