import { useFeatures, FeatureFlags } from 'contexts/features';
import { useUser } from 'contexts/user';
import { userCanAccessProviderEndpoint } from 'utils/roles';

// FE paths
export const CHARGING_STATIONS_FE_PATH = `/charging-stations`;
export const EVSE_CONFIG_PRESETS_FE_PATH = `${CHARGING_STATIONS_FE_PATH}/config-presets`;
export const EVSE_CONFIG_PRESETS_FE_PATH_CREATE = `${EVSE_CONFIG_PRESETS_FE_PATH}/create`;
export const USERS_FE_PATH = `/accounts/users`;

// API paths
export const EVSE_CONFIG_PRESET_BE_PATH = `/1/evse-config-presets`;

export const EVSE_CONFIG_PRESET_PERMISSIONS = 'evseConfigPresets';

export const hasEvseConfigPresetPermissions = (
  requiredLevel: 'read' | 'write'
): boolean => {
  const { hasFeature } = useFeatures();
  const { user, provider } = useUser();
  if (!user) {
    return false;
  }

  const hasPermission = userCanAccessProviderEndpoint(
    user,
    provider,
    EVSE_CONFIG_PRESET_PERMISSIONS,
    requiredLevel
  );

  return hasFeature(FeatureFlags.EvseConfigPresets) && hasPermission;
};
