import React, { useContext } from 'react';
import { Search } from 'components';
import { Label, Table } from 'semantic';
import { Link } from 'react-router-dom';
import {
  ACCOUNTS_FE_PATH,
  CHARGING_STATIONS_FE_PATH,
  EVSE_CONTROLLERS_LOCATIONS_FE_PATH,
} from 'screens/EvseControllersBackgroundJobs/utils';
import { ConnectivityStatus } from 'components/ConnectivityStatus';
import EvseControllerBackgroundJobExecutionStatus from 'screens/EvseControllersBackgroundJobs/EvseControllerBackgroundJobExecutionStatus';
import { useTranslation } from 'react-i18next';
import ResultsFound from 'screens/EvseControllersBackgroundJobs/ResultsFound';
import {
  EvseBulkActionWorkflow,
  EvseControllerBackgroundJobStatus,
} from 'types/evse-controller-background-job-execution';
import HelpTip from 'components/HelpTip';
import { Center } from 'components/Layout/helpers';
import WorkflowActions from './Actions';
import { formatDateTime } from 'utils/date';

type WorkflowsTableProps = {
  backgroundJobId: string;
  onRefresh: () => void;
};

export default function WorkflowsTable({
  backgroundJobId,
  onRefresh,
}: WorkflowsTableProps) {
  const { items, loading } = useContext(Search.Context);
  const { t } = useTranslation();

  if (!items?.length || loading) {
    return null;
  }

  return (
    <>
      <ResultsFound numResults={items?.length || 0} />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>
              {t(
                'evseControllersBackgroundJobsDetail.columChargeStationId',
                'Charge Station ID'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t(
                'evseControllersBackgroundJobsDetail.columAccountInfo',
                'Account Info'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t(
                'evseControllersBackgroundJobsDetail.columFirmwareVersion',
                'Firmware Version'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t(
                'evseControllersBackgroundJobsDetail.columOcppVersion',
                'OCPP Version'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t(
                'evseControllersBackgroundJobsDetail.columConnectivity',
                'Connectivity'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllersBackgroundJobsDetail.columStatus', 'Status')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t(
                'evseControllersBackgroundJobsDetail.columnActions',
                'Actions'
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items?.map((workflow: EvseBulkActionWorkflow) => {
            const evseController = workflow.evseController;
            if (!evseController) {
              return null;
            }

            const ocppVersion = evseController.ocppProtocolVersion?.replaceAll(
              'ocpp',
              ''
            );

            const statusDetails =
              workflow.status === EvseControllerBackgroundJobStatus.Cancelled
                ? t(
                    'evseControllersBackgroundJobsDetail.statusDetailModalDetailsCanceledInfo',
                    'The job is canceled by {{requestedBy}} on {{requestedAt}}.',
                    {
                      requestedBy:
                        workflow.cancelRequestedByUser?.name || 'Unknown',
                      requestedAt: formatDateTime(workflow.cancelRequestedAt),
                    }
                  )
                : workflow.status ===
                    EvseControllerBackgroundJobStatus.Cancelling
                  ? t(
                      'evseControllersBackgroundJobsDetail.statusDetailModalDetailsCancelingInfo',
                      'The job is canceled by {{requestedBy}} on {{requestedAt}}.',
                      {
                        requestedBy:
                          workflow.cancelRequestedByUser?.name || 'Unknown',
                        requestedAt: formatDateTime(workflow.cancelRequestedAt),
                      }
                    )
                  : workflow.statusDetails;

            return (
              <Table.Row key={evseController.id}>
                <Table.Cell>
                  {evseController.deleted ? (
                    evseController.ocppIdentity
                  ) : (
                    <>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to={`${CHARGING_STATIONS_FE_PATH}/${evseController.id}`}
                          target="_blank">
                          {evseController.ocppIdentity}
                        </Link>
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {t(
                          'evseControllersBackgroundJobsDetail.columSerialNumberText',
                          'Serial Number'
                        )}
                        : {evseController.serialNumber}
                      </p>
                    </>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {evseController.account && (
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'evseControllersBackgroundJobsDetail.columAccountText',
                        'Account'
                      )}
                      :{' '}
                      <Link
                        to={`${ACCOUNTS_FE_PATH}/${evseController.account.id}`}
                        target="_blank">
                        {evseController.account.name}
                      </Link>
                    </p>
                  )}

                  {evseController.maintenanceAccount && (
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'evseControllersBackgroundJobsDetail.columFieldServiceText',
                        'Field Service'
                      )}
                      :{' '}
                      <Link
                        to={`${ACCOUNTS_FE_PATH}/${evseController.maintenanceAccount.id}`}
                        target="_blank">
                        {evseController.maintenanceAccount.name}
                      </Link>
                    </p>
                  )}
                  {evseController.location && (
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'evseControllersBackgroundJobsDetail.columLocationText',
                        'Location'
                      )}
                      :{' '}
                      <Link
                        to={`${EVSE_CONTROLLERS_LOCATIONS_FE_PATH}/${evseController.location.id}`}
                        target="_blank">
                        {evseController.location.name}
                      </Link>
                    </p>
                  )}
                  {evseController.billingPlan && (
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'evseControllersBackgroundJobsDetail.columPlanText',
                        'Plan'
                      )}
                      : {evseController.billingPlan.name}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {evseController.bootInfo?.firmwareVersion}
                </Table.Cell>
                <Table.Cell textAlign="center">{ocppVersion}</Table.Cell>
                <Table.Cell textAlign="center">
                  {evseController.deleted ? (
                    <Label
                      color="red"
                      content={t('common.deleted', 'Deleted')}
                    />
                  ) : (
                    <ConnectivityStatus item={evseController} />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Center>
                    <EvseControllerBackgroundJobExecutionStatus
                      status={workflow.status}
                    />
                    {statusDetails && (
                      <HelpTip
                        disabledIconTitle
                        marginTop="0"
                        marginLeft="0.5em"
                        iconTooltip="info"
                        iconCircular
                        title={t(
                          'evseControllersBackgroundJobsDetail.statusDetailModalTitle',
                          'Status Description'
                        )}
                        text={
                          <>
                            <p>
                              {t(
                                'evseControllersBackgroundJobsDetail.statusDetailModalJobStatus',
                                'Job status for'
                              )}{' '}
                              {evseController.ocppIdentity}
                            </p>
                            <div style={{ paddingBottom: 20 }}>
                              <EvseControllerBackgroundJobExecutionStatus
                                status={workflow.status}
                              />
                            </div>
                            <p>
                              <strong>
                                {t(
                                  'evseControllersBackgroundJobsDetail.statusDetailModalDetailsTitle',
                                  'Status details'
                                )}
                                :
                              </strong>
                            </p>
                            <p style={{ whiteSpace: 'pre-line' }}>
                              {statusDetails.replace(
                                /: |, /g,
                                (match) => match + '\n'
                              )}
                            </p>
                          </>
                        }
                      />
                    )}
                  </Center>
                </Table.Cell>
                <Table.Cell>
                  <Center>
                    <WorkflowActions
                      t={t}
                      backgroundJobId={backgroundJobId}
                      workflow={workflow}
                      onRefresh={onRefresh}
                    />
                  </Center>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
