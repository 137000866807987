import { BillingPlan } from '../../types/billingplan';

export const resolveBillingPlanTypeName = (plan: BillingPlan) => {
  switch (plan.type) {
    case 'consumer':
      return 'Consumer Card';
    case 'station':
      return 'Charging Station';
    case 'account-tier':
      return 'Account Tier';
    case 'roaming-fee':
      return 'Roaming Fee';
    case 'adhoc-payment-fee':
      return 'Adhoc Payment Fee';
    default:
      return '';
  }
};
