import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchDropdown from '../form-fields/formik/SearchDropdown';
import { request } from '../../utils/api';

export default function EditEvseControllerLabels({
  objectPath,
}: {
  objectPath: string;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <SearchDropdown
          name={`${objectPath}.assignLabels`}
          label={t('evseControllers.labels.assignLabels', 'Assign Labels')}
          multiple
          allowAdditions={true}
          onDataNeeded={() =>
            request({
              path: '/1/evse-controllers/available-labels',
              method: 'GET',
            }).then((res) => {
              return {
                data: res.data?.map((label: string) => ({
                  id: label,
                  name: label,
                })),
              };
            })
          }
          placeholder={t(
            'editEvseControllersModal.selectOption',
            'Select Option'
          )}
        />
      </div>
      <div>
        <SearchDropdown
          name={`${objectPath}.removeLabels`}
          label={t('evseControllers.labels.removeLabels', 'Remove Labels')}
          multiple
          allowAdditions={false}
          onDataNeeded={() =>
            request({
              path: '/1/evse-controllers/available-labels',
              method: 'GET',
            }).then((res) => {
              return {
                data: res.data?.map((label: string) => ({
                  id: label,
                  name: label,
                })),
              };
            })
          }
          placeholder={t(
            'editEvseControllersModal.selectOption',
            'Select Option'
          )}
        />
      </div>
    </>
  );
}
