import {
  ActionButton,
  Button,
  Icon,
  Label,
  LabelDetail,
  Table,
} from 'semantic';
import ConfigurationInput, {
  ConfigurationInputType,
  Value,
} from 'screens/EvseControllersBackgroundJobs/ChangeConfiguration/ConfigurationInput';
import { OCPPProtocolVersion } from 'types/evse-controller';
import React, { useMemo } from 'react';
import { shortCircuitEvent } from 'utils/events';
import useFetch from 'hooks/useFetch';
import {
  configurationKeysFromProtocolResponses,
  ProtocolResponse16,
  ProtocolResponse201,
  StandardConfigurationKeys,
} from 'screens/EvseControllersBackgroundJobs/ChangeConfiguration/protocol';
import { useTranslation } from 'react-i18next';
import { isEmpty, maxBy, size } from 'lodash-es';

export type ConfigurationItemForm = {
  id: string;
  name: string;
  value: string;
  component?: {
    name: string;
  };
  ocppProtocolVersion: string;
  label?: string;
  input?: ConfigurationInputType;
  standardKeyId?: string;
  presetId?: string;
  edit: boolean;
};

type Props = {
  items: ConfigurationItemForm[];
  ocppProtocols: OCPPProtocolVersion[];
  onChange: (items: ConfigurationItemForm[]) => void;
};

export const isConfigValid = (
  config: ConfigurationItemForm,
  ocppProtocols: string[] = []
) => {
  const onlyOcpp201 = ocppProtocols.every(
    (p) => p === OCPPProtocolVersion.OCPP201
  );
  if (onlyOcpp201) {
    return config?.name && config?.component?.name && config?.value;
  }
  return config?.name && config?.value;
};

export const isConfigEmpty = (config: ConfigurationItemForm) => {
  return (
    isEmpty(config.name) && isEmpty(config.value) && isEmpty(config.component)
  );
};

export const buildChangeConfigurationItem = (
  id: string = '1',
  input: ConfigurationInputType = ConfigurationInputType.STANDARD
): ConfigurationItemForm => ({
  id,
  name: '',
  value: '',
  ocppProtocolVersion: '',
  label: '',
  input,
  edit: true,
});

export const newConfigurationItemId = (items: ConfigurationItemForm[]) => {
  const configurationItemWithMaxId = maxBy(items, (c) => Number(c.id));
  return configurationItemWithMaxId
    ? Number(configurationItemWithMaxId.id) + 1
    : 1;
};

export default function ConfigurationInputList({
  items,
  ocppProtocols,
  onChange,
}: Props) {
  const { t } = useTranslation();

  const { data: protocol16 } = useFetch<ProtocolResponse16>({
    path: '1/ocpp/1.6/protocol',
  });

  const { data: protocol201 } = useFetch<ProtocolResponse201>({
    path: '1/ocpp/2.0.1/protocol',
  });

  const standardConfigurationKeys = useMemo<StandardConfigurationKeys>(() => {
    return configurationKeysFromProtocolResponses(
      ocppProtocols,
      protocol16,
      protocol201
    );
  }, [ocppProtocols, protocol16, protocol201]);

  const configurationItemLabel = (item: Value) =>
    item.input === ConfigurationInputType.STANDARD ? (
      <Label color="blue">
        {t(
          'evseControllersBackgroundJobsChangeConfiguration.addedStandardVariableKeyLabel',
          'Standard Variable'
        )}
        <LabelDetail
          content={`OCPP ${item.ocppProtocol === 'ocpp1.6' ? '1.6' : '2.0.1'}`}
        />
      </Label>
    ) : (
      <Label color="black">
        {t(
          'evseControllersBackgroundJobsChangeConfiguration.addedCustomVariableKeyLabel',
          'Custom Variable'
        )}
      </Label>
    );

  return (
    <>
      {size(items) > 0 && (
        <Table padded basic>
          <Table.Body>
            {items.map((item) => {
              const idx = items.findIndex((i) => i.id === item.id);
              const value: Value = {
                name: item.name,
                value: item.value,
                component: item.component?.name,
                ocppProtocol: item.ocppProtocolVersion as OCPPProtocolVersion,
                input:
                  item.input == ConfigurationInputType.STANDARD ||
                  standardConfigurationKeys[item.standardKeyId ?? '']
                    ? ConfigurationInputType.STANDARD
                    : ConfigurationInputType.CUSTOM,
                standardKeyId: item.standardKeyId,
              };

              return (
                <Table.Row key={item.id}>
                  {item.edit && (
                    <Table.Cell colspan="4">
                      <div style={{ display: 'flex' }}>
                        <div style={{ flexGrow: 1 }}>
                          <ConfigurationInput
                            withBox={false}
                            standardConfigurationKeys={
                              standardConfigurationKeys
                            }
                            ocppProtocols={ocppProtocols}
                            value={value}
                            onChange={(v: Value) => {
                              const configurationItems = [...items];
                              configurationItems[idx] = mapConfigurationValue(
                                item.id,
                                v,
                                standardConfigurationKeys
                              );

                              onChange(configurationItems);
                            }}
                          />
                        </div>
                        <div>
                          <ActionButton
                            style={{ paddingLeft: 0 }}
                            disabled={!isConfigValid(item, ocppProtocols)}
                            primary
                            compact
                            onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                              shortCircuitEvent(e);
                              const configurationItems = [...items];
                              configurationItems[idx] = {
                                ...configurationItems[idx],
                                edit: false,
                              };
                              onChange(configurationItems);
                            }}>
                            <>
                              <Icon name="compress" />
                              {t(
                                'evseControllersBackgroundJobsChangeConfiguration.minimizeConfiguration',
                                'Minimize'
                              )}
                            </>
                          </ActionButton>
                          <Button
                            basic
                            icon="trash"
                            title="Delete"
                            onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                              shortCircuitEvent(e);

                              const newConfigurationItems = items.filter(
                                (c) => c.id !== item.id
                              );

                              onChange(newConfigurationItems);
                            }}
                          />
                        </div>
                      </div>
                    </Table.Cell>
                  )}
                  {!item.edit && (
                    <>
                      <Table.Cell collapsing>
                        {configurationItemLabel(value)}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        {t(
                          'evseControllersBackgroundJobsChangeConfiguration.configurationItemKey',
                          'Key'
                        ) + ': '}
                        <strong>
                          {item.component?.name && `${item.component.name}.`}
                          {item.name}
                        </strong>
                      </Table.Cell>
                      <Table.Cell>
                        {t(
                          'evseControllersBackgroundJobsChangeConfiguration.configurationItemValue',
                          'Value'
                        ) + ': '}
                        <strong>{item.value}</strong>
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Button
                          basic
                          icon="pen-to-square"
                          title="Edit"
                          onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                            shortCircuitEvent(e);
                            const configurationItems = [...items];
                            configurationItems[idx] = {
                              ...configurationItems[idx],
                              edit: true,
                            };
                            onChange(configurationItems);
                          }}
                        />
                        <Button
                          basic
                          icon="trash"
                          title="Delete"
                          onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                            shortCircuitEvent(e);
                            const configurationItems = [...items];
                            configurationItems.splice(idx, 1);
                            onChange(configurationItems);
                          }}
                        />
                      </Table.Cell>
                    </>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
      <AddConfigurationActionButton
        icon="plus"
        text={t(
          'evseControllersBackgroundJobsChangeConfiguration.addConfigurationItem',
          'Add configuration item'
        )}
        onClick={() => {
          const configurationItems = items.map((item) => {
            if (isConfigValid(item, ocppProtocols)) {
              return { ...item, edit: false };
            }
            return item;
          });

          const configurationItemsId =
            newConfigurationItemId(configurationItems);

          onChange([
            ...configurationItems,
            buildChangeConfigurationItem(`${configurationItemsId}`),
          ]);
        }}
      />
    </>
  );
}

function mapConfigurationValue(
  id: string,
  value: Value,
  standardConfigurationKeys: StandardConfigurationKeys
): ConfigurationItemForm {
  const standardConfiguration = value.standardKeyId
    ? standardConfigurationKeys[value.standardKeyId]
    : null;

  return {
    id,
    label: standardConfiguration?.label || value.name,
    name: value.name,
    value: value.value,
    ...(value.component && {
      component: {
        name: value.component,
      },
    }),
    ocppProtocolVersion: value.ocppProtocol || '',
    input: value.input,
    standardKeyId: value.standardKeyId,
    edit: true,
  };
}

function AddConfigurationActionButton({
  text,
  icon,
  onClick,
  disabled = false,
}: {
  text: string;
  icon: string;
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <ActionButton
      style={{ paddingLeft: 0 }}
      disabled={disabled}
      primary
      compact
      onClick={(e: React.SyntheticEvent<HTMLElement>) => {
        shortCircuitEvent(e);
        onClick?.();
      }}>
      <>
        <Icon name={icon} />
        {text}
      </>
    </ActionButton>
  );
}
