import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Suspense, useState } from 'react';

import AuthSwitchRoute from 'components/routes/AuthSwitch';
import Protected from 'components/routes/Protected';
import PublicRoute from 'components/routes/Public';

import ConfirmAccess from 'screens/Auth/ConfirmAccess';
import MfaVerification from 'screens/Auth/MfaVerification';
import MfaBackupVerification from 'screens/Auth/MfaBackupVerification';
import SandboxDashboard from './screens/Sandbox';
import Home from './screens/Home';
import Settings from './screens/Settings';
import SyncLog from './screens/SyncLog';
import SyncLogIssues from './screens/SyncLogIssues';
import AuditTrail from './screens/AuditTrail';
import EvseIssuesAggregated from './screens/EvseIssues';
import OcpiCredentials from './screens/OcpiCredentials';
import Users from './screens/Users';
import Tokens from './screens/Tokens';
import TokenList from './screens/Tokens/TokenList';
import Roles from './screens/Roles';
import Providers from './screens/Providers';
import ProviderDetail from './screens/Providers/Detail';
import BillingPlans from './screens/BillingPlans';
import ProviderCurrencies from 'screens/ProviderCurrencies';
import Finance from './screens/Finance';
import FinanceRoaming from './screens/FinanceRoaming';
import FinanceRoamingLimits from 'screens/FinanceRoamingLimits';
import FinanceRoamingOverrides from './screens/FinanceRoamingOverrides';
import Login from './screens/Auth/Login';
import LoginSSO from './screens/Auth/Login/SSO';
import LoginSSOExchange from './screens/Auth/Login/SSO/Exchange';
import AcceptInvite from './screens/Auth/AcceptInvite';
import Accounts from './screens/Accounts';
import Logout from './screens/Auth/Logout';
import ForgotPassword from './screens/Auth/ForgotPassword';
import SignupMsp from './screens/Auth/Signup/WithPlan';
import SignupCpo from './screens/Auth/Signup/Cpo';
import SignupApi from './screens/Auth/Signup/Api';
import SignupFieldService from './screens/Auth/Signup/FieldService';
import SignupDirectPayment from './screens/Auth/Signup/DirectPayment';
import SignupV2 from './screens/Auth/SignupV2';

import ResetPassword from './screens/Auth/ResetPassword';
import SessionsMsp from './screens/SessionsMsp';
import SessionDetail from './screens/SessionDetail';
import SessionsMspActive from './screens/SessionsMsp/Active';
import SessionsMspImportBatches from './screens/SessionsMsp/ImportBatches';
import SessionsMspCreditSessions from './screens/SessionsMsp/CreditSessions';
import SessionsCpo from './screens/SessionsCpo';
import Invoices from './screens/Invoices';
import InvoicesEventLog from './screens/InvoiceEventLogs';

import Locations from './screens/Locations';
import Location from './screens/Location';
import LocationMsp from './screens/LocationMsp';
import LocationsAnalytics from './screens/LocationsAnalytics';
import LocationsMap from './screens/LocationsMap';
import AccessGroups from './screens/AccessGroups';
import AccessGroup from './screens/AccessGroup';
import MyMspUsage from './screens/MyMspUsage';
import MyCpoUsage from './screens/MyCpoUsage';

import MyInvoices from './screens/MyInvoices';
import MyCreditInvoices from './screens/MyCreditInvoices';
import MyCards from './screens/MyCards';
import MyLocations from './screens/MyLocations';
import MyUsers from './screens/MyUsers';
import MyAccessGroups from './screens/MyAccessGroups';
import MyAccessGroup from './screens/MyAccessGroup';
import MyRemoteSessions from './screens/MyRemoteSessions';
import MyRemoteSessionsMsp from './screens/MyRemoteSessionsMsp';
import MyOcpiCredentials from './screens/MyOcpiCredentials';
import MyOcpiCredential from './screens/MyOcpiCredential';
import MyCredentials from './screens/MyCredentials';
import MyCredential from './screens/MyCredential';
import ExternalProviders from './screens/ExternalProviders';
import ExternalTokens from './screens/ExternalTokens';
import EvseControllers from './screens/EvseControllers';
import SetupEvse from './screens/SetupEvse';
import EvseControllersMaintenance from './screens/EvseControllersMaintenance';
import EvseControllersMaintenanceIssues from './screens/EvseControllersMaintenance/Issues';
import EvseControllersStatus from './screens/EvseControllersStatus';
import Cards from './screens/Cards';
import CardsBatchAssign from './screens/CardsBatchAssign';
import Activate from './screens/Activate';
import SmartCharging from './screens/SmartCharging';
import ProviderAnalytics from './screens/Analytics/Provider';
import Docs from './screens/Docs';
import Credentials from './screens/Credentials';
import CredentialDetails from './screens/Credentials/Detail';
import EvseIds from './screens/EvseIds';
import EvseLink from './screens/EvseLink';
import TariffProfiles from './screens/TariffProfiles';
import ExchangeRates from './screens/ExchangeRates';
import FacilitatorLocations from './screens/FacilitatorLocations';
import LocationsFacilitatorMap from './screens/LocationsFacilitatorMap';

import MaintainceHome from './screens/MaintenanceHome';

import MaintenanceAnalytics from './screens/Analytics/Maintenance';
import FacilitatorAnalytics from './screens/Analytics/Facilitator';
import AccountAnalytics from './screens/Analytics/Account';

import ProviderFavicon from 'components/ProviderFavicon';
import OcppSwarmSimulator from './screens/OcppSwarmSimulator';
import FinanceExports from './screens/FinanceExports';
import SessionsSimulator from './screens/SessionsSimulator';
import ChargeStationSimulator from './screens/ChargeStationSimulator';
import EvseControllersBackgroundJobs from './screens/EvseControllersBackgroundJobs';
import BatchEvseIds from './screens/EvseIds/Batches/BatchEvseIds';
import TariffNotes from 'screens/TariffNotes';
import AccountTiers from 'screens/AccountTiers';
import ComingUpSoon from 'screens/System/ComingUpSoon';
import EnterpriseAccountMigrations from 'screens/EnterpriseAccountMigrations';
import EvseControllersLabels from 'screens/EvseControllersLabels';
import TokenBatches from './screens/TokenBatches';
import GetReceipt from 'screens/Receipt/GetReceipt';
import Cdrs from 'screens/Cdrs';
import CdrDetail from 'screens/CdrDetail';
import Integrations from 'screens/Integrations';
import EvseOperationalStatuses from 'screens/EvseOperationalStatuses';
import SearchChargingStations from 'screens/Search/ChargingStations';
import { subscribeToAuthenticationBus } from 'components/authentication/authentication-bus';
import { setupSSOTokenExpirationNotification } from 'components/authentication/authentication-checker';
import { ExpiredSessionLoginModal } from 'components/authentication/ExpiredSessionLoginModal';
import BillingEntities from 'screens/BillingEntities';

import MyPaymentFees from 'screens/MyPaymentFees';
import SpotlightMenu from 'components/SpotlightMenu';
import EvseConfigPresetIndex from 'screens/EvseConfigPreset';

const App = () => {
  const [authed, setAuthed] = useState(true);

  setupSSOTokenExpirationNotification();
  subscribeToAuthenticationBus((msg) => {
    setAuthed(msg === 'authorized');
  });

  return (
    <Suspense fallback={<div></div>}>
      {!authed && <ExpiredSessionLoginModal />}
      <ProviderFavicon />
      {authed && <SpotlightMenu />}
      <Switch>
        <Protected endpoint="me" exact path="/" component={Home} />
        <Protected path="/accounts/users" component={Users} endpoint="users" />
        <Protected path="/accounts" component={Accounts} endpoint="accounts" />

        <Protected
          path="/system/providers"
          exact
          component={() => <Providers routesContext="system" />}
          endpoint="system/providers"
        />

        <Protected
          path="/system/finance/billing-entities"
          exact
          component={BillingEntities}
          endpoint="system/billing-entities"
        />

        <Protected
          path="/system/finance/invoices/:path?/:param?"
          exact
          component={ComingUpSoon}
          endpoint="system/finance/invoices"
        />

        <Protected
          path="/system/finance/roaming"
          exact
          component={() => <FinanceRoaming routesContext="system" />}
          endpoint="system/finance/roaming"
        />
        <Protected
          exact
          path="/system/finance/billing-plans"
          component={ComingUpSoon}
          endpoint="system/finance/billing-plans"
        />
        <Protected
          exact
          path="/system/finance/exchange-rates"
          component={() => <ExchangeRates routesContext="system" />}
          endpoint="system/finance/exchangeRates"
        />
        <Protected
          exact
          path="/system/finance/roaming-overrides"
          component={() => <FinanceRoamingOverrides routesContext="system" />}
          endpoint="system/finance/roamingOverrides"
        />
        <Protected
          exact
          path="/system/finance/roaming-limits"
          component={() => <FinanceRoamingLimits />}
          endpoint="system/finance/roamingLimits"
        />

        <Protected
          path="/system/finance/:path?"
          exact
          component={ComingUpSoon}
          endpoint="system/finance"
        />

        <Protected
          path="/system/roaming/msp/locations/"
          component={ComingUpSoon}
          endpoint="system/roaming/msp/locations"
        />
        <Protected
          path="/system/roaming/msp/providers"
          component={ComingUpSoon}
          endpoint="system/roaming/msp/providers"
        />
        <Protected
          path="/system/roaming/tokens"
          component={ComingUpSoon}
          endpoint="system/roaming/tokens"
        />
        <Protected
          path="/system/roaming/ocpi"
          component={ComingUpSoon}
          endpoint="system/roaming/ocpi"
        />

        <Protected
          path="/search/charging-stations"
          component={SearchChargingStations}
          endpoint="system/search/charging-stations"
        />

        <Protected
          path="/system/roaming"
          exact
          component={ComingUpSoon}
          endpoint="system/roaming"
        />

        <Protected
          path="/system/iam"
          component={ComingUpSoon}
          endpoint="system/iam"
        />
        <Protected
          path="/system/iam/roles"
          component={ComingUpSoon}
          endpoint="system/iam/roles"
        />
        <Protected
          path="/system/sync-log"
          exact
          component={ComingUpSoon}
          endpoint="system/sync-logs"
        />
        <Protected
          path="/system/sync-log/sync-issues"
          exact
          component={ComingUpSoon}
          endpoint="system/sync-log/sync-issues"
        />
        <Protected
          exact
          path="/system/sync-log/audit-trail"
          component={ComingUpSoon}
          endpoint="system/sync-log/audit-trail"
        />
        <Protected
          exact
          path="/system/migrations/enterprise-accounts"
          component={() => (
            <EnterpriseAccountMigrations routesContext="system" />
          )}
          endpoint="system/migrations/enterprise-accounts"
        />

        <Protected exact path="/settings" component={Settings} endpoint="me" />
        <Protected
          exact
          path="/settings/*"
          component={Settings}
          endpoint="me"
        />

        <Protected
          exact
          path="/sandbox"
          component={SandboxDashboard}
          endpoint="sandbox"
        />
        <Protected
          exact
          path="/sandbox/*"
          component={SandboxDashboard}
          endpoint="sandbox"
        />
        <Protected
          exact
          path="/my-users"
          component={MyUsers}
          endpoint="users"
        />
        <Protected
          exact
          path="/sync-log"
          component={SyncLog}
          endpoint="sync-log"
        />
        <Protected
          exact
          path="/sync-log/issues"
          component={SyncLogIssues}
          endpoint="sync-log"
        />
        <Protected
          exact
          path="/sync-log/audit-trail"
          component={AuditTrail}
          endpoint="sync-log"
        />
        <Protected
          path="/credentials/ocpi"
          component={OcpiCredentials}
          endpoint="ocpi-credentials"
        />
        <Protected
          path="/credentials/tariff-notes"
          component={TariffNotes}
          endpoint="tariff-notes"
        />
        <Protected
          path="/charging-stations/evse-ids/batches/:id"
          component={BatchEvseIds}
          endpoint="evseIds"
        />
        <Protected
          exact
          path="/finance"
          component={Finance}
          endpoint="billingPlans"
        />
        <Protected
          exact
          path="/finance/roaming"
          component={FinanceRoaming}
          endpoint="billingPlans"
        />
        <Protected
          path="/finance/invoices"
          component={Invoices}
          endpoint="invoices"
        />
        <Protected
          path="/finance/invoices-event-logs"
          component={InvoicesEventLog}
          endpoint="invoices-event-logs"
        />
        <Protected
          exact
          path="/finance/billing-plans"
          component={BillingPlans}
          endpoint="billingPlans"
        />
        <Protected
          exact
          path="/finance/currencies"
          component={ProviderCurrencies}
          endpoint="providers-currencies"
        />
        <Protected
          exact
          path="/finance/account-tiers"
          component={AccountTiers}
          endpoint="account-tiers"
        />
        <Protected
          exact
          path="/finance/exports/*"
          component={FinanceExports}
          endpoint="exports"
        />
        <Protected
          exact
          path="/finance/roaming-overrides"
          component={FinanceRoamingOverrides}
          endpoint="roamingOverrides"
        />
        <Protected
          exact
          path="/finance/*"
          component={Finance}
          endpoint="billingPlans"
        />
        <Protected
          exact
          path="/cards/tokens"
          component={TokenList}
          endpoint="tokens"
        />
        <Protected
          exact
          path="/cards/tokens/:id"
          component={Tokens}
          endpoint="tokens"
        />
        <Protected
          exact
          path="/cards/tokens/:id/*"
          component={Tokens}
          endpoint="tokens"
        />
        <Protected
          exact
          path="/cards/token-batches"
          component={TokenBatches}
          endpoint="tokenBatches"
        />
        <Protected
          exact
          path="/cards/token-batches/*"
          component={TokenBatches}
          endpoint="tokenBatches"
        />
        <Protected exact path="/roles" component={Roles} endpoint="roles" />
        <Protected
          exact
          path="/charging-stations/simulator"
          component={ChargeStationSimulator}
          endpoint="credentials"
        />
        <Protected
          exact
          path="/charging-stations/background-jobs"
          component={EvseControllersBackgroundJobs}
          endpoint="evseBackgroundJobs"
        />
        <Protected
          exact
          path="/charging-stations/firmware-update"
          component={EvseControllersBackgroundJobs}
          endpoint="evseBackgroundJobs"
        />
        <Protected
          exact
          path="/charging-stations/change-configuration"
          component={EvseControllersBackgroundJobs}
          endpoint="evseBackgroundJobs"
        />
        <Protected
          exact
          path="/charging-stations/execute-command"
          component={EvseControllersBackgroundJobs}
          endpoint="evseBackgroundJobs"
        />
        <Protected
          exact
          path="/charging-stations/background-jobs/:id"
          component={EvseControllersBackgroundJobs}
          endpoint="evseBackgroundJobs"
        />
        <Protected
          exact
          path="/charging-stations/config-presets"
          component={EvseConfigPresetIndex}
          endpoint="evseConfigPresets"
        />
        <Protected
          exact
          path="/charging-stations/config-presets/create"
          component={EvseConfigPresetIndex}
          endpoint="evseConfigPresets"
        />
        <Protected
          exact
          path="/charging-stations/config-presets/:id/edit"
          component={EvseConfigPresetIndex}
          endpoint="evseConfigPresets"
        />
        <Protected
          exact
          path="/charging-stations/integrations"
          component={Integrations}
          endpoint="evseControllersIntegrations"
        />
        <Protected
          exact
          path="/charging-stations/operational-statuses"
          component={EvseOperationalStatuses}
          endpoint="evseOperationalStatuses"
        />
        <Protected
          path="/charging-stations/evse-ids"
          component={EvseIds}
          endpoint="evseIds"
        />
        <Protected
          exact
          path="/charging-stations/tariff-profiles"
          component={TariffProfiles}
          endpoint="tariffProfiles"
        />
        <Protected
          exact
          path="/charging-stations/locations/:id"
          component={Location}
          endpoint="me"
        />
        <Protected
          exact
          path="/charging-stations/locations/:id/*"
          component={Location}
          endpoint="me"
        />
        <Protected
          exact
          path="/charging-stations/sessions/:id"
          context="cpo"
          component={SessionDetail}
          endpoint="cpoSessions"
        />
        <Protected
          exact
          path="/charging-stations/sessions"
          component={SessionsCpo}
          endpoint="cpoSessions"
        />
        <Protected
          exact
          path="/charging-stations/locations"
          component={Locations}
          endpoint="cpoLocations"
        />
        <Protected
          exact
          path="/charging-stations/tokens"
          component={ExternalTokens}
          endpoint="externalTokens"
        />
        <Protected
          exact
          path="/charging-stations/tokens/*"
          component={ExternalTokens}
          endpoint="externalTokens"
        />
        <Protected
          exact
          path="/charging-stations/swarm-simulator"
          component={OcppSwarmSimulator}
          endpoint="OCPP Swarm Simulator"
        />
        <Protected
          exact
          path="/charging-stations/issues-aggregated"
          component={EvseIssuesAggregated}
          endpoint="evseControllers"
        />
        <Protected
          exact
          path="/charging-stations/connections"
          component={EvseControllersStatus}
          endpoint="evseControllers"
        />
        <Protected
          exact
          path="/charging-stations/access-groups"
          component={AccessGroups}
          endpoint="accessGroups"
        />
        <Protected
          exact
          path="/charging-stations/access-groups/:id"
          component={AccessGroup}
          endpoint="accessGroups"
        />
        <Protected
          exact
          path="/charging-stations/access-groups/:id/*"
          component={AccessGroup}
          endpoint="accessGroups"
        />
        <Protected
          exact
          path="/charging-stations/labels"
          component={EvseControllersLabels}
          endpoint="evseControllersLabels"
        />
        <Protected
          path="/charging-stations"
          component={EvseControllers}
          endpoint="evseControllers"
        />
        <Protected
          exact
          path="/providers"
          component={Providers}
          endpoint="providers"
        />
        <Protected
          exact
          path="/providers/:id"
          component={ProviderDetail}
          endpoint="providers"
        />
        <Protected
          exact
          path="/providers/:id/*"
          component={ProviderDetail}
          endpoint="providers-sensitive-configuration"
        />
        <Protected
          exact
          path="/cards/sessions"
          component={SessionsMsp}
          endpoint="sessions"
        />
        <Protected exact path="/cards/cdrs" component={Cdrs} endpoint="cdrs" />
        <Protected
          exact
          path="/cards/cdrs/:id"
          component={CdrDetail}
          endpoint="cdrs"
        />
        <Protected
          exact
          path="/cards/sessions/active"
          component={SessionsMspActive}
          endpoint="activeSessions"
        />
        <Protected
          exact
          path="/cards/sessions/import-batches"
          component={SessionsMspImportBatches}
          endpoint="sessions"
        />
        <Protected
          exact
          path="/cards/credit-sessions"
          component={SessionsMspCreditSessions}
          endpoint="creditSessions"
        />
        <Protected
          exact
          path="/cards/sessions/:id"
          context="msp"
          component={SessionDetail}
          endpoint="sessions"
        />
        <Protected
          exact
          path="/cards/sessions/:id/*"
          context="msp"
          component={SessionDetail}
          endpoint="sessions"
        />
        <Protected
          exact
          path="/sessions/:id"
          context={'cpo'}
          component={SessionDetail}
          endpoint="sessions"
        />
        <Protected
          exact
          path="/finance"
          component={Invoices}
          endpoint="invoices"
        />
        <Protected
          exact
          path="/my-map"
          component={LocationsMap}
          endpoint="me"
        />

        <Protected
          exact
          path="/cards/locations"
          component={LocationsAnalytics}
          endpoint="locations"
        />
        <Protected
          exact
          path={[
            '/cards/locations/map',
            '/cards/locations/map',
            '/cards/locations/sources',
            '/cards/locations/analytics',
          ]}
          component={LocationsAnalytics}
          endpoint="locations"
        />
        <Protected
          exact
          path="/cards/locations/:id"
          component={LocationMsp}
          endpoint="locations"
        />
        <Protected
          exact
          path="/cards/locations/:id/*"
          component={LocationMsp}
          endpoint="locations"
        />

        <Protected
          exact
          path="/my-cards/usage"
          component={MyMspUsage}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-cards/usage/*"
          component={MyMspUsage}
          endpoint="me"
        />
        <Protected exact path="/my-cards" component={MyCards} endpoint="me" />
        <Protected
          exact
          path="/my-invoices"
          component={MyInvoices}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-invoices/*"
          component={MyInvoices}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-locations/usage"
          component={MyCpoUsage}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-locations/usage/*"
          component={MyCpoUsage}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-locations/access-groups"
          component={MyAccessGroups}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-locations/access-groups/:id"
          component={MyAccessGroup}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-locations/access-groups/:id/*"
          component={MyAccessGroup}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-locations/integrations"
          component={Integrations}
          endpoint="me"
        />
        <Protected
          path="/payout-invoices"
          component={MyCreditInvoices}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-locations"
          component={MyLocations}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-locations/*"
          component={MyLocations}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-analytics/:id?"
          component={AccountAnalytics}
          endpoint="me"
        />
        <Protected
          exact
          path="/charge-station-simulator"
          component={ChargeStationSimulator}
          endpoint="credentials"
        />
        <Protected
          exact
          path="/sessions-simulator"
          component={SessionsSimulator}
          endpoint="ocpiCredentials"
        />
        <Protected
          exact
          path="/my-ocpi"
          component={MyOcpiCredentials}
          endpoint="ocpiCredentials"
        />
        <Protected
          exact
          path="/my-ocpi/:id?"
          component={MyOcpiCredential}
          endpoint="ocpiCredentials"
        />
        <Protected
          exact
          path="/my-ocpi/:id/*"
          component={MyOcpiCredential}
          endpoint="ocpiCredentials"
        />

        <Protected
          exact
          path="/my-credentials"
          component={MyCredentials}
          endpoint="credentials"
        />
        <Protected
          exact
          path="/my-credentials/:id?"
          component={MyCredential}
          endpoint="credentials"
        />
        <Protected
          exact
          path="/my-credentials/:id/*"
          component={MyCredential}
          endpoint="credentials"
        />
        <Protected
          exact
          path="/cards/providers"
          component={ExternalProviders}
          endpoint="externalProviders"
        />

        <Protected
          exact
          path="/maintenance/setup-evse"
          component={SetupEvse}
          endpoint="evseControllersMaintenance"
        />
        <Protected
          exact
          path="/maintenance/evse-controllers"
          component={EvseControllersMaintenance}
          endpoint="evseControllersMaintenance"
        />
        <Protected
          exact
          path="/maintenance/evse-controllers/issues"
          component={EvseControllersMaintenanceIssues}
          endpoint="evseControllersMaintenance"
        />
        <Protected
          exact
          path="/maintenance/analytics/:id?"
          component={MaintenanceAnalytics}
          endpoint="evseControllersMaintenance"
        />
        <Protected
          exact
          path="/maintenance"
          component={MaintainceHome}
          endpoint="evseControllersMaintenance"
        />
        <Protected
          exact
          path="/facilitator/locations"
          component={FacilitatorLocations}
          endpoint="locationsFacilitator"
        />
        <Protected
          exact
          path="/facilitator/map"
          component={LocationsFacilitatorMap}
          endpoint="locationsFacilitator"
        />
        <Protected
          exact
          path="/facilitator/analytics/:id?"
          component={FacilitatorAnalytics}
          endpoint="locationsFacilitator"
        />

        <Protected exact path="/cards" component={Cards} endpoint="cards" />
        <Protected
          exact
          path="/cards/batch-assign"
          component={CardsBatchAssign}
          endpoint="cards"
        />
        <Protected exact path="/activate" component={Activate} endpoint="me" />
        <Protected
          exact
          path={['/smart-charging', '/smart-charging/*']}
          component={SmartCharging}
          endpoint="me"
        />
        <Protected
          exact
          path="/analytics"
          component={ProviderAnalytics}
          endpoint="mspAnalytics"
        />
        <Protected
          exact
          path="/analytics/*"
          component={ProviderAnalytics}
          endpoint="mspAnalytics"
        />
        <Protected
          exact
          path="/credentials"
          component={Credentials}
          endpoint="credentials"
        />
        <Protected
          exact
          path="/credentials/:id"
          component={CredentialDetails}
          endpoint="credentials"
        />
        <Protected
          exact
          path="/credentials/:id/*"
          component={CredentialDetails}
          endpoint="credentials"
        />
        <Protected
          exact
          path="/my-map/remote-sessions/msp"
          component={MyRemoteSessionsMsp}
          endpoint="me"
        />
        <Protected
          exact
          path="/my-map/remote-sessions"
          component={MyRemoteSessions}
          endpoint="me"
        />
        <Protected
          path="/migrations/enterprise-accounts"
          component={EnterpriseAccountMigrations}
          endpoint="enterprise-account-migrations"
        />
        <Protected path="/confirm-access" component={ConfirmAccess} exact />
        <Protected
          path="/my-payment-fees"
          component={MyPaymentFees}
          endpoint="paymentFees"
        />
        <Route exact path="/logout" component={Logout} />
        <AuthSwitchRoute
          path="/login/verification"
          loggedOut={MfaVerification}
          loggedIn={() => <Redirect to="/" />}
          exact
        />
        <AuthSwitchRoute
          path="/login/verification/backup"
          loggedOut={MfaBackupVerification}
          loggedIn={() => <Redirect to="/" />}
          exact
        />
        <AuthSwitchRoute
          exact
          path="/login"
          loggedOut={Login}
          loggedIn={() => <Redirect to="/" />}
        />
        <AuthSwitchRoute
          exact
          path="/login/sso"
          loggedOut={LoginSSO}
          loggedIn={() => <Redirect to="/" />}
        />
        <PublicRoute
          exact
          path="/login/sso/exchange"
          component={LoginSSOExchange}
        />
        <PublicRoute
          exact
          path="/field-service/signup"
          component={SignupFieldService}
        />
        <PublicRoute
          exact
          path="/field-service/signup/*"
          component={SignupFieldService}
        />
        <PublicRoute exact path="/api/signup" component={SignupApi} />
        <PublicRoute exact path="/cpo/signup" component={SignupCpo} />
        <PublicRoute exact path="/cpo/signup/*" component={SignupCpo} />
        <PublicRoute
          exact
          path="/direct-payment/signup"
          component={SignupDirectPayment}
        />
        <PublicRoute
          exact
          path="/direct-payment/signup/*"
          component={SignupDirectPayment}
        />
        <PublicRoute exact path="/signup" component={SignupMsp} />
        <PublicRoute exact path="/signup/*" component={SignupMsp} />

        <PublicRoute exact path="/join" component={SignupV2} />
        <PublicRoute exact path="/join/*" component={SignupV2} />

        <PublicRoute
          exact
          path="/invites/accept/create-user"
          component={AcceptInvite}
        />
        <PublicRoute exact path="/accept-invite" component={AcceptInvite} />
        <PublicRoute exact path="/password/forgot" component={ForgotPassword} />
        <PublicRoute exact path="/password/reset" component={ResetPassword} />

        <PublicRoute exact path="/receipt" component={GetReceipt} />
        <Route path="/docs/:id?" component={Docs} />
        <Redirect path="/developer" to="/docs" />
        <Route path="/evse/:id" component={EvseLink} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Suspense>
  );
};

export default App;
