import React, { useState, useEffect } from 'react';
import PlatformModule from 'components/PlatformModule';
import { SearchFilters } from 'components';
import { useTranslation } from 'react-i18next';

import { request } from 'utils/api';
import { DropdownOption } from 'components/search/Filters/DropdownV2';
import { useFilterContext } from 'components/search/Context';

export default function AccountTierFilter() {
  const { t } = useTranslation();
  const filterContext = useFilterContext();

  const initialAccountTierFilterValue =
    filterContext.filters?.['accountTier'] || '';
  const initialAccountTierBillingPlanValue =
    filterContext.filters?.['accountTierBillingPlan'] || '';

  const [accountTier, setAccountTier] = useState<string>(
    initialAccountTierFilterValue
  );
  const [accountTierBillingPlan, setAccountTierBillingPlan] = useState<string>(
    initialAccountTierBillingPlanValue
  );

  const [accountTierOptions, setAccountTierOptions] = useState<
    DropdownOption[]
  >([]);
  const [accountTierBillingPlanOptions, setAccountTierBillingPlanOptions] =
    useState<DropdownOption[]>([]);

  useEffect(() => {
    request({
      path: '/1/account-tiers/',
      method: 'GET',
    })
      .then((response) => {
        const options = (response.data || []).map(
          (accountTier: { name: string; id: string }) => ({
            text: accountTier.name,
            value: accountTier.id,
            key: accountTier.id,
          })
        );

        setAccountTierOptions(options);
      })
      .catch((err) => {
        console.log(`Failed fetching account tiers: ${err.message}`);
        setAccountTierOptions([]);
      });
  }, []);

  useEffect(() => {
    request({
      path: '/1/billing-plans/search',
      method: 'POST',
      body: {
        type: 'account-tier',
        ...(accountTier && { accountTier }),
      },
    })
      .then((response) => {
        const options: DropdownOption[] = (response.data || []).map(
          (billingPlan: { name: string; id: string }) => ({
            text: billingPlan.name,
            value: billingPlan.id,
            key: billingPlan.id,
          })
        );

        setAccountTierBillingPlanOptions(options);

        // if selected filter is not included, clear
        if (!options.some((x) => x.value === accountTierBillingPlan)) {
          setAccountTierBillingPlan('');
        }
      })
      .catch((err) => {
        console.log(
          `Failed fetching account tier billing plans: ${err.message}`
        );
        setAccountTierBillingPlanOptions([]);
      });
  }, [accountTier]);

  // keeps the filter context in sync with the state
  useEffect(() => {
    filterContext.onFilterChange({
      name: 'accountTierBillingPlan',
      value: accountTierBillingPlan,
    });
  }, [accountTierBillingPlan]);

  const labels = {
    accountTier: t('accounts.accountTier', 'Account Tier'),
    accountTierBillingPlan: t(
      'accounts.accountTierBillingPlan',
      'Account Tier Billing Plan'
    ),
  };

  return (
    <PlatformModule moduleName="account-tiers">
      <SearchFilters.DropdownFilterV2
        label={labels.accountTier}
        name="accountTier"
        options={accountTierOptions}
        onChange={(value) => {
          setAccountTier(value);
        }}
      />
      <SearchFilters.DropdownFilterV2
        label={labels.accountTierBillingPlan}
        name="accountTierBillingPlan"
        options={accountTierBillingPlanOptions}
        onChange={(value) => {
          setAccountTierBillingPlan(value);
        }}
      />
    </PlatformModule>
  );
}
