import React, { useEffect } from 'react';
import { Modal, Table, Segment, Message, Progress, Label } from 'semantic';
import { request } from 'utils/api';
import AsyncModal from 'helpers/async-modal';
import { decodeBase64IfNeeded } from 'utils/signedmeter';

function ViewSessionCalibration(props) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [verification, setVerification] = React.useState(null);

  async function fetchVerification() {
    try {
      const { data: verification } = await request({
        method: 'POST',
        path: '/1/ocpp/calibration/verify',
        body: props.data.rawRecord?.signedData,
      });
      setVerification(verification);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setTimeout(() => fetchVerification(), 3000);
  });

  function renderVerification({
    verificationAvailable,
    verification,
    timestamp,
    value,
    uid,
  }) {
    if (!verificationAvailable) {
      return (
        <Message
          info
          icon="circle-info"
          content="Automatic verification for this session is not available, please use SAFE tool to manually verify this session"
        />
      );
    }
    if (!verification) {
      return (
        <Message error content="Verification failed for this signed session" />
      );
    }
    return (
      <Message
        success
        icon="check"
        content={`Session with meter stop value of ${value} at ${new Date(
          timestamp
        ).toISOString()} for UID ${uid} successfully verified`}
      />
    );
  }

  const { signedData } = props.data?.rawRecord || {};
  return (
    <>
      <Modal.Header>Session Verification</Modal.Header>
      <Modal.Content>
        <p>
          This session has calibration signatures which allow you to verify the
          authenticity of the session against the hardware's energy meter. You
          can use independent tools such as{' '}
          <a href="https://safe-ev.de/de/">SAFE EV</a> to verification.
        </p>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ width: '200px' }}>
                Vendor Implementation
              </Table.Cell>
              <Table.Cell>
                <Label content={signedData.vendor.toUpperCase()} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ width: '200px' }}>
                Encoding Format
              </Table.Cell>
              <Table.Cell>
                <Label
                  content={(
                    signedData.encodingMethod ||
                    signedData.vendor ||
                    'Custom'
                  ).toUpperCase()}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ width: '200px' }}>Public Key</Table.Cell>
              <Table.Cell>
                <Segment
                  inverted
                  style={{ width: '600px', wordWrap: 'break-word' }}>
                  {signedData.publicKey}
                </Segment>
              </Table.Cell>
            </Table.Row>
            {signedData.dataset && (
              <Table.Row>
                <Table.Cell>Data Set</Table.Cell>
                <Table.Cell>
                  <Segment
                    inverted
                    style={{ width: '600px', wordWrap: 'break-word' }}>
                    {signedData.dataset}
                  </Segment>
                </Table.Cell>
              </Table.Row>
            )}
            {signedData.signedMeterData && (
              <Table.Row>
                <Table.Cell>Signed Meter Data</Table.Cell>
                <Table.Cell>
                  <Segment
                    inverted
                    style={{ width: '600px', wordWrap: 'break-word' }}>
                    {decodeBase64IfNeeded(signedData)}
                  </Segment>
                </Table.Cell>
              </Table.Row>
            )}
            {signedData.signature && (
              <Table.Row>
                <Table.Cell>Signature</Table.Cell>
                <Table.Cell>
                  <Segment
                    inverted
                    style={{ width: '600px', wordWrap: 'break-word' }}>
                    {signedData.signature}
                  </Segment>
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>Verification</Table.Cell>
              <Table.Cell>
                {error && <Message error content={error.message} />}
                {loading && (
                  <Progress
                    label="Verifying cryptographic signature"
                    percent={100}
                    active
                  />
                )}
                {verification && renderVerification(verification)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </>
  );
}

export default AsyncModal(ViewSessionCalibration);
