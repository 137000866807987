import React, { useEffect, useState } from 'react';
import { Button, Form, Message, Modal } from 'semantic';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { request, joiErrorDetailsToObject } from 'utils/api';
import { Formik, FormikHelpers } from 'formik';
import SelectField from 'components/form-fields/formik/SelectField';
import { useUser } from 'contexts/user';
import { BillingPlan } from 'types/billingplan';
import { Group } from 'components/Layout/helpers';
import { usePlatformModules } from 'contexts/platformModules';
import { BillingPlanFormUserCard } from './BillingPlanFormConsumerCard';
import { BillingPlanFormChargingStation } from './BillingPlanFormChargingStation';
import { BillingPlanFormAccountTier } from './BillingPlanFormAccountTier';
import useAccountTierBillingPlanDefaults from './useAccountTierBillingPlanDefaults';

function sanatizeConsumerCardBillingPlan(
  plan: BillingPlan & { usingBillingPlanFeesPercentage?: boolean }
): BillingPlan {
  const cleanPlan = plan;

  cleanPlan.providerContext = 'msp';

  const prices = cleanPlan.prices.map((price) => {
    price.perDevice = 0;
    price.perKwhPercentage = undefined;
    return price;
  });

  cleanPlan.prices = prices;
  cleanPlan.usingBillingPlanFeesPercentage = undefined;

  return cleanPlan;
}

function sanatizeStationBillingPlan(
  plan: BillingPlan & { usingBillingPlanFeesPercentage?: boolean }
): BillingPlan {
  const cleanPlan = plan;

  cleanPlan.providerContext = 'cpo';

  const prices = cleanPlan.prices.map((price) => {
    price.perCard = 0;
    price.perSession = 0;

    if (plan.usingBillingPlanFeesPercentage) {
      price.perKwhFixed = 0;
    } else {
      price.perKwhPercentage = 0;
    }

    return price;
  });

  cleanPlan.usingBillingPlanFeesPercentage = undefined;

  cleanPlan.prices = prices;

  return cleanPlan;
}

function sanatizeAccountTierBillingPlan(
  plan: BillingPlan & { usingBillingPlanFeesPercentage?: boolean }
): BillingPlan {
  const cleanPlan = plan;

  cleanPlan.providerContext = 'cpo';

  // For now we don't have additional fees for accountTier billing plans
  const prices = cleanPlan.prices.map((price) => {
    price.perCard = 0;
    price.perSession = 0;
    price.perKwhFixed = undefined;
    price.perKwhPercentage = undefined;

    return price;
  });
  cleanPlan.prices = prices;

  cleanPlan.usingBillingPlanFeesPercentage = undefined;

  return plan;
}

interface ProviderCurrency {
  currency: string;
  active: boolean;
}

interface Props {
  close(): void;
  onSave(): void;
  onSubmit(billingPlan: BillingPlan): Promise<void>;
  billingPlan: Partial<BillingPlan>;
}

// TODO: consider having separate tabs and forms for CPO/MSP billing plans.
export const BillingPlanFormV2 = ({
  billingPlan = {
    billingPeriod: 'month',
    type: 'account-tier',
    isPublic: false,
    priority: 0,
    providerContext: 'cpo',
    id: '',
    name: '',
    prices: [
      {
        currency: 'EUR',
        perPeriod: 0,
        perDevice: 0,
        perSession: 0,
        perCard: 0,
        perKwhPercentage: 0,
        perKwhFixed: 0,
      },
    ],
    details: {
      en: {
        name: '',
        descriptionLong: '',
        descriptionShort: '',
        features: [],
      },
    },
  },
  close,
  onSave,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const { provider } = useUser();

  const isUpdate = !!billingPlan?.id;

  if (billingPlan.prices?.length) {
    // @ts-ignore This is not part of the spec but I'm using it to internalize inital values for the toggle here
    billingPlan.usingBillingPlanFeesPercentage =
      (billingPlan.prices[0]?.perKwhPercentage ?? 0) > 0;
  }

  const { hasPlatformModule } = usePlatformModules();
  const isAccountTiersEnabled = hasPlatformModule('account-tiers');

  const [currenciesInUse, setCurrenciesInUse] = useState<string[]>([]);
  const [currencies, setCurrencies] = useState<ProviderCurrency[]>([]);
  const [useATBPDefaults, setUseATBPDefaults] = useState(false);
  const defaultATBPValues = useAccountTierBillingPlanDefaults();

  const fetchCurrencies = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/providers/${provider!.id}/currencies`,
    });
    if (Array.isArray(data)) {
      setCurrencies(data);
    }
  };

  const fetchCurrenciesInUse = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/billing-plans/${billingPlan.id}/currencies-in-use`,
    });
    if (Array.isArray(data)) {
      setCurrenciesInUse(data);
    }
  };

  useEffect(() => {
    if (billingPlan.id) fetchCurrenciesInUse();
  }, [billingPlan]);

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const submitHandler = async (
    formValues: BillingPlan & { usingBillingPlanFeesPercentage?: boolean },
    formikBag: FormikHelpers<BillingPlan>
  ) => {
    let body = formValues;
    if (formValues.type === 'consumer') {
      body = sanatizeConsumerCardBillingPlan(formValues);
    } else if (formValues.type === 'station') {
      body = sanatizeStationBillingPlan(formValues);
    } else if (formValues.type === 'account-tier') {
      body = sanatizeAccountTierBillingPlan(formValues);
    }

    try {
      await onSubmit(body);
      close();
      onSave();
    } catch (error: any) {
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      } else {
        formikBag.setStatus(error?.message);
      }
    }
  };

  const periodOptions = [
    {
      key: 'month',
      value: 'month',
      text: t('editBillingPlan.billingPeriodMonth', 'month'),
    },
    {
      key: 'year',
      value: 'year',
      text: t('editBillingPlan.billingPeriodYear', 'year'),
    },
  ];

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        initialTouched={{
          type: true,
          billingPeriod: true,
          priority: true,
          prices: [],
        }}
        onSubmit={(formValues, formikBag) =>
          submitHandler(
            formValues as BillingPlan,
            formikBag as FormikHelpers<BillingPlan>
          )
        }
        validationSchema={Yup.object({
          providerContext: Yup.string().oneOf(['cpo', 'msp']).required(),
          billingPeriod: Yup.string().oneOf(['month', 'year']).required(),
          priority: Yup.number().min(0).required(),
          type: Yup.string().oneOf(['consumer', 'station', 'account-tier']),
          accountTierId: Yup.string().when('type', {
            is: 'account-tier',
            then: (schema) =>
              schema.required(
                t(
                  'editBillingPlan.accountTierRequired',
                  'Account Tier is required'
                )
              ),
          }),
          prices: Yup.array().of(
            Yup.object({
              currency: Yup.string().required(),
              perPeriod: Yup.number().min(0).required(),
              perDevice: Yup.number().min(0).default(0),
              perCard: Yup.number().min(0).default(0),
              perSession: Yup.number().min(0).default(0),
              perKwhPercentage: Yup.number().min(0).max(50),
              perKwhFixed: Yup.number().min(0),
            })
          ),
          details: Yup.object({
            en: Yup.object({
              name: Yup.string().required(),
            }),
          }),
        })}
        initialValues={{
          ...billingPlan,
          // TODO: below is a nasty fix that hides a data problem.
          // Currently, CPO billing plans all have an incorrect type of 'consumer'.
          // This will read provider context and overwrite the value in the UI.
          // However, a migration script is needed and this fix should be removed.
          ...(billingPlan.providerContext === 'cpo' &&
          billingPlan.type !== 'account-tier'
            ? { type: 'station' }
            : {}),

          ...(useATBPDefaults ? defaultATBPValues : {}),
        }}>
        {({ status, values, isSubmitting, isValid, dirty, handleSubmit }) => {
          return (
            <>
              <Modal.Header role={'heading'}>
                {isUpdate
                  ? t(
                      'editBillingPlan.titleEdit',
                      'Edit Billing Plan {{name}}',
                      {
                        name: billingPlan.name,
                      }
                    )
                  : t('editBillingPlan.titleNew', 'New Billing Plan')}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <Group align="flex-start" grow spacing={'xs'}>
                    <SelectField
                      required
                      style={{
                        display: 'flex',
                      }}
                      label="Type"
                      name="type"
                      options={[
                        ...(isAccountTiersEnabled
                          ? [
                              {
                                text: t(
                                  'billingPlan.accountTierType',
                                  'Account Tier'
                                ),
                                value: 'account-tier',
                                key: 'account-tier',
                              },
                            ]
                          : []),
                        {
                          text: t('billingPlan.consumerType', 'Consumer Card'),
                          value: 'consumer',
                          key: 'consumer',
                        },
                        {
                          text: t(
                            'billingPlan.chargingStationType',
                            'Charging Station'
                          ),
                          value: 'station',
                          key: 'station',
                        },
                      ]}
                    />

                    <SelectField
                      label={t(
                        'editBillingPlan.billingPeriod',
                        'Billing Period'
                      )}
                      required
                      name="billingPeriod"
                      options={periodOptions}
                    />
                  </Group>

                  {values.type === 'consumer' && (
                    <BillingPlanFormUserCard
                      currenciesInUse={currenciesInUse}
                      billingPlan={
                        values as BillingPlan & {
                          usingBillingPlanFeesPercentage?: boolean;
                        }
                      }
                      currencies={currencies}
                    />
                  )}
                  {values.type === 'station' && (
                    <BillingPlanFormChargingStation
                      currenciesInUse={currenciesInUse}
                      billingPlan={
                        values as BillingPlan & {
                          usingBillingPlanFeesPercentage?: boolean;
                        }
                      }
                      currencies={currencies}
                    />
                  )}
                  {values.type === 'account-tier' && (
                    <BillingPlanFormAccountTier
                      useDefaults={useATBPDefaults}
                      setUseDefaults={setUseATBPDefaults}
                      currenciesInUse={currenciesInUse}
                      billingPlan={
                        values as BillingPlan & {
                          usingBillingPlanFeesPercentage?: boolean;
                        }
                      }
                      currencies={currencies}
                    />
                  )}
                  {status && (
                    <Message error>
                      <p>{status}</p>
                    </Message>
                  )}
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="button"
                  type="submit"
                  loading={isSubmitting}
                  role="button"
                  aria-label={
                    isUpdate ? t('button.update') : t('button.create')
                  }
                  primary
                  disabled={isSubmitting || !isValid || !dirty}
                  content={isUpdate ? t('button.update') : t('button.create')}
                  onClick={handleSubmit}
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default BillingPlanFormV2;
