import { useUser } from 'contexts/user';
import useFetch from 'hooks/useFetch';
import { size, upperCase } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Icon } from 'semantic';
import { Button, Card, SemanticCOLORS } from 'semantic';

import { EvseIssue } from 'types/evse-issue';
import { request } from 'utils/api';
import {
  colorForSeverity,
  useIssueTypeDetectedText,
  useEvseIssueSeverityText,
  useEvseIssueDescription,
} from 'utils/evse-issues';
import { userCanAccessProviderEndpoint } from 'utils/roles';

type Props = {
  evseControllerId: string;
};

const COLLAPSE_NUMBER = 2;

export default function EvseIssues(props: Props) {
  const { user, provider } = useUser();
  const [accordionOpen, setAccordionOpen] = useState(false);
  const { t } = useTranslation();

  const canAccessEvseControllersRead =
    user &&
    userCanAccessProviderEndpoint(user, provider, 'evseControllers', 'read');

  const { data, error, refresh } = useFetch<EvseIssue[]>({
    method: 'POST',
    path: canAccessEvseControllersRead
      ? `/1/evse-issues/search`
      : `/1/evse-issues/maintenance/search`,
    body: {
      evseControllerId: props.evseControllerId,
    },
  });

  if (!error) {
    console.error(error);
  }

  const resolve = async (item: EvseIssue) => {
    return request({
      method: 'POST',
      path: canAccessEvseControllersRead
        ? `/1/evse-issues/${item.id}/resolve`
        : `/1/evse-issues/maintenance/${item.id}/resolve`,
    }).then(() => refresh());
  };

  if (!data) return <span />;

  const issuesExpanded =
    size(data) > COLLAPSE_NUMBER ? data?.slice(0, COLLAPSE_NUMBER) : data;
  const issuesCollapsed =
    size(data) > COLLAPSE_NUMBER ? data?.slice(COLLAPSE_NUMBER) : [];

  return (
    <div>
      {size(issuesExpanded) > 0 &&
        issuesExpanded.map((evseIssue) => (
          <EvseIssueComponent
            key={evseIssue.id}
            evseIssue={evseIssue}
            resolve={resolve}
          />
        ))}
      {size(issuesCollapsed) > 0 && (
        <div style={{ paddingBottom: 20 }}>
          <Accordion>
            <Accordion.Title onClick={() => setAccordionOpen(!accordionOpen)}>
              <p
                style={{
                  color: 'grey',
                  fontWeight: 500,
                  fontSize: 12,
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 10,
                }}>
                {accordionOpen ? (
                  <Icon name="angle-down" />
                ) : (
                  <Icon name="angle-right" />
                )}
                {upperCase(t('evseIssues.seeMoreIssues', 'See more issues'))}
              </p>
            </Accordion.Title>
            <Accordion.Content active={accordionOpen}>
              {issuesCollapsed.map((evseIssue) => (
                <EvseIssueComponent
                  key={evseIssue.id}
                  evseIssue={evseIssue}
                  resolve={resolve}
                />
              ))}
            </Accordion.Content>
          </Accordion>
        </div>
      )}
    </div>
  );
}

function EvseIssueComponent({
  evseIssue,
  resolve,
}: {
  evseIssue: EvseIssue;
  resolve: (evseIssue: EvseIssue) => void;
}) {
  const header = useIssueTypeDetectedText(evseIssue.type);
  const severityText = useEvseIssueSeverityText(evseIssue);
  const description = useEvseIssueDescription(evseIssue);
  return (
    <Card
      key={evseIssue.id}
      fluid
      color={colorForSeverity(evseIssue.severity) as SemanticCOLORS}
      style={{ marginBottom: '1.5rem' }}>
      <Card.Content>
        <Card.Header>{header}</Card.Header>
        <Card.Meta>{severityText}</Card.Meta>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          basic
          icon="check"
          content="Mark as Resolved"
          onClick={() => resolve(evseIssue)}
        />
      </Card.Content>
    </Card>
  );
}
