import React, { useCallback } from 'react';

import { Link } from 'react-router-dom';
import { Form } from 'semantic';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ErrorMessage } from 'components';
import useFetch from 'hooks/useFetch';
import { EvseConfigPreset } from 'types/evse-config-preset';

const StyledLink = styled(Link)`
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
`;

type Props = {
  // when a preset is selected, the onChange function is called with the selected preset
  // if preset is removed, the onChange function is called with undefined
  onChange: (preset?: EvseConfigPreset) => void;
};

export default function SelectPreset({ onChange }: Props) {
  const { t } = useTranslation();

  const {
    data: evseConfigPresets,
    loading: evseConfigPresetsLoading,
    error: evseConfigPresetsError,
  } = useFetch<EvseConfigPreset[]>({
    method: 'GET',
    path: '/1/evse-config-presets',
  });

  const evseConfigPresetsOptions = [
    ...(evseConfigPresets?.map((preset) => ({
      key: preset.id,
      value: preset.id,
      text: preset.name,
    })) || []),
  ];

  const handleOnChange = useCallback(
    (value?: string) => {
      if (onChange) {
        const selectedPreset = evseConfigPresets?.find(
          (preset) => preset.id === value
        );

        onChange(selectedPreset);
      }
    },
    [evseConfigPresets, onChange]
  );

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ flexGrow: 1, maxWidth: 450 }}>
          <Form.Dropdown
            forceSelection={false}
            selectOnBlur={false}
            clearable
            selection
            label={t(
              'evseControllersBackgroundJobsChangeConfiguration.selectEvseConfigPresetsLabel',
              'Select Configuration Preset'
            )}
            placeholder={
              evseConfigPresetsLoading
                ? t(
                    'evseControllersBackgroundJobsChangeConfiguration.loadingEvseConfigPresets',
                    'Loading...'
                  )
                : t(
                    'evseControllersBackgroundJobsChangeConfiguration.selectEvseConfigPresetsPlaceholder',
                    'Select Preset'
                  )
            }
            options={evseConfigPresetsOptions}
            onChange={(e, { value }) => handleOnChange(value as string)}
            disabled={evseConfigPresetsLoading}
          />
        </div>
        <div style={{ paddingLeft: 10, paddingBottom: 10 }}>
          <StyledLink to="/charging-stations/config-presets" target="_blank">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.showAllPresets',
              'Show all presets'
            )}
          </StyledLink>
        </div>
      </div>
      <ErrorMessage error={evseConfigPresetsError} />
    </>
  );
}
