import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { capitalize, isArray } from 'lodash-es';
import {
  Segment,
  Divider,
  Container,
  Table,
  Progress,
  Label,
  Button,
} from 'semantic';
import { request } from 'utils/api';
import styles from './ChargingStations.module.css';

import {
  Breadcrumbs,
  Layout,
  Search,
  SearchFilters,
  ListHeader,
  FeatureFlag,
} from 'components';

import { useTranslation } from 'react-i18next';
import { getSetupProgress } from 'utils/evse-controllers';
import AppWrapper from 'components/AppWrapper';
import { EvseControllerEvseId } from 'components/EvseId';
import { ConnectivityStatus } from 'components/ConnectivityStatus';
import { API_URL } from 'utils/env';
import { useUser } from 'contexts/user';
import { useFeatures, FeatureFlags } from 'contexts/features';
import {
  EvseOperationalStatus,
  EvseOperationalStatusType,
} from 'types/evse-operational-status';
import { OperationalStatus } from 'components/OperationalStatus';
import { evseOperationalStatusNameTranslation } from 'utils/evse-operational-status';

function EvseControllersTable() {
  const { user } = useUser();
  const { items, loading } = useContext(Search.Context);
  const { hasFeature } = useFeatures();
  const { t } = useTranslation();
  const hasOperationalStatusesPageFeature = hasFeature(
    FeatureFlags.OperationalStatusesPage
  );

  if (!items.length || loading) return null;

  const userHasGlobalEVSEReadPermission =
    user?.globalRoles?.some(
      (globalRole) =>
        globalRole?.role?.permissions['evseControllers'].indexOf('read') !== -1
    ) || user.roles.includes('super_admin');

  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>
            {t('evseControllers.columnOCPP_ID', 'OCPP ID')}
          </Table.HeaderCell>
          <Table.HeaderCell width={3}>
            {t('evseControllers.location', 'Location')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {hasOperationalStatusesPageFeature
              ? t('evseOperationalStatus.title', 'Operational Status')
              : t('evseControllers.columnSetup', 'Setup')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('evseControllers.columnConnectivity', 'Connectivity')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('evseControllers.columnActions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item) => {
          const setupProgress = getSetupProgress(item);

          const hasOperationalStatus =
            hasOperationalStatusesPageFeature && item.evseOperationalStatus;

          return (
            <Table.Row key={item.id}>
              <Table.Cell>
                <p>
                  <small>OCPP Identity: {item?.ocppIdentity} </small> <br />
                  <small>
                    Evse ID: <EvseControllerEvseId evseController={item} />{' '}
                  </small>{' '}
                  <br />
                  <small>
                    {t('evseController.serialNumber', 'Serial Number: ')}
                    {item.serialNumber || '-'}
                  </small>
                </p>
              </Table.Cell>
              <Table.Cell>
                {item?.location?.name ? (
                  <p>
                    <small>
                      <strong>{item?.location?.name}</strong>{' '}
                    </small>{' '}
                    <br />
                    <small>
                      Address: {item?.location?.address},{' '}
                      {item?.location?.postcode}{' '}
                    </small>{' '}
                    <br />
                    <small>City: {item?.location?.city} </small> <br />
                  </p>
                ) : (
                  '-'
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {hasOperationalStatus ? (
                  <OperationalStatus
                    evseOperationalStatus={item.evseOperationalStatus}
                  />
                ) : setupProgress.completed ? (
                  <Progress percent={100} label="Completed" size="tiny" />
                ) : setupProgress.readyForActivation ? (
                  <Label
                    color="blue"
                    content="Activation Pending"
                    title="This EVSE is ready to be activated by the customer or linked by admin"
                  />
                ) : (
                  <Progress
                    percent={setupProgress.percent}
                    label="Setup Progress"
                    size="tiny"
                  />
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <ConnectivityStatus item={item} />
              </Table.Cell>
              <Table.Cell textAlign="center">
                {userHasGlobalEVSEReadPermission && (
                  <Button
                    as="a"
                    basic
                    className={styles.cta}
                    rel="noopener"
                    target="_blank"
                    href={`/charging-stations/${item.id}?&providerId=${item.providerId}`}>
                    {t('system.search.chargestation.open', 'Open')}
                  </Button>
                )}
                {!userHasGlobalEVSEReadPermission && (
                  <Button
                    as="a"
                    basic
                    className={styles.cta}
                    rel="noopener"
                    target="_blank"
                    href={`${API_URL}/1/evse-controllers/${item.id}/direct`}>
                    {t('system.search.chargestation.openPortal', 'via Portal')}
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default () => {
  const { t } = useTranslation();
  const searchRef = useRef();

  const onDataNeeded = useCallback(async (filters) => {
    return request({
      method: 'POST',
      path: `/1/system/search/charging-stations`,
      body: filters,
    });
  }, []);

  const filterMapping = useMemo(() => {
    return {
      searchPhrase: {
        type: 'search',
      },
      hasNoAccount: {
        label: t('evseControllerFilter.hasNoAccount', 'Without account'),
        type: 'boolean',
      },
      readyForActivation: {
        label: t(
          'evseControllerFilter.readyForActivation',
          'Ready for activation'
        ),
        type: 'boolean',
      },
      connectivityState: {
        type: 'select',
        label: t('evseControllerFilter.connectivityState', 'Conection State'),
      },
      setupInProgress: {
        type: 'boolean',
        label: t(
          'evseControllerFilter.setupInProgress',
          'Show only "setup in progress"'
        ),
      },
      createdAt: {
        label: t('common.createdAt'),
        type: 'date',
        range: true,
      },
      evseOperationalStatusIds: {
        label: t(
          'evseControllerFilter.operationalStatus',
          'Operational Status'
        ),
        type: 'select',
        multiple: true,
      },
    };
  }, [t]);

  const labels = useMemo(
    () => ({
      hasNoAccount: t('evseControllerFilter.hasNoAccount', 'Without account'),
      readyForActivation: t(
        'evseControllerFilter.readyForActivation',
        'Ready for activation'
      ),
      connectivityState: t(
        'evseControllerFilter.connectivityState',
        'Conection State'
      ),
      setupInProgress: t(
        'evseControllerFilter.setupInProgress',
        'Show only "setup in progress"'
      ),
      createdAt: t('common.createdAt'),
    }),
    [t]
  );

  return (
    <AppWrapper>
      <Container>
        <Search.Provider
          ref={searchRef}
          onDataNeeded={onDataNeeded}
          filterMapping={filterMapping}
          limit={20}>
          {() => {
            return (
              <>
                <Breadcrumbs
                  active={t('evseControllers.header', 'Charging Stations')}
                />
                <ListHeader
                  title={t(
                    'evseControllers.header',
                    'Charging Stations'
                  )}></ListHeader>
                <Segment>
                  <Layout horizontal spread stackable center>
                    <SearchFilters.ModalFilterV2 useAutoFocus={false}>
                      <FeatureFlag
                        feature={FeatureFlags.OperationalStatusesPage}>
                        <SearchFilters.DropdownSearchFilterV2
                          label={t(
                            'evseControllerFilter.operationalStatus',
                            'Operational Status'
                          )}
                          name="evseOperationalStatusIds"
                          multiple={true}
                          populateOnLoad={true}
                          onDataNeeded={async () => {
                            const response = await request({
                              method: 'GET',
                              path: '/1/evse-operational-statuses',
                            });
                            if (!isArray(response?.data)) {
                              return response;
                            }

                            const data = response.data.map(
                              (status: EvseOperationalStatus) => {
                                if (
                                  status.type ===
                                  EvseOperationalStatusType.System
                                ) {
                                  return {
                                    ...status,
                                    name: capitalize(
                                      evseOperationalStatusNameTranslation(
                                        t,
                                        status
                                      )
                                    ),
                                  };
                                }

                                return status;
                              }
                            );

                            return { ...response, data };
                          }}
                        />
                      </FeatureFlag>
                      <SearchFilters.Checkbox
                        label={labels.hasNoAccount}
                        name="hasNoAccount"
                        toggle
                      />
                      <SearchFilters.Checkbox
                        label={labels.readyForActivation}
                        name="readyForActivation"
                        toggle
                      />
                      <SearchFilters.Checkbox
                        label={labels.setupInProgress}
                        name="setupInProgress"
                        toggle
                      />
                      <SearchFilters.Dropdown
                        label={labels.connectivityState}
                        name="connectivityState"
                        options={[
                          {
                            text: 'connected',
                            value: 'connected',
                          },
                          {
                            text: 'maybe-connected',
                            value: 'maybe-connected',
                          },
                          {
                            text: 'disconnected',
                            value: 'disconnected',
                          },
                          {
                            text: 'access-denied',
                            value: 'access-denied',
                          },
                          {
                            text: 'pending-first-connection',
                            value: 'pending-first-connection',
                          },
                        ]}
                      />
                      <Divider />
                      <SearchFilters.DateRange
                        label={labels.createdAt}
                        name="createdAt"
                      />
                    </SearchFilters.ModalFilterV2>

                    <Layout horizontal stackable center right>
                      <Search.Total />
                      <SearchFilters.Search
                        name="searchPhrase"
                        placeholder={t(
                          'evseControllers.filterPlaceHolder',
                          'Location or ID'
                        )}
                      />
                    </Layout>
                  </Layout>
                </Segment>
                <Search.Status
                  noResults={t('common.noResults', 'No Results')}
                />
                <Divider hidden />
                <div
                  style={{
                    textAlign: 'center',
                  }}>
                  <EvseControllersTable />
                  <Search.Pagination />
                </div>
              </>
            );
          }}
        </Search.Provider>
      </Container>
    </AppWrapper>
  );
};
