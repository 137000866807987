import { providerIsCpoOnly } from 'utils/providers';
import {
  canAccess,
  canAccessProviderEndpoint,
  hasGlobalRoles,
  hasProviderRoles,
  hasSystemRoles,
  isSuperAdmin,
} from './roles';
import { FeatureFlags } from 'contexts/features';
import { systemMenuDefinitions } from './navigationSystemMenu';

import { isSandboxMode } from './sandbox';
import {
  EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_PERMISSIONS_READ,
} from 'screens/EvseControllersBackgroundJobs/utils';

import {
  EVSE_OPERATIONAL_STATUSES_FE_PATH,
  EVSE_OPERATIONAL_STATUSES_PERMISSIONS_READ,
} from 'screens/EvseOperationalStatuses/utils';
import { EVSE_CONFIG_PRESET_PERMISSIONS } from 'screens/EvseConfigPreset/utils';

export const menuDefinitions = [
  ...systemMenuDefinitions,
  {
    id: 'providerConfiguration',
    title: (t) => t('menu.manage provider', 'Manage provider'),
    items: [
      {
        content: (t) => t('menu.manageProviderSocialLogin', 'Social login'),
        icon: 'users',
        to: (provider) => `/providers/${provider.id}/social-login`,
        providedByModule: 'social-login',
        endpoint: 'providers-sensitive-configuration',
        providerOrGlobalRolesOnly: true,
      },
    ],
  },
  {
    id: 'provider',
    title: (t) => t('menu.providerMenu', 'Provider Menu'),
    items: [
      {
        content: (t) => t('menu.sandboxDashboard', 'Sandbox'),
        icon: 'code',
        hidden: isSandboxMode() === false,
        to: '/sandbox',
        endpoint: 'sandbox',
        providerOrGlobalRolesOnly: true,
        slug: 'sandbox',
        subItems: [
          {
            content: (t) => t('menu.sandboxCpo', 'CPO'),
            icon: 'code',
            to: '/sandbox/cpo',
            endpoint: 'sandbox',
            slug: 'sandbox-cpo',
          },
          {
            content: (t) => t('menu.sandboxMsp', 'MSP'),
            icon: 'code',
            to: '/sandbox/msp',
            endpoint: 'sandbox',
            slug: 'sandbox-msp',
          },
        ],
      },
      {
        content: (t) => t('menu.account', 'Accounts'),
        description: 'Manage organisation and individual accounts',
        icon: 'user-tie',
        to: '/accounts',
        endpoint: 'accounts',
        slug: 'accounts',
        providerOrGlobalRolesOnly: true,
        subItems: [
          {
            content: (t) => t('menu.users', 'Users'),
            icon: 'user',
            to: '/accounts/users',
            description: 'Manage users linked to an account',
            slug: 'users',
            endpoint: 'users',
            providerOrGlobalRolesOnly: true,
            providerRolesPrimary: true,
          },
        ],
      },
      {
        content: (t) => t('menu.finance', 'Finance'),
        icon: 'coins',
        to: '/finance',
        endpoint: 'billingPlans',
        providerOrGlobalRolesOnly: true,
        slug: 'finance',
        subItems: [
          {
            content: (t) => t('menu.invoices', 'Invoices'),
            description: 'An overview of invoices for various billing runs',
            icon: 'file-invoice',
            to: '/finance/invoices',
            slug: 'invoices',
            endpoint: 'invoices',
            providerOrGlobalRolesOnly: true,
            requireInvoicing: true,
          },
          {
            content: (t) => t('menu.invoicesEventLog', 'Invoices Event Log'),
            icon: 'magnifying-glass',
            to: '/finance/invoices-event-logs',
            slug: 'invoices-event-logs',
            endpoint: 'invoices-event-logs',
            requiresFeatureFlag: 'billing_and_settlement_invoice_event_logs',
            requireSuperAdmin: true,
          },
          {
            content: (t) => t('menu.roaming', 'Roaming'),
            icon: 'globe',
            to: '/finance/roaming',
            slug: 'roaming',
            endpoint: 'invoices',
            efluxOnly: true,
          },
          {
            content: (t) => t('menu.billingPlans', 'Billing Plans'),
            description:
              'Manage pricing plans for MSP, CPO and Account Tier packages',
            icon: 'user-tag',
            to: '/finance/billing-plans',
            slug: 'billing-plans',
            endpoint: 'billingPlans',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.currencies', 'Currencies'),
            icon: 'money-bill-1',
            to: '/finance/currencies',
            slug: 'currencies',
            endpoint: 'providers-currencies',
            requiresFeatureFlag: 'multi_currency_support',
            providedByModule: 'multi-currency',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.accountTiers', 'Account Tiers'),
            icon: 'cubes',
            to: '/finance/account-tiers',
            slug: 'account-tiers',
            endpoint: 'account-tiers',
            providedByModule: 'account-tiers',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.exchangeRates', 'Exchange Rates'),
            icon: 'chart-line',
            to: '/system/finance/exchange-rates',
            slug: 'exchange-rates',
            endpoint: 'system/finance/exchangeRates',
          },
          {
            content: (t) => t('menu.financeExports', 'Data Collections'),
            icon: 'database',
            to: '/finance/exports/statistics',
            slug: 'finance-exports',
            endpoint: 'financeExports',
          },
          {
            content: (t) =>
              t('menu.roamingCpoCostOverride', 'Roaming settings'),
            icon: 'globe',
            to: '/finance/roaming-overrides',
            slug: 'roaming-overrides',
            endpoint: 'roamingOverrides',
            efluxOnly: true,
            requireSuperAdmin: true,
          },
        ],
      },
      {
        content: (t) => t('menu.cpoChargeStations', 'Charging Stations'),
        icon: 'charging-station',
        to: '/charging-stations',
        slug: 'charging-stations',
        endpoint: 'evseControllers',
        providerOrGlobalRolesOnly: true,
        isActive: (_, location) => {
          const paths = [
            '/charging-stations',
            EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH,
            EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH,
            EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH,
          ];

          return paths.indexOf(location.pathname) > -1;
        },
        subItems: [
          {
            content: (t) => t('menu.cpoSessions', 'Sessions'),
            description: 'Overview of CPO sessions',
            icon: 'bars-staggered',
            to: '/charging-stations/sessions',
            slug: 'cpo-sessions',
            endpoint: 'cpoSessions',
            providerOrGlobalRolesOnly: true,
            promoteToPrimaryWithoutEndpoint: 'sessions',
          },
          {
            content: (t) => t('menu.cpoTokens', 'Tokens'),
            description: 'Manage external CPO tokens',
            icon: 'tag',
            to: '/charging-stations/tokens',
            slug: 'external-tokens',
            endpoint: 'externalTokens',
            providerOrGlobalRolesOnly: true,
            efluxOnly: true,
          },
          {
            content: (t) => t('menu.cpoLocations', 'Locations'),
            description: 'An overview of CPO locations',
            icon: 'location-dot',
            to: '/charging-stations/locations',
            slug: 'cpo-locations',
            endpoint: 'cpoLocations',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) =>
              t('menu.evseControllersBackgroundJobs', 'Background Jobs'),
            icon: 'box',
            to: '/charging-stations/background-jobs',
            slug: 'background-jobs',
            endpoint: EVSE_CONTROLLERS_BACKGROUND_JOBS_PERMISSIONS_READ,
            providerOrGlobalRolesOnly: true,
            providedByModule: 'bulk-management',
          },
          {
            content: (t) => t('menu.evseConfigPresets', 'Config Presets'),
            icon: 'swatchbook',
            to: '/charging-stations/config-presets',
            slug: 'config-presets',
            endpoint: EVSE_CONFIG_PRESET_PERMISSIONS,
            providerOrGlobalRolesOnly: true,
            requiresFeatureFlag: FeatureFlags.EvseConfigPresets,
          },
          {
            content: (t) => t('menu.cpoDetectedIssues', 'Detected Issues'),
            icon: 'bug',
            to: '/charging-stations/issues',
            slug: 'cpo-issues',
            endpoint: 'cpoLocations',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.cpoAccessGroups', 'Access Groups'),
            icon: 'key',
            to: '/charging-stations/access-groups',
            slug: 'access-groups',
            endpoint: 'accessGroups',
            providerOrGlobalRolesOnly: true,
            providedByModule: 'access-groups',
          },
          {
            content: (t) => t('menu.evseIds', 'EVSE IDs'),
            icon: 'qrcode',
            to: '/charging-stations/evse-ids/batches',
            slug: 'evse-ids',
            endpoint: 'evseIds',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.tariffProfiles', 'Tariff Profiles'),
            icon: 'dollar-sign',
            to: '/charging-stations/tariff-profiles',
            slug: 'tariff-profiles',
            endpoint: 'tariffProfiles',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.connections', 'Connections'),
            icon: 'sitemap',
            to: '/charging-stations/connections',
            slug: 'evse-controllers',
            endpoint: 'evse-controllers',
            providerOrGlobalRolesOnly: true,
            efluxOnly: true,
          },
          {
            content: () => 'Issues Stats',
            icon: 'bug',
            to: '/charging-stations/issues-aggregated',
            slug: 'evse-issues',
            endpoint: 'evse-issues',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: () => 'Manage Labels',
            icon: 'tags',
            to: '/charging-stations/labels',
            slug: 'evse-labels',
            endpoint: 'evseControllersLabels',
            requiresFeatureFlag: 'evsecontroller-labels',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) =>
              t('menu.chargeStationSimulator', 'Station Simulator'),
            icon: 'wrench',
            to: '/charging-stations/simulator',
            slug: 'evse-simulator',
            exact: false,
            endpoint: 'credentials',
            accountRolesOnly: false,
            efluxOnly: true,
          },
          {
            content: (t) => t('menu.integrations', 'Integrations'),
            icon: 'bolt',
            id: 'menu-my-charging-stations-integrations',
            to: '/charging-stations/integrations',
            slug: 'evse-integrations',
            endpoint: 'evseControllersIntegrations',
            providedByModule: 'smart-charging',
            providerOrGlobalRolesOnly: true,
            notForSuperAdmin: true,
          },
          {
            content: (t) => t('menu.integrations', 'Integrations'),
            icon: 'bolt',
            id: 'menu-my-charging-stations-integrations',
            to: '/charging-stations/integrations',
            slug: 'evse-integrations',
            endpoint: 'evseControllersIntegrations',
            providedByModule: 'smart-charging',
            requireSuperAdmin: true,
          },
          {
            content: (t) =>
              t('menu.evseOperationalStatuses', 'Operational Statuses'),
            icon: 'rotate',
            to: EVSE_OPERATIONAL_STATUSES_FE_PATH,
            slug: 'evse-operational-statuses',
            endpoint: EVSE_OPERATIONAL_STATUSES_PERMISSIONS_READ,
            providerOrGlobalRolesOnly: true,
            notForSuperAdmin: true,
            requiresFeatureFlag: FeatureFlags.OperationalStatusesPage,
          },
          {
            content: (t) =>
              t('menu.evseOperationalStatuses', 'Operational Statuses'),
            icon: 'rotate',
            to: EVSE_OPERATIONAL_STATUSES_FE_PATH,
            slug: 'evse-operational-statuses',
            endpoint: EVSE_OPERATIONAL_STATUSES_PERMISSIONS_READ,
            requireSuperAdmin: true,
            requiresFeatureFlag: FeatureFlags.OperationalStatusesPage,
          },
        ],
      },
      {
        content: (t) => t('menu.cards', 'Cards'),
        description: 'Manage MSP cards',
        icon: 'credit-card',
        to: '/cards',
        slug: 'cards',
        endpoint: 'cards',
        providerOrGlobalRolesOnly: true,
        subItems: [
          {
            content: (t) => t('menu.sessions', 'Sessions'),
            description: 'Overview of MSP sessions',
            icon: 'bars-staggered',
            to: '/cards/sessions',
            slug: 'msp-sessions',
            endpoint: 'sessions',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.cdrs', 'CDRS'),
            icon: 'record-vinyl',
            to: '/cards/cdrs',
            slug: 'cdrs',
            endpoint: 'cdrs',
            requiresFeatureFlag: 'roaming_cdrs',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.creditSessions', 'Credit Sessions'),
            icon: 'money-bill',
            to: '/cards/credit-sessions',
            slug: 'msp-credit-sessions',
            endpoint: 'creditSessions',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.tokens', 'Tokens'),
            description: 'Manage internal MSP tokens',
            icon: 'tag',
            to: '/cards/tokens',
            slug: 'msp-tokens',
            endpoint: 'tokens',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.tokenBatches', 'Token Batches'),
            icon: 'qrcode',
            to: '/cards/token-batches',
            slug: 'msp-token-batches',
            endpoint: 'tokenBatches',
            providedByModule: 'token-batches',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.mspLocations', 'Locations'),
            description: 'An overview of MSP locations',
            icon: 'location-dot',
            to: '/cards/locations',
            slug: 'msp-locations',
            endpoint: 'analytics',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.externalProviders', 'Providers'),
            icon: 'list',
            to: '/cards/providers',
            slug: 'external-providers',
            endpoint: 'externalProviders',
            providerOrGlobalRolesOnly: true,
          },
        ],
      },
      {
        content: (t) => t('menu.analytics', 'Analytics'),
        icon: 'chart-pie',
        to: '/analytics',
        slug: 'analytics',
        endpoint: 'mspAnalytics',
        providerOrGlobalRolesOnly: true,
        subItems: [
          {
            content: (t) => t('menu.analyticsMsp', 'MSP'),
            icon: 'chart-pie',
            to: '/analytics/msp',
            slug: 'msp-analytics',
            endpoint: 'mspAnalytics',
            providerOrGlobalRolesOnly: true,
          },
          {
            content: (t) => t('menu.analyticsCpo', 'CPO'),
            icon: 'chart-pie',
            to: '/analytics/cpo',
            slug: 'cpo-analytics',
            endpoint: 'cpoAnalytics',
            providerOrGlobalRolesOnly: true,
          },
        ],
      },
    ],
  },
  {
    id: 'facilitator',
    title: (t) => t('menu.facilitatorMenu', 'Facilitator Menu'),
    items: [
      {
        content: (t) => t('menu.locations', 'Locations'),
        description: 'Overview of facilitator locations',
        icon: 'location-dot',
        to: '/facilitator/locations',
        slug: 'facilitator-locations',
        exact: true,
        endpoint: 'locationsFacilitator',
        accountRolesOnly: true,
      },
      {
        content: (t) => t('menu.facilitatorAnalytics', 'Analytics'),
        icon: 'chart-pie',
        to: '/facilitator/analytics',
        slug: 'facilitator-analytics',
        exact: true,
        endpoint: 'locationsFacilitator',
        accountRolesOnly: true,
      },
      {
        content: (t) => t('menu.facilitatorMap', 'Map'),
        icon: 'map',
        to: '/facilitator/map',
        slug: 'facilitator-map',
        exact: true,
        endpoint: 'locationsFacilitator',
        accountRolesOnly: true,
      },
    ],
  },
  {
    id: 'developer',
    title: (t) => t('menu.developerMenu', 'Developer Menu'),
    items: [
      {
        content: (t) => t('menu.credentialsApi', 'API Credentials'),
        icon: 'key',
        to: '/my-credentials',
        slug: 'credentials',
        endpoint: 'credentials',
        exact: false,
        accountRolesOnly: false,
        excludeForEfluxProviders: true,
      },
      {
        content: (t) =>
          t('menu.credentialsOcpiConnections', 'OCPI Connections'),
        icon: 'right-left',
        to: '/my-ocpi',
        slug: 'ocpi-credentials',
        exact: false,
        endpoint: 'ocpiCredentials',
        accountRolesOnly: true,
        excludeForEfluxProviders: true,
      },
      {
        content: (t) => t('menu.chargeStationSimulator', 'Station Simulator'),
        icon: 'wrench',
        to: '/charge-station-simulator',
        slug: 'evse-simulator',
        exact: false,
        endpoint: 'credentials',
        accountRolesOnly: false,
        excludeForEfluxProviders: true,
      },
      {
        content: (t) => t('menu.sessionsSimulator', 'Sessions Simulator'),
        icon: 'wrench',
        to: '/sessions-simulator',
        slug: 'sessions-simulator',
        exact: false,
        endpoint: 'ocpiCredentials',
        accountRolesOnly: false,
        excludeForEfluxProviders: true,
      },
      {
        content: (t) => t('menu.apiDocs', 'API Docs'),
        icon: 'book',
        to: '/developer',
        slug: 'api-docs',
        target: '_blank',
        endpoint: 'credentials',
        accountRolesOnly: false,
        excludeForEfluxProviders: true,
      },
    ],
  },
  {
    id: 'maintenance',
    title: (t) => t('menu.maintenanceMenu', 'Maintenance Menu'),
    items: [
      {
        content: (t) => t('menu.maintenanceDashboard', 'Dashboard'),
        icon: 'house',
        to: '/maintenance',
        slug: 'evse-maintenance',
        exact: true,
        endpoint: 'evseControllersMaintenance',
        notForSuperAdmin: true,
      },
      {
        content: (t) => t('menu.maintenanceSetupEvse', 'Setup New EVSE'),
        icon: 'plus',
        to: '/maintenance/setup-evse',
        slug: 'evse-setup',
        exact: false,
        endpoint: 'evseControllersMaintenance',
        notForSuperAdmin: true,
      },
      {
        content: (t) => t('menu.maintenanceHome', 'Charging Stations'),
        icon: 'charging-station',
        to: '/maintenance/evse-controllers',
        slug: 'maintenance-evse-controllers',
        exact: false,
        endpoint: 'evseControllersMaintenance',
        notForSuperAdmin: true,
      },
      {
        content: (t) => t('menu.maintenanceIssues', 'Detected Issues'),
        icon: 'bug',
        to: '/maintenance/evse-controllers/issues',
        slug: 'maintenance-evse-controllers-issues',
        exact: true,
        endpoint: 'evseControllersMaintenance',
        notForSuperAdmin: true,
      },
      {
        content: (t) => t('menu.maintenanceAnalytics', 'Analytics'),
        icon: 'chart-pie',
        to: '/maintenance/analytics',
        slug: 'maintenance-analytics',
        exact: false,
        endpoint: 'evseControllersMaintenance',
        notForSuperAdmin: true,
      },
    ],
  },
  {
    id: 'legacySystem',
    title: (t) => t('menu.legacySystemMenu', 'System Menu '), // don't remove the extra space until the new system menu is fully adopted
    items: [
      {
        content: (t) => t('menu.providers', 'Providers'),
        icon: 'building',
        to: '/providers',
        slug: 'providers',
        endpoint: 'providers',
        providerOrGlobalRolesOnly: true,
        efluxOnly: true,
      },
      {
        content: (t) => t('menu.roles', 'Roles'),
        icon: 'user-tag',
        to: '/roles',
        slug: 'roles',
        endpoint: 'roles',
        providerOrGlobalRolesOnly: true,
        efluxOnly: true,
      },
      {
        content: (t) => t('menu.credentialsApi', 'Credentials'),
        icon: 'key',
        to: '/credentials',
        slug: 'credentials',
        endpoint: 'credentials',
        subItems: [
          {
            content: (t) => t('menu.credentialsOcpi', 'OCPI'),
            icon: 'right-left',
            to: '/credentials/ocpi',
            slug: 'ocpi-credentials',
            endpoint: 'ocpi-credentials',
            providerOrGlobalRolesOnly: true,
            efluxOnly: true,
          },
          {
            content: (t) => t('menu.tariffNotes', 'Tariff Notes'),
            icon: 'note-sticky',
            to: '/credentials/tariff-notes',
            slug: 'tariff-notes',
            endpoint: 'tariff-notes',
            providerOrGlobalRolesOnly: true,
            efluxOnly: true,
          },
          {
            content: (t) => t('menu.apiDocs', 'API Docs'),
            icon: 'book',
            to: '/developer',
            slug: 'api-docs',
            target: '_blank',
            endpoint: 'roles',
            providerOrGlobalRolesOnly: true,
            efluxOnly: true,
          },
        ],
        providerOrGlobalRolesOnly: true,
        requireSuperAdmin: true,
      },
      {
        content: (t) => t('menu.legacySystemLogs', 'System Logs'),
        icon: 'server',
        to: '/sync-log',
        slug: 'sync-log',
        endpoint: 'sync-log',
        providerOrGlobalRolesOnly: true,
        efluxOnly: true,
        subItems: [
          {
            content: (t) => t('menu.legacySystemSyncIssues', 'Sync Issues'),
            icon: 'circle-exclamation',
            to: '/sync-log/issues',
            slug: 'sync-issues',
            endpoint: 'me',
          },
          {
            content: (t) => t('menu.auditTrail', 'Audit Trail'),
            icon: 'list-ol',
            to: '/sync-log/audit-trail',
            slug: 'audit-trail',
            endpoint: 'me',
          },
        ],
      },
      {
        content: (t) => t('menu.legacySystemMigrations', 'Migrations'),
        icon: 'clone',
        to: '/migrations/enterprise-accounts',
        slug: 'enterprise-account-migrations',
        endpoint: 'enterprise-account-migrations',
        efluxOnly: true,
        superAdminOnly: true,
        requiresFeatureFlag: 'enterprise_account_migrations',
      },
      {
        content: (t) =>
          t('menu.system.finance.roamingLimits', 'Roaming Limits'),
        icon: 'thumbtack',
        to: '/system/finance/roaming-limits',
        slug: 'roaming-limits',
        endpoint: 'system/finance/roamingLimits',
        systemRolesOnly: true,
        efluxOnly: true,
      },
    ],
  },
  {
    id: 'consumer',
    title: (t) => t('menu.mainMenu', 'Main Menu'),
    hideForSuperAdmin: true,
    items: [
      {
        content: (t) => t('menu.home', 'Home'),
        icon: 'map',
        to: '/my-map',
        exact: true,
        slug: 'home',
        endpoint: 'me',
        accountRolesOnly: true,
        excludeFeatureFlag: 'dashboard_home_v2',
        subItems: [
          {
            content: (t) => t('menu.remoteSessions', 'Remote Sessions'),
            icon: 'charging-station',
            to: '/my-map/remote-sessions',
            slug: 'my-remote-sessions',
            endpoint: 'me',
            requiresPlatformFeature: 'roaming:remote-sessions',
          },
        ],
      },
      {
        content: (t) => t('menu.home', 'Home'),
        icon: 'house',
        to: '/',
        exact: true,
        slug: 'my-home',
        endpoint: 'me',
        accountRolesOnly: true,
        requiresFeatureFlag: 'dashboard_home_v2',
      },
      {
        content: (t) => t('menu.map', 'My Map'),
        icon: 'map',
        to: '/my-map',
        slug: 'my-map',
        exact: true,
        endpoint: 'me',
        accountRolesOnly: true,
        // only show my map if card or tag is enabled
        providerCheck: (provider) => {
          const { enabledChargeProducts = [] } = provider;
          return enabledChargeProducts.some((c) => ['card', 'tag'].includes(c));
        },
        requiresFeatureFlag: 'dashboard_home_v2',
        subItems: [
          {
            content: (t) => t('menu.remoteSessions', 'Remote Sessions'),
            icon: 'charging-station',
            to: '/my-map/remote-sessions',
            slug: 'my-remote-sessions',
            endpoint: 'me',
            requiresPlatformFeature: 'roaming:remote-sessions',
          },
        ],
      },
      {
        content: (t) => t('menu.myCards', 'My Cards'),
        icon: 'credit-card',
        to: '/my-cards',
        slug: 'my-cards',
        endpoint: 'me',
        providerMsp: true,
        id: 'menu-my-cards',
        subItems: [
          {
            content: (t) => t('menu.myUsage', 'My Usage'),
            icon: 'bars-staggered',
            id: 'menu-my-cards-usage',
            to: '/my-cards/usage',
            slug: 'my-cards-usage',
            endpoint: 'me',
          },
        ],
      },
      {
        content: (t) => t('menu.myLocations', 'My Locations'),
        icon: 'location-dot',
        to: '/my-locations',
        id: 'menu-my-locations',
        slug: 'my-locations',
        endpoint: 'me',
        subItems: [
          {
            content: (t) => t('menu.myUsage', 'Usage'),
            icon: 'bars-staggered',
            to: '/my-locations/usage',
            slug: 'my-locations-usage',
            id: 'menu-my-locations-usage',
            endpoint: 'me',
          },
          {
            content: (t) => t('menu.myAccessGroups', 'My Access Groups'),
            icon: 'key',
            to: '/my-locations/access-groups',
            id: 'menu-my-locations-access-groups',
            slug: 'my-locations-access-groups',
            endpoint: 'me',
            providedByModule: 'access-groups',
          },
          {
            content: (t) => t('menu.integrations', 'Integrations'),
            icon: 'bolt',
            to: '/my-locations/integrations',
            id: 'menu-my-locations-integrations',
            slug: 'my-locations-integrations',
            endpoint: 'me',
            requiresPlatformFeature: [
              'smart-charging:kia',
              'smart-charging:coneva',
              'smart-charging:pluq',
              'smart-charging:scoptvision',
            ],
            providedByModule: 'smart-charging',
          },
        ],
      },
      {
        content: (t) => t('menu.myInvoice', 'My Invoices'),
        icon: 'file-invoice',
        id: 'menu-my-invoices',
        to: '/my-invoices',
        slug: 'my-invoices',
        endpoint: 'invoices',
        requireInvoicing: true,
      },
      {
        content: (t) => t('menu.myCreditInvoice', 'My Credit Invoices'),
        icon: 'file-invoice-dollar',
        to: '/payout-invoices',
        id: 'menu-my-locations-credit-invoices',
        slug: 'my-credit-invoices',
        endpoint: 'me',
        requireInvoicing: true,
      },
      {
        content: (t) => t('menu.myAnalytics', 'My Analytics'),
        icon: 'chart-pie',
        to: '/my-analytics',
        slug: 'my-analytics',
        endpoint: 'locations',
        requiresPlatformFeature: 'analytics:account',
      },
      {
        content: (t) => t('menu.myAccount', 'My Account'),
        icon: 'user-tie',
        to: '/my-account',
        id: 'menu-my-account',
        slug: 'my-account',
        endpoint: 'accounts',
        accountRolesOnly: true,
        excludeFeatureFlag: 'account_settings_reorganization',
        subItems: [
          {
            content: (t) => t('menu.myUsers', 'Users'),
            icon: 'users',
            id: 'menu-my-account-users',
            to: '/my-account/users',
            slug: 'my-account-users',
            endpoint: 'users',
            accountRolesOnly: true,
          },
        ],
      },
      {
        content: (t) => t('menu.myPaymentFees', 'My Payment Fees'),
        icon: 'file-lines',
        id: 'menu-my-payemnt-fees',
        to: '/my-payment-fees',
        slug: 'my-payment-fees',
        endpoint: 'paymentFees',
        accountRolesOnly: true,
      },
      {
        content: (t) => t('menu.myUsers', 'Users'),
        icon: 'users',
        id: 'menu-my-account-users',
        to: '/my-users',
        slug: 'my-users',
        endpoint: 'users',
        accountRolesOnly: true,
        requiresFeatureFlag: 'account_settings_reorganization',
      },
    ],
  },
];

function itemIsIncluded(
  item,
  { provider, user, hasFeature, hasPlatformFeature, hasPlatformModule }
) {
  const userHasProviderRoles = hasProviderRoles(user);
  const userHasSystemRoles = hasSystemRoles(user);
  const userHasGlobalRoles = hasGlobalRoles(user);
  const userIsSuperAdmin = isSuperAdmin(user);
  if (userIsSuperAdmin && item.notForSuperAdmin) {
    return false;
  }
  if (!userIsSuperAdmin && item.requireSuperAdmin) {
    return false;
  }
  if (userHasProviderRoles && item.accountRolesOnly) {
    return false;
  }
  if (
    item.providerOrGlobalRolesOnly &&
    !userHasProviderRoles &&
    !userHasGlobalRoles
  ) {
    return false;
  }
  if (item.providedByModule && !hasPlatformModule(item.providedByModule)) {
    return false;
  }
  if (!userHasProviderRoles && item.providerRolesOnly) {
    return false;
  }
  if (!userHasSystemRoles && item.systemRolesOnly) {
    return false;
  }
  if (!provider.enableInvoicing && item.requireInvoicing) {
    return false;
  }
  if (item.excludeFeatureFlag && hasFeature(item.excludeFeatureFlag)) {
    return false;
  }
  if (item.requiresFeatureFlag && !hasFeature(item.requiresFeatureFlag)) {
    return false;
  }
  if (item.providerCheck && !item.providerCheck(provider)) {
    return false;
  }

  if (item.requiresPlatformFeature) {
    if (
      Array.isArray(item.requiresPlatformFeature) &&
      // at least one of the features must be present
      !item.requiresPlatformFeature.some((feature) =>
        hasPlatformFeature(feature)
      )
    ) {
      return false;
    }

    if (
      !Array.isArray(item.requiresPlatformFeature) &&
      !hasPlatformFeature(item.requiresPlatformFeature)
    ) {
      return false;
    }
  }
  if (item.hidden === true) {
    return false;
  }

  if (Array.isArray(item.endpoint)) {
    if (
      !item.endpoint.some((endpoint) =>
        canAccess(user, user.providerId, user.accountId, endpoint, 'read')
      )
    ) {
      return false;
    }
    if (
      (item.providerRolesOnly || item.providerOrGlobalRolesOnly) &&
      !item.endpoint.some((endpoint) =>
        canAccessProviderEndpoint(user, user.providerId, endpoint, 'read')
      )
    ) {
      return false;
    }
  } else {
    if (
      !canAccess(user, user.providerId, user.accountId, item.endpoint, 'read')
    ) {
      return false;
    }
    if (
      (item.providerRolesOnly || item.providerOrGlobalRolesOnly) &&
      !canAccessProviderEndpoint(user, user.providerId, item.endpoint, 'read')
    ) {
      return false;
    }
  }

  if (item.providerMsp && providerIsCpoOnly(provider)) {
    return false;
  }
  if (item.efluxOnly && provider.slug !== 'e-flux') return false;
  if (
    item.excludeForEfluxProviders &&
    provider.slug === 'e-flux' &&
    userHasProviderRoles
  ) {
    return false;
  }
  return true;
}

export function buildMenu(
  provider,
  user,
  items,
  t,
  hasFeature,
  hasPlatformFeature,
  hasPlatformModule
) {
  const menu = [];

  items.forEach((item) => {
    if (
      itemIsIncluded(item, {
        user,
        provider,
        hasFeature,
        hasPlatformFeature,
        hasPlatformModule,
      })
    ) {
      const subItems = (item.subItems || [])
        .filter((subItem) =>
          itemIsIncluded(subItem, {
            user,
            provider,
            hasFeature,
            hasPlatformFeature,
            hasPlatformModule,
          })
        )
        .map((subItem) => {
          return {
            ...subItem,
            content: subItem.content(t),
          };
        });
      menu.push({
        ...item,
        content: item.content(t),
        subItems,
      });
    }
  });
  return menu;
}
