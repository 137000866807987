import { getToken, request } from 'utils/api';
import { parseToken } from 'utils/token';
import React from 'react';
import {
  Button,
  Header,
  Modal,
  ModalActions,
  ModalContent,
  ModalDescription,
  ModalHeader,
} from 'semantic';
import { useTranslation } from 'react-i18next';

export const ExpiredSessionLoginModal = () => {
  const { t } = useTranslation();
  const parsedToken = parseToken(getToken());

  return (
    <Modal open={true} trigger={<div />}>
      <ModalHeader>
        {t('authentication.token_expired.title', 'Session expired')}
      </ModalHeader>
      <ModalContent>
        <ModalDescription>
          <Header>
            {t(
              'authentication.token_expired.header',
              'For security reasons your token has expired.'
            )}
          </Header>
          <p>
            {t(
              'authentication.token_expired.message',
              'Please login again to continue.'
            )}
          </p>
        </ModalDescription>
      </ModalContent>
      <ModalActions>
        {parsedToken.method === 'sso' && (
          <Button
            onClick={async () => {
              const redirectUrl = new URL(window.location.href);

              const { data } = await request({
                method: 'POST',
                path: '/1/auth/login/sso',
                body: {
                  userId: parsedToken.userId,
                  redirectUrl: redirectUrl.pathname,
                },
                providerId: parsedToken.providerId,
              });

              if (data.ssoEnabled && data.redirectRequired) {
                window.location = data.redirectUrl;
                return;
              }
            }}>
            {t('authentication.token_expired.buttonLoginSSO', 'Login with SSO')}
          </Button>
        )}
        {parsedToken.method !== 'sso' && (
          <Button
            onClick={() => {
              localStorage.removeItem('e_flux_jwt');
              window.location.href = `/login?redirect=${window.location.pathname}`;
            }}>
            {t('authentication.token_expired.buttonLoginOthers', 'Login')}
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};
