import React from 'react';
import { FieldValidator, useField } from 'formik';
import SearchDropdownField from 'components/form-fields/SearchDropdown';
import { Label } from 'semantic';

/**
 * OPTION_ITEM_TYPE allows the component to pick up the type of the
 * option based on the returning data from onDataNeeded.
 *
 * Example usage:
 * <SearchDropdown
 *  onDataNeeded: () => Promise<{data: {field1: string, field2: string}[]}>
 *  getOptionLabel: (item) => item.field2 // <-- field2 resolves automatically in the LSP
 * />
 *
 * You can force a type by passing it as a generic:
 * <SearchDropdown<{field1: string, field2: string}>
 *  getOptionLabel: (item) => item.field2
 * />
 *
 * @note in case you are using request, this will not
 * resolve automatically cuz the return type is unknown.
 * To get it working just define the return type in request.
 * I have added a type for it.
 * @see {@link src/utils/api/request.d.ts} for examples
 *
 *
 **/
interface Props<OPTION_ITEM_TYPE> {
  name: string;
  label?: string;
  validate?: FieldValidator;
  onDataNeeded: (search: {
    name: string;
  }) => Promise<{ data: OPTION_ITEM_TYPE[] }>;
  clearable?: boolean;
  multiple?: boolean;
  objectMode?: boolean;
  required?: boolean;
  hideErrorLabel?: boolean;
  populateOnLoad?: boolean;
  getOptionLabel?: (item: OPTION_ITEM_TYPE) => string;
  description?: string;
  forceFetchOnFocus?: boolean;
  allowAdditions?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

function SearchDropdown<T>({
  name,
  label,
  validate,
  objectMode = false,
  multiple,
  onDataNeeded,
  clearable = true,
  required = false,
  populateOnLoad = false,
  hideErrorLabel = false,
  disabled,
  getOptionLabel,
  description,
  forceFetchOnFocus,
  allowAdditions = false,
  placeholder,
}: Props<T>) {
  const [field, meta, helpers] = useField({ name, validate });

  return (
    <>
      <SearchDropdownField
        clearable={clearable}
        value={field.value}
        objectMode={objectMode}
        onDataNeeded={onDataNeeded}
        placeholder={placeholder}
        label={label}
        disabled={disabled}
        populateOnLoad={populateOnLoad}
        allowAdditions={allowAdditions}
        multiple={multiple}
        description={description}
        required={required}
        onChange={(e: Event, { value }: { value: unknown }) => {
          helpers.setValue(value, true);
        }}
        getOptionLabel={getOptionLabel}
        forceFetchOnFocus={forceFetchOnFocus}
        error={
          meta.touched && meta.error
            ? hideErrorLabel
              ? undefined
              : meta.error
            : undefined
        }
      />

      {/* We had to add this label to show error message box below the dropdown list */}
      {meta.error && meta.touched && !hideErrorLabel && (
        <Label
          basic
          pointing={'above'}
          style={{
            color: '#9F3A38',
            marginTop: -15,
            border: '1px solid #E0B4B4',
          }}>
          {meta.error}
        </Label>
      )}
    </>
  );
}

export default SearchDropdown;
