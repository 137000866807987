import { userCanAccessProviderEndpoint } from 'utils/roles';
import { useFeatures } from 'contexts/features';
import { useUser } from 'contexts/user';
import { usePlatformModules } from 'contexts/platformModules';

export const CHARGING_STATIONS_FE_PATH = `/charging-stations`;
export const EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH = `${CHARGING_STATIONS_FE_PATH}/background-jobs`;
export const EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH = `${CHARGING_STATIONS_FE_PATH}/firmware-update`;
export const EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH = `${CHARGING_STATIONS_FE_PATH}/change-configuration`;
export const EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH = `${CHARGING_STATIONS_FE_PATH}/execute-command`;
export const EVSE_CONTROLLERS_LOCATIONS_FE_PATH = `${CHARGING_STATIONS_FE_PATH}/locations`;
export const ACCOUNTS_FE_PATH = `/accounts`;
export const USERS_FE_PATH = `${ACCOUNTS_FE_PATH}/users`;

export const EVSE_BACKGROUND_JOBS_BE_PATH = `/1/evse-background-jobs`;
export const EVSE_BACKGROUND_JOBS_SEARCH_BE_PATH = `${EVSE_BACKGROUND_JOBS_BE_PATH}/search`;
export const EVSE_BACKGROUND_JOBS_BE_UPDATEFIRMWARE_PATH = `${EVSE_BACKGROUND_JOBS_BE_PATH}/update-firmware`;
export const EVSE_BACKGROUND_JOBS_BE_CHANGE_CONFIGURATION_PATH = `${EVSE_BACKGROUND_JOBS_BE_PATH}/set-configuration`;
export const EVSE_BACKGROUND_JOBS_BE_EXECUTE_COMMAND_PATH = `${EVSE_BACKGROUND_JOBS_BE_PATH}/raw-command`;

export const EVSE_CONTROLLERS_BACKGROUND_JOBS_PERMISSIONS_READ =
  'evseBackgroundJobs';
export const EVSE_CONTROLLERS_BACKGROUND_JOBS_PERMISSIONS_WRITE = [
  EVSE_CONTROLLERS_BACKGROUND_JOBS_PERMISSIONS_READ,
  'evseCommands',
];

export const EVSE_CONTROLLERS_BACKGROUND_JOBS_PLATFORM_MODULE =
  'bulk-management';

export const getCancelUrl = (
  backgroundJobId: string,
  workflowExecutionId: string
): string =>
  `${EVSE_BACKGROUND_JOBS_BE_PATH}/${backgroundJobId}/workflows/${workflowExecutionId}/cancel`;

export const hasEvseControllersBackgroundJobsPermissions = (
  requiredLevel: 'read' | 'write'
): boolean => {
  const { user, provider } = useUser();
  const { hasPlatformModule } = usePlatformModules();
  if (!user) {
    return false;
  }

  const hasPermission =
    (requiredLevel === 'read' &&
      userCanAccessProviderEndpoint(
        user,
        provider,
        EVSE_CONTROLLERS_BACKGROUND_JOBS_PERMISSIONS_READ,
        'read'
      )) ||
    (requiredLevel === 'write' &&
      EVSE_CONTROLLERS_BACKGROUND_JOBS_PERMISSIONS_WRITE.every((p) =>
        userCanAccessProviderEndpoint(user, provider, p, requiredLevel)
      ));

  return (
    hasPlatformModule(EVSE_CONTROLLERS_BACKGROUND_JOBS_PLATFORM_MODULE) &&
    hasPermission
  );
};
